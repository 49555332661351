/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import "./DesignLibrary.scss";
import archib from "../../../assets/images/workspace/icons/archib.png";
import interior from "../../../assets/images/workspace/icons/interiorb.png";
import landsc from "../../../assets/images/workspace/icons/landscb.png";
// import graphic from "../../../assets/images/workspace/icons/graphicb.png";
import urbanDesign from "../../../assets/images/workspace/icons/urban-design.png";
import defaultimg from "../../../assets/images/workspace/default.png";
import defaulticon from "../../../assets/images/workspace/default-icon.png";
import sunny from "../../../assets/images/workspace/atmosphere/sunny.png";
import intro from "../../../assets/images/workspace/icons/intro.png";
import template from "../../../assets/images/hover-tips-images/template.png";
import stylerefimage from "../../../assets/images/hover-tips-images/style_reference.jpg";
import privateCustomModel from "../../../assets/images/hover-tips-images/private-custom-model.jpg";
import promptImage from "../../../assets/images/hover-tips-images/prompt.jpg";
import promptImageVideo from "../../../assets/images/hover-tips-images/promptvideo.jpg";
import baseicon from "../../../assets/images/workspace/icons/base-icon.png";
import cameraimage from "../../../assets/images/hover-tips-images/camera.png";
import atmosphereimage from "../../../assets/images/hover-tips-images/atmosphere.png";
import styleRefEn from "../../../assets/images/hover-tips-images/atmosphere-en.png";
import styleRefFr from "../../../assets/images/hover-tips-images/atmosphere-fr.png";
import styleRefCh from "../../../assets/images/hover-tips-images/atmosphere-ch.png";
import styleRefEn1 from "../../../assets/images/hover-tips-images/mj_style_reference_en.jpg";
import styleRefFr1 from "../../../assets/images/hover-tips-images/mj_style_reference_fr.jpg";
import styleRefCh1 from "../../../assets/images/hover-tips-images/mj_style_reference_chs.jpg";
import revert from "../../../assets/images/icons/revert.png";
import deleteicon from "../../../assets/images/workspace/icons/upload-delete.png";
import gif from "../../../assets/images/workspace/dots.gif";
import placeholder from "../../../assets/images/icons/placeholder.png";
import Loader from "../../../components/loader/index";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../store/UserDetailsStore";
import TipsPopups1 from "../../TipsPopup/TipsPopup1";
import {
  GetCameraViewUrlUser,
  GetCameraViewUser,
  GetFirstTierUser,
  GetSecondTierUser,
  GetThirdTierAndStyleUser,
  GetAllPrivateModel,
  CallAwsUrl,
  GetGraphicStyleData,
  GetSmartPromptData,
} from "../../../utils/apiCalls";
import { DesignLibraryContext } from "../../../store/DesignLibraryData";
import Portal from "../../TipsPopup/Portal1";
import { ResponsiveDataContext } from "../../../store/ResponsiveData";
import Switch from "react-switch";
import Select from "react-select";
import { TeamDesignContext } from "../../../store/TeamDesignData";
import { AtmosphereRefContext } from "../../../store/AtmosphereRefData";
import { Popover, Slider } from "antd";
import {
  computePostProcessedDimension,
  handleBase64Data,
  processImage,
  uploadFile,
  SCALE_LIMIT,
} from "../../../utils/workspaceUtils";
import CameraDropDown from "../cameradropdown/CameraDropDown";
import GraphicStyleDropDown from "../GraphicStyle/GraphicStyle";
import { getLanguageLimit, processText } from "../../../utils/commonUtils";
import { UserSocketContext } from "../../../store/UserSocketData";
import { AIRI_AWS_URL } from "../../../utils/api-constant";
import { useSelector } from "react-redux";

const stylerefimages = {
  en: styleRefEn,
  fr: styleRefFr,
  ch: styleRefCh,
};

const stylerefimages1 = {
  en: styleRefEn1,
  fr: styleRefFr1,
  ch: styleRefCh1,
};

const DesignLibraryComopnent = ({
  handleCloseAccessPopup,
  handleActiveLearn,
  activeLearnBool,
  setIsButtonDisabled,
  setIsLimitPopup,
  setGlobalErrorMessage,
  setIsUploadScaled,
  templateDisable,
  isActiveToolset,
  mainMenu,
  sideMenu,
  isMagicPrompt,
  setIsMagicPrompt,
}) => {
  const { t } = useTranslation();
  const privateModelApiRef = useRef(false);
  const url = window.location.pathname;
  const lastPart = url.substring(url.lastIndexOf("/") + 1);
  const textareaRef = useRef(null);
  const textarea1Ref = useRef(null);
  const textarea2Ref = useRef(null);
  const textarea3Ref = useRef(null);

  const { isPrivilagesDetails, isLoginUserDetails } = useContext(UserContext);
  const { designLibraryData, updateDesignLibraryData } =
    useContext(DesignLibraryContext);
  const { responsiveData } = useContext(ResponsiveDataContext);
  const { isTeamChanges } = useContext(TeamDesignContext);
  const {
    smartPromptData,
    updateSmartPromptData,
    updateAtmosphereRefData,
    updateAtmosphereRefData1,
  } = useContext(AtmosphereRefContext);
  const { updateSocketData, openUserSocket } = useContext(UserSocketContext);

  const inspireSelector = (state) =>
    state.inspireToolset[lastPart]?.inspireToolsetData;
  const inspireToolsetRedux = useSelector(inspireSelector);
  const toolsetSelector = (state) => state.toolset[lastPart]?.toolsetData;
  const toolsetRedux = useSelector(toolsetSelector);
  const upscaleSelector = (state) =>
    state.upscaleToolset[lastPart]?.upscaleToolsetData;
  const upscaleToolsetRedux = useSelector(upscaleSelector);

  const lang = localStorage.getItem("language");
  const limit = getLanguageLimit(lang);
  const languageCodes = {
    ch: "chs",
    en: "en",
    fr: "fr",
  };
  const tiresData = JSON.parse(
    localStorage.getItem(`designLibraryData_${lastPart}`)
  );
  const atmosphereData = JSON.parse(
    localStorage.getItem(`atmosphereData_${lastPart}`)
  );
  const atmosphereData1 = JSON.parse(
    localStorage.getItem(`atmosphereData1_${lastPart}`)
  );
  const atmosphere = JSON.parse(
    localStorage.getItem(`atmosphereData_${lastPart}`)
  );
  let dlData = JSON.parse(localStorage.getItem(`dlData_${lastPart}`));
  let designLibrary = JSON.parse(localStorage.getItem(`dlData_${lastPart}`));
  const promptLocalData = JSON.parse(
    localStorage.getItem(`promptData_${lastPart}`)
  );
  const atmosphereRefLocal = JSON.parse(
    localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
  );
  const isActiveTeam = JSON.parse(localStorage.getItem("activeTeam"));
  const istemplateLocal = JSON.parse(
    localStorage.getItem(`isTemplate_${lastPart}`)
  );
  const designLibraryDisable = JSON.parse(
    localStorage.getItem(`designLibraryDisable_${lastPart}`)
  );
  const smartPrompt =
    JSON.parse(localStorage.getItem(`smartPrompt_${lastPart}`)) || {};
  const smartLocalRes = JSON.parse(
    localStorage.getItem(`smartPromptRes_${lastPart}`)
  );
  const styleRefSwift = JSON.parse(
    localStorage.getItem(`styleRefSwift_${lastPart}`)
  );
  const plugins = JSON.parse(sessionStorage.getItem("plugin"));
  const isPlugin =
    plugins?.plugin === "/rhino" ||
    plugins?.plugin === "/sketchup" ||
    plugins?.plugin === "/revit";

  const [defaultText, setDefaultText] = useState("");
  const [remaining, setRemaining] = useState(0);
  const [defaultText1, setDefaultText1] = useState("");
  const [remaining1, setRemaining1] = useState(0);
  const [defaultText2, setDefaultText2] = useState("");
  const [remaining3, setRemaining3] = useState(0);
  const [defaultText3, setDefaultText3] = useState("");
  const [remaining2, setRemaining2] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [firstTierZeroCall, setFirstTierZeroCall] = useState();
  const [firstTierZeroNameCall, setFirstTierZeroNameCall] = useState();
  const [secondTierZeroCall, setSecondTierZeroCall] = useState();
  const [secondTierZeroNameCall, setSecondTierZeroNameCall] = useState();
  const [thirdTierZeroCall, setThirdTierZeroCall] = useState();
  const [thirdTierZeroNameCall, setThirdTierZeroNameCall] = useState();
  const [thirdTierZeroSubNameCall, setThirdTierZeroSubNameCall] = useState();
  const [privateModelError, setPrivateModelError] = useState(false);
  const [checked, setChecked] = useState(promptLocalData?.isTemplate ?? true);
  const [privateModelData, setPrivateModelData] = useState([]);
  const [isModelDataLoad, setIsModelDataLoad] = useState(false);
  const [modelSelected, setModelSelected] = useState();
  const [value, setValue] = useState();
  const [activeFidelityLevel, setActiveFidelityLevel] = useState(
    [
      atmosphereRefLocal?.[0]?.refFidelityLevel,
      atmosphereRefLocal?.[1]?.refFidelityLevel,
    ] || [5, 5]
  );
  const [activeFidelityLevel1, setActiveFidelityLevel1] = useState(5);
  const [inputKey, setInputKey] = useState(0);
  const [inputKey1, setInputKey1] = useState(0);
  const [isToolset, setIsToolset] = useState(0);
  const [isIndex, setIsIndex] = useState(0);
  const [isTemplate, setIsTemplate] = useState(
    istemplateLocal?.isTemplate ?? true
  );
  const [isAtmosphere, setIsAtmosphere] = useState(
    istemplateLocal?.isAtmosphere ?? false
  );
  const [isPrompt, setIsPrompt] = useState(istemplateLocal?.isPrompt ?? false);
  const [isPrivate, setIsPrivate] = useState(
    istemplateLocal?.isPrivate ?? false
  );
  const [apiCameraData, setApiCameraData] = useState([]);
  const [apiGraphicData, setApiGraphicData] = useState([]);
  const [typing, setTyping] = useState(false);
  const [smartPromptText, setSmartPromptText] = useState(
    smartPrompt?.text ?? ""
  );
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9, setOpen9] = useState(false);
  const [open10, setOpen10] = useState(false);
  const [open11, setOpen11] = useState(false);
  const [isMagicImage, setIsMagicImage] = useState(false);
  const [hoverTimer, setHoverTimer] = useState(null);
  const [sliderDisable, setSliderDisable] = useState(false);
  const [isSecondTierData, setIsSecondTierData] = useState(null);
  const [selectedSecondTierData, setSelectedSecondTierData] = useState(null);

  const isAtmosphereSwift = isActiveToolset === 3 && upscaleToolsetRedux?.toolset === 3;
  const isMjSelect = isActiveToolset === 0 && inspireToolsetRedux?.model === 2;

  useEffect(() => {
    if (atmosphereRefLocal === null || atmosphereRefLocal === undefined) {
      const check = [
        {
          refFidelityLevel: 5,
          feedDesignBase64: null,
          uploadedBaseImageUrl: null,
        },
        {
          refFidelityLevel: 5,
          feedDesignBase64: null,
          uploadedBaseImageUrl: null,
        },
      ];
      localStorage.setItem(
        `atmosphereRefLocal_${lastPart}`,
        JSON.stringify(check)
      );
      // updateAtmosphereRefData(check);
    } else {
      setActiveFidelityLevel([
        atmosphereRefLocal[0]?.refFidelityLevel,
        atmosphereRefLocal[1]?.refFidelityLevel,
      ]);
    }
    if (istemplateLocal == null || istemplateLocal === undefined) {
      const check = {
        isTemplate: true,
        isAtmosphere: false,
        isPrompt: true,
        isPrivate: false,
      };

      localStorage.setItem(`isTemplate_${lastPart}`, JSON.stringify(check));
    }

    if (!designLibraryDisable) {
      const check = {
        isDesign: false,
      };

      localStorage.setItem(
        `designLibraryDisable_${lastPart}`,
        JSON.stringify(check)
      );
    }
    getSecondTierDesign();

    if (!atmosphereData1) {
      getAtmosphere1();
    }
    if (!styleRefSwift) {
      const styleRef = {
        refFidelityLevel: 5,
        feedDesignBase64: null,
        uploadedBaseImageUrl: null,
      };
      localStorage.setItem(
        `styleRefSwift_${lastPart}`,
        JSON.stringify(styleRef)
      );
      updateAtmosphereRefData(styleRef);
    } else {
      setActiveFidelityLevel1(styleRefSwift?.refFidelityLevel);
    }
  }, []);

  useEffect(() => {
    setOpen(false);
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen9(false);
    setOpen10(false);
  }, [isActiveToolset]);

  useEffect(() => {
    if (isAtmosphere && isActiveToolset === 2 && toolsetRedux?.editToolsetSelection === 1) {
      setOpen10(true);
    } else {
      setOpen10(false);
    }
  }, [isActiveToolset, toolsetRedux?.editToolsetSelection, isAtmosphere]);

  useEffect(() => {
    if (open10) {
      setTimeout(() => {
        setOpen10(false);
      }, 10000);
    }
  }, [open10]);

  useEffect(() => {
    if (isActiveToolset === 2 && toolsetRedux?.editToolsetSelection === 1) {
      setSliderDisable(true);
      handleImageSliderChange(5, 0);
    } else {
      setSliderDisable(false);
    }
  }, [isActiveToolset, toolsetRedux?.editToolsetSelection]);

  useEffect(() => {
    if (
      tiresData?.isFirstTierId === null ||
      tiresData?.isFirstTierId === undefined
    ) {
      callAllTiers();
    }
  }, [tiresData]);

  useEffect(() => {
    if (typing) return;

    if (
      (promptLocalData?.additionalPrompt === null ||
        promptLocalData?.additionalPrompt === undefined ||
        promptLocalData?.prompt === null ||
        promptLocalData?.prompt === undefined) &&
      (promptLocalData?.negativePrompt === null ||
        promptLocalData?.negativePrompt === undefined) &&
      (promptLocalData?.privateModelValue === null ||
        promptLocalData?.privateModelValue === undefined) &&
      (promptLocalData?.atmospherePrompt === null ||
        promptLocalData?.atmospherePrompt === undefined)
    ) {
      const data = {
        isTemplate: checked,
        additionalPrompt: "",
        prompt: "",
        videoPrompt: "",
        negativePrompt: "",
        atmospherePrompt: "",
        privateModel: null,
        privateModelCh: null,
        privateModelValue: null,
      };
      localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(data));
    } else {
      if (
        promptLocalData?.prompt !== "" &&
        promptLocalData?.prompt !== undefined &&
        promptLocalData?.prompt !== null
      ) {
        handleTextAreaChange(promptLocalData?.prompt);
        handleSmartPromptChange(promptLocalData?.prompt);
      } else {
        handleTextAreaChange("");
        handleSmartPromptChange("");
      }
      if (
        promptLocalData?.negativePrompt !== "" &&
        promptLocalData?.negativePrompt !== undefined &&
        promptLocalData?.negativePrompt !== null
      ) {
        handleTextAreaChange1(promptLocalData?.negativePrompt);
      } else {
        handleTextAreaChange1("");
      }
      if (
        promptLocalData?.videoPrompt !== "" &&
        promptLocalData?.videoPrompt !== undefined &&
        promptLocalData?.videoPrompt !== null
      ) {
        handleTextAreaChange2(promptLocalData?.videoPrompt);
      } else {
        handleTextAreaChange2("");
      }
      if (
        promptLocalData?.atmospherePrompt !== "" &&
        promptLocalData?.atmospherePrompt !== undefined &&
        promptLocalData?.atmospherePrompt !== null
      ) {
        handleTextAreaChange3(promptLocalData?.atmospherePrompt);
      } else {
        handleTextAreaChange3("");
      }
      if (
        promptLocalData?.privateModelValue !== "" &&
        promptLocalData?.privateModelValue !== undefined &&
        promptLocalData?.privateModelValue !== null
      ) {
        setValue(promptLocalData?.privateModelValue);
      }
    }
  }, [promptLocalData]);

  useEffect(() => {
    privateModelApiRef.current = false;
    if (!privateModelApiRef.current) {
      privateModelApiRef.current = true;
      getAllPrivateModel();
    }
  }, [isTeamChanges]);

  useEffect(() => {
    if (isSecondTierData && tiresData) {
      const findData = isSecondTierData?.find(
        (second) => second?.id === tiresData?.isSecondTierId
      );
      setSelectedSecondTierData(findData);
    }
  }, [isSecondTierData, tiresData]);

  const getSecondTierDesign = () => {
    const payload = {
      designLibraryId: dlData?.prevDesignLibraryId,
      firstTierId: tiresData?.isFirstTierId,
      language: lang === "ch" ? "chs" : lang,
    };
    if (payload) {
      GetSecondTierUser((res) => {
        if (res?.status === 200) {
          setIsSecondTierData(res.data);
        } else if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      }, payload);
    }
  };

  const getAllPrivateModel = () => {
    const teamId = isActiveTeam?.teamId !== 0 ? isActiveTeam?.teamId : 0;
    GetAllPrivateModel((res) => {
      if (res?.status === 200) {
        const options = res?.data.map((model) => ({
          value: model?.id,
          label: model?.modelName,
          defaultStrength: model?.defaultStrength,
          modelDescription: model?.modelDescription,
          modelName: model?.modelName,
          modelNameAlias: model?.modelNameAlias,
          modelPrompt: model?.modelPrompt,
          priority: model?.priority,
        }));
        const optionsCh = res?.data.map((model) => ({
          value: model?.id,
          label: model?.modelNameAlias,
          defaultStrength: model?.defaultStrength,
          modelDescription: model?.modelDescription,
          modelName: model?.modelName,
          modelNameAlias: model?.modelNameAlias,
          modelPrompt: model?.modelPrompt,
          priority: model?.priority,
        }));
        setPrivateModelData((lang === "ch" ? optionsCh : options) ?? []);
        const check =
          JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
        if (check?.privateModel === undefined || check?.privateModel === null) {
          check.privateModel = options[0];
          check.privateModelCh = optionsCh[0];
          setModelSelected(lang === "ch" ? optionsCh[0] : options[0]);
        }
        localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
      } else if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      }
    }, teamId);
  };

  useEffect(() => {
    if (istemplateLocal?.isAtmosphere !== undefined) {
      if (istemplateLocal?.isAtmosphere !== isAtmosphere) {
        setIsAtmosphere(istemplateLocal?.isAtmosphere);
      } else if (istemplateLocal?.isTemplate !== isTemplate) {
        setIsTemplate(istemplateLocal?.isTemplate);
      } else if (istemplateLocal?.isPrompt !== isPrompt) {
        setIsPrompt(istemplateLocal?.isPrompt);
      } else if (istemplateLocal?.isPrivate !== isPrivate) {
        setIsPrivate(istemplateLocal?.isPrivate);
      }
    }
  }, [istemplateLocal]);

  useEffect(() => {
    if (open6) {
      setTimeout(() => {
        setOpen6(false);
      }, 5000);
    }
  }, [open6, isTemplate]);

  const handleTemplate = (val) => {
    let check =
      JSON.parse(localStorage.getItem(`isTemplate_${lastPart}`)) || {};
    if (check?.isAtmosphere !== undefined) {
      if (val === 1) {
        setIsAtmosphere(!check?.isAtmosphere ?? !isAtmosphere);
        if (isAtmosphereSwift) {
          if (!isAtmosphere) {
            setOpen6(true);
          } else {
            setOpen6(false);
          }
          if(check.isAtmosphere) {
            check.isAtmosphere = false;
            check.isTemplate = true;
          } else {
            check.isAtmosphere = true;
            check.isTemplate = false;
          }
        } else {
          check.isAtmosphere = !check?.isAtmosphere ?? !isAtmosphere;
        }
      } else if (val === 2) {
        setIsTemplate(!check?.isTemplate ?? !isTemplate);
        if (isAtmosphereSwift) {
          if(check.isAtmosphere) {
            check.isAtmosphere = false;
            check.isTemplate = true;
          } else {
            check.isAtmosphere = true;
            check.isTemplate = false;
          }
        } else {
          check.isTemplate = !check?.isTemplate ?? !isTemplate;
        }
      } else if (val === 3) {
        setIsPrompt(!check?.isPrompt ?? !isPrompt);
        check.isPrompt = !check?.isPrompt ?? !isPrompt;
      } else if (val === 4) {
        setIsPrivate(!check?.isPrivate ?? !isPrivate);
        check.isPrivate = !check?.isPrivate ?? !isPrivate;
      }
      localStorage.setItem(`isTemplate_${lastPart}`, JSON.stringify(check));
      updateSocketData();
    }
  };

  const callAllTiers = async () => {
    try {
      const firstTierZeroCall = await callFirstFier();
      const secondTierZeroCall = await callSecondFier(firstTierZeroCall.id);
      const thirdTierZeroCall = await callThirdFier(secondTierZeroCall.id);
      if (secondTierZeroCall) {
        const payload = {
          secondTierId: secondTierZeroCall.id || secondTierZeroCall,
          language: lang === "ch" ? "chs" : lang,
        };
        const cameraData = JSON.parse(
          localStorage.getItem(`cameraData_${lastPart}`)
        );
        if (cameraData === undefined || cameraData === null) {
          if (payload?.secondTierId) {
            GetCameraViewUser((res) => {
              if (res?.status === 200 && res?.data?.length > 0) {
                if (res?.data?.length !== 0) {
                  setApiCameraData(res?.data);
                  const isData = {
                    cameraSelectedOption: res?.data[0],
                    cameraViewId: res?.data[0]?.id,
                    cameraViewName: res?.data[0]?.name,
                  };
                  localStorage.setItem(
                    `cameraData_${lastPart}`,
                    JSON.stringify(isData)
                  );
                  getAtmosphere();
                }
              } else if (res?.message === "Invalid access token") {
                updateSocketData({
                  isExitPopup: true,
                });
              }
            }, payload);
            GetGraphicStyleData((res) => {
              if (res?.status === 200 && res?.data?.length > 0) {
                if (res?.data?.length !== 0) {
                  setApiGraphicData(res?.data);
                  const isData = {
                    graphicSelectedOption: res?.data[0],
                    graphicId: res?.data[0]?.id,
                    graphicName: res?.data[0]?.name,
                  };
                  localStorage.setItem(
                    `graphicStyleData_${lastPart}`,
                    JSON.stringify(isData)
                  );
                  getAtmosphere();
                } else if (res?.message === "Invalid access token") {
                  updateSocketData({
                    isExitPopup: true,
                  });
                }
              }
            }, payload);
          }
        }
      }
      const isData = {
        firstTierName: firstTierZeroCall.name,
        firstTierNameCh: firstTierZeroCall.alias,
        isFirstTierId: firstTierZeroCall.id,
        secondTierName: secondTierZeroCall.name,
        secondTierNameCh: secondTierZeroCall.alias,
        isSecondTierId: secondTierZeroCall.id,
        thirdTierName: thirdTierZeroCall.style[0].tierThreeName,
        thirdTierNameCh: thirdTierZeroCall.style[0].tierThreeNameAlias,
        isThirdTierId: thirdTierZeroCall.style[0].styleType,
        thirdTierSubName: thirdTierZeroCall.style[0].name,
        thirdTierSubNameCh: thirdTierZeroCall.style[0].alias,
        styleId: thirdTierZeroCall.style[0].id,
        styleURL: thirdTierZeroCall.style[0].coverImageSuffix,
      };
      localStorage.setItem(
        `designLibraryData_${lastPart}`,
        JSON.stringify(isData)
      );
      return {
        firstTierZeroCall,
        secondTierZeroCall,
        thirdTierZeroCall,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const callFirstFier = () => {
    return new Promise((resolve) => {
      const payload = {
        designLibraryId: 22,
        language: lang === "ch" ? "chs" : lang,
      };
      if (payload) {
        GetFirstTierUser((res) => {
          const firstTierData = res?.data?.[0];
          if (firstTierData) {
            setFirstTierZeroCall(firstTierData.id);
            setFirstTierZeroNameCall(firstTierData.name);
            resolve(firstTierData);
          } else {
            resolve({});
          }
          if (res?.message === "Invalid access token") {
            updateSocketData({
              isExitPopup: true,
            });
          }
        }, payload);
      }
    });
  };

  const callSecondFier = (firstTierZeroCall) => {
    return new Promise((resolve) => {
      const payload = {
        designLibraryId: 22,
        firstTierId: firstTierZeroCall,
        language: lang === "ch" ? "chs" : lang,
      };
      if (payload) {
        GetSecondTierUser((res) => {
          const secondTierData = res?.data?.[0];
          if (secondTierData) {
            setSecondTierZeroCall(secondTierData?.id);
            setSecondTierZeroNameCall(secondTierData?.name);
            resolve(secondTierData);
          } else {
            resolve({});
          }
          if (res?.message === "Invalid access token") {
            updateSocketData({
              isExitPopup: true,
            });
          }
        }, payload);
      }
    });
  };

  const callThirdFier = (secondTierZeroCall) => {
    return new Promise((resolve) => {
      const payload = {
        secondTierId: secondTierZeroCall,
        language: lang === "ch" ? "chs" : lang,
      };
      if (payload?.secondTierId) {
        GetThirdTierAndStyleUser((res) => {
          if (res?.status === 200 && res?.data?.length !== 0) {
            const firstObjectData = res?.data?.[0];
            setThirdTierZeroCall(firstObjectData.id);
            setThirdTierZeroNameCall(firstObjectData.tierThreeName);
            setThirdTierZeroSubNameCall(firstObjectData.name);
            resolve(firstObjectData);
          } else if (res?.message === "Invalid access token") {
            updateSocketData({
              isExitPopup: true,
            });
          }
        }, payload);
      }
    });
  };

  const getAtmosphere = () => {
    if (
      atmosphereData?.defaultSelectedAtmosphereId === undefined ||
      atmosphereData?.defaultSelectedAtmosphereId === null
    ) {
      const designLibraryType = dlData?.prevDesignLibraryId ?? 22;
      const language = lang === "ch" ? "chs" : lang;
      GetCameraViewUrlUser(
        (res) => {
          if (res?.status === 200 && res?.data?.length !== 0) {
            const atmosphere = {
              defaultSelectedAtmosphereId: res?.data?.[0]?.id,
              defaultSelectedAtmosphereUrl: res?.data?.[0]?.thumbnail,
              seasonName: "sunny",
              seasonIcon: sunny,
              seasonNameSelected: "spring",
              seasonNameSelectedId: 1,
            };
            localStorage.setItem(
              `atmosphereData_${lastPart}`,
              JSON.stringify(atmosphere)
            );
          } else if (res?.message === "Invalid access token") {
            updateSocketData({
              isExitPopup: true,
            });
          }
        },
        designLibraryType,
        language
      );
    }
  };

  const getAtmosphere1 = () => {
    if (
      atmosphereData1?.defaultSelectedAtmosphereId === undefined ||
      atmosphereData1?.defaultSelectedAtmosphereId === null
    ) {
      const designLibraryType = -1;
      const language = lang === "ch" ? "chs" : lang;
      GetCameraViewUrlUser(
        (res) => {
          if (res?.status === 200 && res?.data?.length !== 0) {
            const atmosphere1 = {
              isDesignType: 1,
              defaultSelectedAtmosphereId: res?.data?.[0]?.id,
              defaultSelectedAtmosphereUrl: res?.data?.[0]?.thumbnail,
              seasonName: "sunny",
              seasonIcon: sunny,
              seasonNameSelected: "spring",
              seasonNameSelectedId: 1,
            };
            localStorage.setItem(
              `atmosphereData1_${lastPart}`,
              JSON.stringify(atmosphere1)
            );
          } else if (res?.message === "Invalid access token") {
            updateSocketData({
              isExitPopup: true,
            });
          }
        },
        designLibraryType,
        language
      );
    }
  };

  useEffect(() => {
    if (
      designLibrary?.isDesignLibraryId === null ||
      designLibrary?.isDesignLibraryId === undefined
    ) {
      const data = {
        isDesignLibraryId: 22,
        prevDesignLibraryId: 22,
        designLibraryName: "Architecture",
      };
      localStorage.setItem(`dlData_${lastPart}`, JSON.stringify(data));
    }
  }, [designLibrary]);

  const handleActiveTires = (e) => {
    const newData = {
      isCloseTiresPopup: !designLibraryData?.isCloseTiresPopup,
      isShowTrueOrFalse: false,
      isDisplayAlldata: false,
    };
    updateDesignLibraryData(newData);
    let DesignData =
      JSON.parse(localStorage.getItem(`dlData_${lastPart}`)) || {};
    DesignData.isDesignLibraryId = e;
    if (e === 22) {
      DesignData.designLibraryName = "Architecture";
    } else if (e === 30) {
      DesignData.designLibraryName = "Interior";
    } else if (e === 31) {
      DesignData.designLibraryName = "Landscape";
    } else if (e === 32) {
      DesignData.designLibraryName = "Urban design";
    } else {
      DesignData.designLibraryName = "No Style";
    }
    localStorage.setItem(`dlData_${lastPart}`, JSON.stringify(DesignData));
  };

  const handleTiresPopup = () => {
    const newData = {
      isCloseTiresPopup: !designLibraryData?.isCloseTiresPopup,
      isShowTrueOrFalse: false,
      isDisplayAlldata: true,
    };
    updateDesignLibraryData(newData);
  };

  useEffect(() => {
    // Adjust textarea height based on its scroll height
    if (textareaRef.current) {
      textareaRef.current.classList.add("auto-resize");
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [defaultText]);

  useEffect(() => {
    // Adjust textarea height based on its scroll height
    if (textarea1Ref.current) {
      textarea1Ref.current.classList.add("auto-resize");
      textarea1Ref.current.style.height = "auto";
      textarea1Ref.current.style.height = `${textarea1Ref.current.scrollHeight}px`;
    }
  }, [defaultText1]);

  const handleTypingFocus = () => {
    if (!typing) {
      setTyping(true);
    }
  };

  const handleTypingBlur = () => {
    if (typing) {
      setTyping(false);
    }
  };

  const handleTextAreaChange = (e) => {
    let inputText = e;
    if (inputText === undefined) return;

    if (inputText === "") {
      setDefaultText(inputText);
      setRemaining(0);
      const check =
        JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
      check.prompt = inputText;
      localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
      return;
    }
    if (isPrivilagesDetails?.manualPrompt === true) {
      const { truncatedText, remainingChars } = processText(
        inputText,
        limit,
        2
      );
      setDefaultText(truncatedText);
      setRemaining(Math.max(0, remainingChars));
      const check =
        JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
      check.prompt = truncatedText;
      localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
    } else {
      handleCloseAccessPopup();
    }
  };

  const handleTextAreaChange1 = (e) => {
    let inputText = e;
    if (inputText === undefined) return;

    if (inputText === "") {
      setDefaultText1(inputText);
      setRemaining1(0);
      const check =
        JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
      check.negativePrompt = inputText;
      localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
      return;
    }
    if (isPrivilagesDetails?.manualPrompt === true) {
      const { truncatedText, remainingChars } = processText(inputText, 500);
      setDefaultText1(truncatedText);
      setRemaining1(Math.max(0, remainingChars));

      const check =
        JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
      check.negativePrompt = truncatedText;
      localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
    } else {
      handleCloseAccessPopup();
    }
  };

  const handleTextAreaChange2 = (e) => {
    let inputText = e;
    if (inputText === undefined) return;

    if (inputText === "") {
      setDefaultText2(inputText);
      setRemaining2(0);
      const check =
        JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
      check.videoPrompt = inputText;
      localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
      return;
    }
    if (isPrivilagesDetails?.manualPrompt === true) {
      const { truncatedText, remainingChars } = processText(inputText, 500, 2);
      setDefaultText2(truncatedText);
      setRemaining2(Math.max(0, remainingChars));
      const check =
        JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
      check.videoPrompt = truncatedText;
      localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
    } else {
      handleCloseAccessPopup();
    }
  };

  const handleTextAreaChange3 = (e) => {
    let inputText = e;
    if (inputText === undefined) return;

    if (inputText === "") {
      setDefaultText3(inputText);
      setRemaining3(0);
      const check =
        JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
      check.atmospherePrompt = inputText;
      localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
      return;
    }
    if (isPrivilagesDetails?.manualPrompt === true) {
      const { truncatedText, remainingChars } = processText(inputText, 500, 2);
      setDefaultText3(truncatedText);
      setRemaining3(Math.max(0, remainingChars));
      const check =
        JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
      check.atmospherePrompt = truncatedText;
      localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
    } else {
      handleCloseAccessPopup();
    }
  };

  const handleChange = (e) => {
    const check =
      JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
    if (check?.privateModel !== undefined || check?.privateModel !== null) {
      const option = {
        value: e?.value,
        label: e?.modelName,
        defaultStrength: e?.defaultStrength,
        modelDescription: e?.modelDescription,
        modelNameAlias: e?.modelNameAlias,
        modelPrompt: e?.modelPrompt,
        priority: e?.priority,
      };
      const optionCh = {
        value: e?.value,
        label: e?.modelNameAlias,
        defaultStrength: e?.defaultStrength,
        modelDescription: e?.modelDescription,
        modelNameAlias: e?.modelNameAlias,
        modelPrompt: e?.modelPrompt,
        priority: e?.priority,
      };
      check.privateModel = option;
      check.privateModelCh = optionCh;
      check.privateModelValue = e.defaultStrength;
      setModelSelected(lang === "ch" ? optionCh : option);
      setValue(e?.defaultStrength);
    } else {
      const option = {
        value: e?.value,
        label: e?.modelName,
        defaultStrength: e?.defaultStrength,
        modelDescription: e?.modelDescription,
        modelNameAlias: e?.modelNameAlias,
        modelPrompt: e?.modelPrompt,
        priority: e?.priority,
      };
      const optionCh = {
        value: e?.value,
        label: e?.modelNameAlias,
        defaultStrength: e?.defaultStrength,
        modelDescription: e?.modelDescription,
        modelNameAlias: e?.modelNameAlias,
        modelPrompt: e?.modelPrompt,
        priority: e?.priority,
      };
      check.privateModel = option;
      check.privateModelCh = optionCh;
      check.privateModelValue = e.defaultStrength;
      setModelSelected(lang === "ch" ? optionCh : option);
      setValue(e?.defaultStrength);
    }
    localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
  };

  const customStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    menu: (base) => ({
      ...base,
      width: "100%",
      borderColor: "#263238",
      backgroundColor: "#f8f8f9",
      borderRadius: "8px",
      position: "absolute",
      top: "-210%",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      backgroundColor: "#ffffff",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: responsiveData ? "12px" : "14px",
      backgroundColor: state.isSelected ? "#ffffff" : "#F8F8F9",
      color: state.isSelected ? "#677074" : "#A8ADAF",
    }),
  };

  const handleScroll = (event) => {
    const delta = Math.sign(event.deltaY);
    const newValue =
      delta > 0
        ? Math.max(parseFloat((value - 0.1).toFixed(1)), 0.1)
        : Math.min(parseFloat((value + 0.1).toFixed(1)), 1);

    const check =
      JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
    check.privateModelValue = newValue;
    localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
    setValue(newValue);
  };

  const handleClickOpenClose = () => {
    const newData = {
      isShowTrueOrFalse: true,
      isCloseTiresPopup: false,
    };
    updateDesignLibraryData(newData);
  };

  const handleChange1 = (event) => {
    const inputValue = parseFloat(event.target.value);
    if (isNaN(inputValue)) {
      setValue();
      const check =
        JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
      check.privateModelValue = "";
      localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
      return;
    }

    const parsedValue = parseFloat(inputValue);

    if (parsedValue === 0) {
      setValue(0);
      const check =
        JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
      check.privateModelValue = 0;
      localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
    } else if (!isNaN(parsedValue) && parsedValue > 0 && parsedValue <= 1) {
      const roundedValue = parseFloat(parsedValue.toFixed(1));
      setValue(roundedValue);
      const check =
        JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
      check.privateModelValue = roundedValue;
      localStorage.setItem(`promptData_${lastPart}`, JSON.stringify(check));
    } else {
      const check =
        JSON.parse(localStorage.getItem(`promptData_${lastPart}`)) || {};
      setValue(check.privateModelValue);
    }
  };

  const updatePreUploadState = (toolset, uploadedUrl) => {
    switch (toolset) {
      case 0:
      case 11:
        updateAtmosphereRefData({
          uploadedBaseImageUrl: uploadedUrl,
          feedDesignBase64: null,
        });
        const check = JSON.parse(
          localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
        );
        if (check[0]) {
          check[0].feedDesignBase64 = null;
          check[0].uploadedBaseImageUrl = uploadedUrl;
        }
        localStorage.setItem(
          `atmosphereRefLocal_${lastPart}`,
          JSON.stringify(check)
        );
        setInputKey((prevKey) => prevKey + 1);
        break;
      case 12:
        updateAtmosphereRefData({
          uploadedBaseImageUrl: uploadedUrl,
          feedDesignBase64: null,
        });
        const check2 = JSON.parse(
          localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
        );
        if (check2[1]) {
          check2[1].feedDesignBase64 = null;
          check2[1].uploadedBaseImageUrl = uploadedUrl;
        }
        localStorage.setItem(
          `atmosphereRefLocal_${lastPart}`,
          JSON.stringify(check2)
        );
        setInputKey((prevKey) => prevKey + 1);
        break;
      case 14:
        updateAtmosphereRefData1({
          uploadedBaseImageUrl: uploadedUrl,
          feedDesignBase64: null,
        });
        const check1 = JSON.parse(
          localStorage.getItem(`styleRefSwift_${lastPart}`)
        );
        if (check1) {
          check1.feedDesignBase64 = null;
          check1.uploadedBaseImageUrl = uploadedUrl;
        }
        localStorage.setItem(
          `styleRefSwift_${lastPart}`,
          JSON.stringify(check1)
        );
        setInputKey((prevKey) => prevKey + 1);
        break;
      case 13:
        updateSmartPromptData({
          uploadedBaseImageUrl: uploadedUrl,
          feedDesignBase64: null,
        });
        setInputKey1((prevKey) => prevKey + 1);
        break;
      default:
        break;
    }
  };

  const handleFileChange = (toolset, event) => {
    if (!event) {
      console.log(event);
      return null;
    }
    const files = event.target.files || event.dataTransfer.files;
    if (!files || files.length === 0) {
      console.log("No files selected");
      return null;
    }
    setIsButtonDisabled(true);
    const file = files[0];
    if (!file) {
      setIsButtonDisabled(false);
      return null;
    }

    const validTypes = ["image/jpeg", "image/png", "image/jpg", "image/webp"];
    if (validTypes.includes(file.type)) {
      const imageUrl = URL.createObjectURL(file);
      return imageUrl;
    } else {
      alert(t("image_format_alart"));
      return null;
    }
  };

  const handleFileChangeWithToolsetInput = (event, toolset) => {
    const imageType = "reference-image";
    localStorage.setItem(`imageType_${lastPart}`, JSON.stringify(imageType));
    setInputKey((prevKey) => prevKey + 1);
    const imageUrl = handleFileChange(toolset, event);
    if (imageUrl) {
      openUserSocket();
      updatePreUploadState(toolset, imageUrl);
      setTimeout(() => {
        handleBaseImageData(toolset, imageUrl);
      }, 0);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event, toolset = null) => {
    event.preventDefault();
    event.stopPropagation();
    const selectedToolset =
      toolset !== null && toolset !== undefined ? toolset : isToolset;
    const imageType = "reference-image";
    localStorage.setItem(`imageType_${lastPart}`, JSON.stringify(imageType));
    if (event.dataTransfer.files?.length === 0) {
      openUserSocket();
      const media = JSON.parse(event.dataTransfer.getData("media"));
      const updatedLink = media?.url;
      updatePreUploadState(selectedToolset, updatedLink);
      CallAwsUrl((res) => {
        handleBaseImageData(selectedToolset, handleBase64Data(res.data));
      }, updatedLink);
      if (selectedToolset === 13) {
        setIsMagicPrompt(true);
        setIsMagicImage(true);
        CallAwsUrl((res) => {
          handleBaseImageData(11, handleBase64Data(res.data));
        }, updatedLink);
      }
    } else if (event.dataTransfer.files?.length === 1) {
      openUserSocket();
      //TODO:what is this for? delete?
      const imageUrl = handleFileChange(selectedToolset, event);
      if (imageUrl) {
        updatePreUploadState(selectedToolset, imageUrl);
        handleBaseImageData(selectedToolset, imageUrl);
        if (selectedToolset === 13) {
          setIsMagicPrompt(true);
          setIsMagicImage(true);
          handleBaseImageData(11, imageUrl);
        }
      }
    }
  };

  const handleBaseImageData = (toolset, imageUrl) => {
    const img = new Image();
    img.src = imageUrl;
    const bool = false;
    img.onload = function () {
      let resizedWidth, resizedHeight, scale;
      let finalResizedImageUrl = processImage(
        img,
        computePostProcessedDimension,
        toolset,
        (dimensions) => {
          resizedWidth = dimensions.resizedWidth;
          resizedHeight = dimensions.resizedHeight;
          scale = dimensions.scale;
        }
      );
      if (scale >= SCALE_LIMIT) {
        setIsUploadScaled(true);
      }
      const proceedNextStep = () => {
        fetch(finalResizedImageUrl)
          .then((res) => res.blob())
          .then((blob) => {
            const resizedImageFile = new File([blob], "resized_image.jpg", {
              type: "image/jpg",
            });

            uploadFile(
              "reference-image",
              bool,
              resizedImageFile,
              async (data) => {
                switch (toolset) {
                  case 0:
                  case 11:
                    updateAtmosphereRefData({
                      feedDesignBase64: data,
                      uploadedBaseImageUrl: finalResizedImageUrl,
                    });
                    const check = JSON.parse(
                      localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
                    );
                    if (check[0]) {
                      check[0].feedDesignBase64 = data;
                      check[0].uploadedBaseImageUrl = data?.path;
                    }
                    localStorage.setItem(
                      `atmosphereRefLocal_${lastPart}`,
                      JSON.stringify(check)
                    );
                    setInputKey((prevKey) => prevKey + 1);
                    break;
                  case 12:
                    updateAtmosphereRefData({
                      feedDesignBase64: data,
                      uploadedBaseImageUrl: finalResizedImageUrl,
                    });
                    const check2 = JSON.parse(
                      localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
                    );
                    if (check2[1]) {
                      check2[1].feedDesignBase64 = data;
                      check2[1].uploadedBaseImageUrl = data?.path;
                    }
                    localStorage.setItem(
                      `atmosphereRefLocal_${lastPart}`,
                      JSON.stringify(check2)
                    );
                    setInputKey((prevKey) => prevKey + 1);
                    break;
                  case 14:
                    updateAtmosphereRefData1({
                      feedDesignBase64: data,
                      uploadedBaseImageUrl: finalResizedImageUrl,
                    });
                    const check1 = JSON.parse(
                      localStorage.getItem(`styleRefSwift_${lastPart}`)
                    );
                    if (check1) {
                      check1.feedDesignBase64 = data;
                      check1.uploadedBaseImageUrl = data?.path;
                    }
                    localStorage.setItem(
                      `styleRefSwift_${lastPart}`,
                      JSON.stringify(check1)
                    );
                    setInputKey1((prevKey) => prevKey + 1);
                    break;
                  case 13:
                    updateSmartPromptData({
                      feedDesignBase64: data,
                      uploadedBaseImageUrl: finalResizedImageUrl,
                    });
                    const bool = true;
                    handleGetSmartPrompt(data?.path, bool);
                    const template =
                      JSON.parse(
                        localStorage.getItem(`isTemplate_${lastPart}`)
                      ) || {};
                    if (template?.isAtmosphere !== undefined) {
                      if (!template?.isAtmosphere) {
                        handleTemplate(1);
                      }
                    }
                    setInputKey((prevKey) => prevKey + 1);
                    break;
                  default:
                    break;
                }
              },
              () => setIsButtonDisabled(false),
              (error) => {
                handleRateLimitPopup(error);
                switch (toolset) {
                  case 0:
                  case 11:
                    updateAtmosphereRefData({
                      feedDesignBase64: null,
                      uploadedBaseImageUrl: null,
                    });
                    const check = JSON.parse(
                      localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
                    );
                    if (check[0]) {
                      check[0].feedDesignBase64 = null;
                      check[0].uploadedBaseImageUrl = null;
                    }
                    localStorage.setItem(
                      `atmosphereRefLocal_${lastPart}`,
                      JSON.stringify(check)
                    );
                    setInputKey((prevKey) => prevKey + 1);
                    break;
                  case 12:
                    updateAtmosphereRefData({
                      feedDesignBase64: null,
                      uploadedBaseImageUrl: null,
                    });
                    const check2 = JSON.parse(
                      localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
                    );
                    if (check2[1]) {
                      check2[1].feedDesignBase64 = null;
                      check2[1].uploadedBaseImageUrl = null;
                    }
                    localStorage.setItem(
                      `atmosphereRefLocal_${lastPart}`,
                      JSON.stringify(check2)
                    );
                    setInputKey((prevKey) => prevKey + 1);
                    break;
                  case 14:
                    updateAtmosphereRefData1({
                      feedDesignBase64: null,
                      uploadedBaseImageUrl: null,
                    });
                    const check1 = JSON.parse(
                      localStorage.getItem(`styleRefSwift_${lastPart}`)
                    );
                    if (check1) {
                      check1.feedDesignBase64 = null;
                      check1.uploadedBaseImageUrl = null;
                    }
                    localStorage.setItem(
                      `styleRefSwift_${lastPart}`,
                      JSON.stringify(check1)
                    );
                    setInputKey((prevKey) => prevKey + 1);
                    break;
                  case 13:
                    updateSmartPromptData({
                      feedDesignBase64: null,
                      uploadedBaseImageUrl: null,
                    });
                    setInputKey1((prevKey) => prevKey + 1);
                    break;
                  default:
                    break;
                }
              },
              updateSocketData
            );
          })
          .catch((error) => {
            console.error("Error converting Data URL to File:", error);
          });
      };

      proceedNextStep();
    };
  };

  const handleRateLimitPopup = (error) => {
    updateAtmosphereRefData({ uploadedBaseImageUrl: null });
    const renderUpdate = {
      uploadedBaseImageUrl: null,
    };
    localStorage.setItem(
      `atmosphereRefLocal_${lastPart}`,
      JSON.stringify(renderUpdate)
    );
    setIsLimitPopup(true);
    setGlobalErrorMessage(error.message);
  };

  useEffect(() => {
    if (atmosphereRefLocal?.[0]?.refFidelityLevel !== activeFidelityLevel[0]) {
      setActiveFidelityLevel((prevLevels) => {
        const newLevels = { ...prevLevels };
        newLevels[0] = atmosphereRefLocal?.[0]?.refFidelityLevel;
        return newLevels;
      });
    } else if (
      atmosphereRefLocal?.[1]?.refFidelityLevel !== activeFidelityLevel[1]
    ) {
      setActiveFidelityLevel((prevLevels) => {
        const newLevels = { ...prevLevels };
        newLevels[1] = atmosphereRefLocal?.[1]?.refFidelityLevel;
        return newLevels;
      });
    }
  }, [
    atmosphereRefLocal?.[0]?.refFidelityLevel,
    atmosphereRefLocal?.[1]?.refFidelityLevel,
  ]);

  const handleImageSliderChange = (value, index) => {
    let check = JSON.parse(
      localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
    );
    if (check !== undefined && check !== null) {
      if (check[index]?.refFidelityLevel) {
        check[index].refFidelityLevel = value;
        setActiveFidelityLevel((prevLevels) => {
          const newLevels = { ...prevLevels };
          newLevels[index] = value;
          return newLevels;
        });
        localStorage.setItem(
          `atmosphereRefLocal_${lastPart}`,
          JSON.stringify(check)
        );
      }
    }
    setIsIndex(index);
  };

  const handleImageSliderChange1 = (value) => {
    let check = JSON.parse(localStorage.getItem(`styleRefSwift_${lastPart}`));
    if (check !== undefined && check !== null) {
      if (value) {
        check.refFidelityLevel = value;
        setActiveFidelityLevel1(value);
      } else if (value === 0) {
        check.refFidelityLevel = value;
        setActiveFidelityLevel(value);
      }
      localStorage.setItem(`styleRefSwift_${lastPart}`, JSON.stringify(check));
    }
  };

  const handleDeleteRef = (id) => {
    if (id === 1) {
      const check = JSON.parse(
        localStorage.getItem(`styleRefSwift_${lastPart}`)
      );
      if (check) {
        check.feedDesignBase64 = null;
        check.uploadedBaseImageUrl = null;
      }
      localStorage.setItem(`styleRefSwift_${lastPart}`, JSON.stringify(check));
      setInputKey1((prevKey) => prevKey + 1);
    } else if (id === 2) {
      const check2 = JSON.parse(
        localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
      );
      if (check2[1]) {
        check2[1].feedDesignBase64 = null;
        check2[1].uploadedBaseImageUrl = null;
      }
      localStorage.setItem(
        `atmosphereRefLocal_${lastPart}`,
        JSON.stringify(check2)
      );
      setInputKey((prevKey) => prevKey + 1);
    } else {
      const check1 = JSON.parse(
        localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
      );
      if (check1[0]) {
        check1[0].feedDesignBase64 = null;
        check1[0].uploadedBaseImageUrl = null;
      }
      localStorage.setItem(
        `atmosphereRefLocal_${lastPart}`,
        JSON.stringify(check1)
      );
      setInputKey((prevKey) => prevKey + 1);
    }
  };

  const handleSmartPromptChange = (e) => {
    let inputText = e;
    if (inputText === undefined) return;

    if (inputText === "") {
      setSmartPromptText(inputText);
      const check =
        JSON.parse(localStorage.getItem(`smartPrompt_${lastPart}`)) || {};
      check.text = inputText;
      localStorage.setItem(`smartPrompt_${lastPart}`, JSON.stringify(check));
      return;
    }

    const { truncatedText, remainingChars } = processText(inputText, limit, 2);
    setRemaining(Math.max(0, remainingChars));
    setSmartPromptText(truncatedText);
    const check =
      JSON.parse(localStorage.getItem(`smartPrompt_${lastPart}`)) || {};
    check.text = truncatedText;
    localStorage.setItem(`smartPrompt_${lastPart}`, JSON.stringify(check));
  };

  const magicPrompt = (res, bool) => {
    if (res.message === "Access token not provided") {
      updateSocketData({
        isExitPopup: true,
      });
    }

    const season = mainMenu?.find((sea) =>
      lang === "ch"
        ? t(`${sea?.name}`) === res?.atmosphere?.seasonAlias
        : t(`${sea?.name}`) === res?.atmosphere?.season
    );
    const weather = sideMenu?.find((sea) =>
      lang === "ch"
        ? t(`${sea?.name}`) === res?.atmosphere?.weatherAlias
        : t(`${sea?.name}`) === res?.atmosphere?.weather
    );
    let designData = JSON.parse(localStorage.getItem(`dlData_${lastPart}`));
    let tiresData = JSON.parse(
      localStorage.getItem(`designLibraryData_${lastPart}`)
    );
    let atmosphereData = JSON.parse(
      localStorage.getItem(`atmosphereData_${lastPart}`)
    );
    let cameraData = JSON.parse(localStorage.getItem(`cameraData_${lastPart}`));
    let graphicData = JSON.parse(
      localStorage.getItem(`graphicStyleData_${lastPart}`)
    );
    let isPromptData = JSON.parse(
      localStorage.getItem(`promptData_${lastPart}`)
    );
    let isTemplateData = JSON.parse(
      localStorage.getItem(`isTemplate_${lastPart}`)
    );
    let activeToolsetLocal = JSON.parse(
      localStorage.getItem(`activeToolset_${lastPart}`)
    );

    if (designData && res?.tiers?.designLibraryId !== 99) {
      designData.isDesignLibraryId = res?.tiers?.designLibraryId;
      designData.prevDesignLibraryId = res?.tiers?.designLibraryId;
      designData.designLibraryName = res?.designLibraryName;
    }

    if (tiresData && res?.tiers?.firstTierId !== 9999) {
      tiresData.firstTierName = res?.tiers?.firstTierName;
      tiresData.firstTierNameCh = res?.tiers?.firstTierAlias;
      tiresData.isFirstTierId = res?.tiers?.firstTierId;
      tiresData.isSecondTierId = res?.tiers?.secondTierId;
      tiresData.secondTierName = res?.tiers?.secondTierName;
      tiresData.secondTierNameCh = res?.tiers?.secondTierAlias;
      tiresData.styleId = res?.tiers?.styleId;
      tiresData.styleURL = res?.tiers?.styleUrl;
    }

    if (graphicData && res?.tiers?.firstTierId !== 9999) {
      graphicData.graphicSelectedOption = res?.graphics;
      graphicData.graphicId = res?.graphics?.id;
      graphicData.graphicName = res?.graphics?.name;
    }

    if (cameraData && res?.camera !== 9999) {
      cameraData.cameraSelectedOption = res?.camera;
      cameraData.cameraViewId = res?.camera?.id;
      cameraData.cameraViewName = res?.camera?.name;
    }

    if (atmosphereData && res?.tiers?.firstTierId !== 9999) {
      atmosphereData.defaultSelectedAtmosphereId = res?.atmosphere?.id;
      atmosphereData.defaultSelectedAtmosphereUrl = res?.atmosphere?.url;
      atmosphereData.seasonIcon = weather?.image;
      atmosphereData.seasonName = res?.atmosphere?.weather;
      atmosphereData.seasonNameSelected = res?.atmosphere?.season;
      atmosphereData.seasonNameSelectedId = season?.id;
    }

    if (isPromptData) {
      const chinesePattern = /[\u4E00-\u9FFF]/;
      const isChinese = chinesePattern.test(res?.promptTarget);
      const isPromptRes = isChinese ? res?.promptTarget : res?.promptEnUs;
      isPromptData.prompt = isPromptRes;
      setSmartPromptText(isPromptRes);
      const check =
        JSON.parse(localStorage.getItem(`smartPrompt_${lastPart}`)) || {};
      check.text = isPromptRes;
      localStorage.setItem(`smartPrompt_${lastPart}`, JSON.stringify(check));
    }

    if (
      isTemplateData &&
      activeToolsetLocal &&
      activeToolsetLocal?.toolsetActive !== 4
    ) {
      if (res?.tiers?.firstTierId === 9999) {
        isTemplateData.isTemplate = false;
        isTemplateData.isPrompt = true;
      } else {
        isTemplateData.isTemplate = true;
        isTemplateData.isPrompt = true;
      }
    }

    localStorage.setItem(
      `designLibraryData_${lastPart}`,
      JSON.stringify(tiresData)
    );
    localStorage.setItem(`dlData_${lastPart}`, JSON.stringify(designData));
    localStorage.setItem(
      `graphicStyleData_${lastPart}`,
      JSON.stringify(graphicData)
    );
    localStorage.setItem(
      `atmosphereData_${lastPart}`,
      JSON.stringify(atmosphereData)
    );
    localStorage.setItem(`cameraData_${lastPart}`, JSON.stringify(cameraData));
    localStorage.setItem(
      `isTemplate_${lastPart}`,
      JSON.stringify(isTemplateData)
    );
    localStorage.setItem(
      `promptData_${lastPart}`,
      JSON.stringify(isPromptData)
    );
    setTimeout(() => {
      setIsMagicImage(false);
      setIsMagicPrompt(false);
    }, 100);
    setTimeout(() => {
      if (bool) {
        setOpen8(true);
      }
    }, 200);
  };

  const handleGetSmartPrompt = (image = "", bool = false) => {
    const userId = isLoginUserDetails?.id;
    const message = image === "" ? smartPrompt?.text : "";
    const language = languageCodes[lang] || "en-us";
    setIsMagicPrompt(true);
    GetSmartPromptData(
      (res) => {
        if (res) {
          // if (res) { && (res?.tiers?.firstTierId !== 9999 || res?.camera?.id !== 9999)) {
          let smartLocal = JSON.parse(
            localStorage.getItem(`smartPromptRes_${lastPart}`)
          );
          const responseData = {
            response: res,
            prompt: message,
          };
          if (!smartLocal || smartLocal?.length === 0) {
            localStorage.setItem(
              `smartPromptRes_${lastPart}`,
              JSON.stringify([responseData])
            );
          } else {
            smartLocal.push(responseData);
            localStorage.setItem(
              `smartPromptRes_${lastPart}`,
              JSON.stringify(smartLocal)
            );
          }
          magicPrompt(res, bool);
          // } else {
          //   setIsMagicPrompt(false);
        }
      },
      message,
      userId,
      image,
      language
    );
  };

  const handleRevert = () => {
    const smartLocal = JSON.parse(
      localStorage.getItem(`smartPromptRes_${lastPart}`)
    );
    if (smartLocal && smartLocal?.length > 1) {
      const lastData = smartLocal[smartLocal?.length - 2];
      const res = lastData?.response;
      magicPrompt(res);
      smartLocal.pop();
      localStorage.setItem(
        `smartPromptRes_${lastPart}`,
        JSON.stringify(smartLocal)
      );
    }
  };

  useEffect(() => {
    if (open8) {
      setTimeout(() => {
        setOpen8(false);
      }, 5000);
    }
  }, [open8]);

  const handleOpen = (isOpen) => {
    setOpen(isOpen);
  };

  const handleOpen1 = (isOpen) => {
    setOpen1(isOpen);
  };

  const handleOpen2 = (isOpen) => {
    setOpen2(isOpen);
  };

  const handleOpen3 = (isOpen) => {
    setOpen3(isOpen);
  };

  const handleOpen4 = (isOpen) => {
    setOpen4(isOpen);
  };

  const handleOpen5 = (isOpen) => {
    setOpen5(isOpen);
  };

  const handleOpen6 = (isOpen) => {
    setOpen6(isOpen);
  };

  const handleOpen7 = (isOpen) => {
    setOpen7(isOpen);
  };

  const handleOpen11 = (isOpen) => {
    setOpen11(isOpen);
  };

  const handleOpen9 = (isOpen) => {
    setOpen9(isOpen);
  };

  const handleMouseEnter = () => {
    const timer = setTimeout(() => {
      setOpen5(true);
    }, 500);
    setHoverTimer(timer);
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimer);
    setHoverTimer(null);
    setOpen5(false);
  };

  const seasonName = isAtmosphereSwift
    ? t(atmosphereData1?.seasonNameSelected || "sunny")
    : t(atmosphere?.seasonNameSelected || "sunny");

  const seasonIcon = isAtmosphereSwift
    ? t(atmosphereData1?.seasonIcon || sunny)
    : atmosphere?.seasonIcon || sunny;

  return (
    <div className="archi" key={templateDisable}>
      <div className={`archi__container ${templateDisable ? "disable" : ""}`}>
        <div className="archi__sections">
          <div className="archi__section">
            <div className="archi__section__main">
              <div
                className="archi__section__main__heading"
                style={{ position: "relative" }}
              >
                <h4>{t("design_library")}</h4>
              </div>
            </div>
            <div className="archi__section__main-new" data-scrollable="true">
              {isActiveToolset !== 4 && !isAtmosphereSwift ? (
                <div className="archi__section__main__smart-prompt">
                  <div className="archi__section__main__smart-prompt__container">
                    <Popover
                      overlayClassName="custom-popover-gallery"
                      content={t("prompt_disable_atmosphere")}
                      arrow={true}
                      trigger="hover"
                      placement="right"
                      open={isAtmosphereSwift ? open : false}
                      onOpenChange={handleOpen}
                    >
                      <div
                        className={`archi__section__main__smart-prompt__heading ${
                          istemplateLocal?.isPrompt ? "" : "off"
                        } ${isAtmosphereSwift ? "disable" : ""}`}
                        onClick={
                          isAtmosphereSwift ? null : () => handleTemplate(3)
                        }
                      >
                        <div
                          className={`archi__section__main__smart-prompt__heading__left ${
                            isAtmosphereSwift ? "disable" : ""
                          }`}
                        >
                          <h4>{t("prompt")}</h4>
                          <div
                            className="tooltip-top"
                            onClick={(event) => handleActiveLearn(22, event)}
                          >
                            {!responsiveData && <img src={intro} alt="intro" />}
                            <Portal
                              style={{
                                display: `${
                                  activeLearnBool?.boolean === true &&
                                  activeLearnBool?.id === 22
                                    ? "block"
                                    : "none"
                                }`,
                              }}
                            >
                              {!responsiveData && (
                                <TipsPopups1
                                  heading={t("prompt")}
                                  content={t("smart_prompt_tip")}
                                  styles={lang === "ch" ? "340px" : "400px"}
                                  image={promptImage}
                                  position={activeLearnBool?.position}
                                />
                              )}
                            </Portal>
                          </div>
                          {/* <span>{t("new")}</span> */}
                        </div>
                        <div className="archi__section__main__smart-prompt__heading__right">
                          <Switch
                            onChange={
                              isAtmosphereSwift ? null : () => handleTemplate(3)
                            }
                            checked={
                              istemplateLocal?.isPrompt !== undefined
                                ? istemplateLocal?.isPrompt
                                : false
                            }
                            height={responsiveData ? 14 : 16}
                            width={responsiveData ? 28 : 32}
                            handleDiameter={12}
                            offColor="#A8ADAF"
                            onColor="#000"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            className={`${
                              responsiveData
                                ? istemplateLocal?.isPrompt !== undefined &&
                                  istemplateLocal?.isPrompt
                                  ? "small-on "
                                  : "small-off "
                                : ""
                            }react-switch-Undragable`}
                          />
                        </div>
                      </div>
                    </Popover>
                    {(istemplateLocal?.isPrompt ?? isPrompt) && (
                      <>
                        {!isMagicPrompt ? (
                          <div
                            className="archi__section__main__smart-prompt__textarea"
                            onDragOver={(e) => {
                              handleDragOver(e);
                              setIsToolset(13);
                            }}
                            onDrop={(e) => {
                              handleDrop(e);
                              setIsToolset(13);
                            }}
                          >
                            {!typing && smartPromptText === "" && (
                              <img src={placeholder} alt="" />
                            )}
                            <Popover
                              overlayClassName="custom-popover-gallery"
                              content={t("smart_prompt_placeholder_hover")}
                              arrow={true}
                              trigger="hover"
                              placement="right"
                              open={open5}
                              // onOpenChange={handleOpen5}
                            >
                              <textarea
                                id="myTextArea2"
                                placeholder={t("smart_prompt_placeholder")}
                                value={smartPromptText}
                                draggable={false}
                                style={{
                                  resize: "none",
                                  border:
                                    smartPromptText !== ""
                                      ? "1px solid black"
                                      : "1px solid #ededed",
                                }}
                                onChange={(e) => {
                                  handleSmartPromptChange(e.target.value);
                                  handleTextAreaChange(e.target.value);
                                }}
                                className={`auto-resize-textarea ${
                                  smartPromptText === "" ? "empty" : ""
                                }`}
                                onFocus={handleTypingFocus}
                                onBlur={handleTypingBlur}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                              ></textarea>
                              {/* {isMjSelect && (
                                <p>{t("mj_smart_prompt")}</p>
                              )} */}
                              {/* <p
                                style={{
                                  color:
                                    remaining === limit ? "red" : "inherit",
                                }}
                              >
                                <span>{remaining}</span> / {limit}
                              </p> */}
                            </Popover>
                          </div>
                        ) : (
                          <div className="archi__section__main__smart-prompt__loading">
                            <div className="archi__section__main__smart-prompt__loading__box">
                              <img src={gif} alt="gif" />
                              <p>
                                {isMagicImage
                                  ? t("magic_prompt_load_image")
                                  : t("magic_prompt_load_text")}
                              </p>
                            </div>
                          </div>
                        )}
                        <div className="archi__section__main__smart-prompt__buttons">
                          <div
                            className={`archi__section__main__smart-prompt__buttons__left ${
                              smartLocalRes && smartLocalRes?.length > 1
                                ? ""
                                : "disable"
                            }`}
                            onClick={handleRevert}
                          >
                            <img src={revert} alt="revert-icon" />
                          </div>
                          <div className="archi__section__main__smart-prompt__buttons__right">
                            <Popover
                              overlayClassName="template-disable-popover"
                              content={t("smart_prompt_button_disbale")}
                              arrow={true}
                              trigger="click"
                              placement="right"
                              open={smartPromptText === "" ? open3 : false}
                              onOpenChange={handleOpen3}
                            >
                              <>
                                <Popover
                                  overlayClassName="custom-popover-gallery"
                                  content={t("smart_prompt_button_disbale")}
                                  arrow={true}
                                  trigger="hover"
                                  placement="right"
                                  open={open9}
                                  onOpenChange={handleOpen9}
                                >
                                  <button
                                    className={`${
                                      isMagicPrompt || smartPromptText === ""
                                        ? "disabled"
                                        : ""
                                    }`}
                                    onClick={
                                      isMagicPrompt || smartPromptText === ""
                                        ? null
                                        : () => handleGetSmartPrompt("")
                                    }
                                  >
                                    {t("smart_guide")}
                                  </button>
                                </Popover>
                              </>
                            </Popover>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  {isAtmosphereSwift ? (
                    <div className="archi__section__main1">
                      <div
                        className={`archi__section__main1__heading ${
                          !isAtmosphere ? "disable" : ""
                        }`}
                      >
                        <div
                          className="archi__section__main1__heading__left"
                          style={{ position: "relative" }}
                        >
                          <h4>{t("prompt")}</h4>
                          <div
                            className="tooltip-top"
                            onClick={(event) => handleActiveLearn(28, event)}
                          >
                            {!responsiveData && <img src={intro} alt="intro" />}
                            <Portal
                              style={{
                                display: `${
                                  activeLearnBool?.boolean === true &&
                                  activeLearnBool?.id === 28
                                    ? "block"
                                    : "none"
                                }`,
                              }}
                            >
                              {!responsiveData && (
                                <TipsPopups1
                                  heading={t("prompt")}
                                  content={t("atmosphere_prompt_placeholder")}
                                  styles="220px"
                                  image={promptImageVideo}
                                  position={activeLearnBool?.position}
                                />
                              )}
                            </Portal>
                          </div>
                        </div>{" "}
                      </div>
                      {(istemplateLocal?.isAtmosphere || isAtmosphere) && (
                        <div className="archi__section__main1__input">
                          <textarea
                            ref={textarea3Ref}
                            id="myTextArea1"
                            placeholder={t("atmosphere_prompt_placeholder")}
                            value={defaultText3}
                            draggable={false}
                            style={{ resize: "none" }}
                            onChange={(e) => {
                              handleTextAreaChange3(e.target.value);
                              setTyping(true);
                            }}
                            className="auto-resize-textarea"
                            onFocus={handleTypingFocus}
                            onBlur={handleTypingBlur}
                          ></textarea>
                          {/* <p
                            style={{
                              color: remaining3 === 100 ? "red" : "inherit",
                            }}
                          >
                            <span>{remaining3}</span> / {100}
                          </p> */}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="archi__section__main1">
                      <div className={`archi__section__main1__heading`}>
                        <div
                          className="archi__section__main1__heading__left"
                          style={{ position: "relative" }}
                        >
                          <h4>{t("prompt")}</h4>
                          <div
                            className="tooltip-top"
                            onClick={(event) => handleActiveLearn(26, event)}
                          >
                            {!responsiveData && <img src={intro} alt="intro" />}
                            <Portal
                              style={{
                                display: `${
                                  activeLearnBool?.boolean === true &&
                                  activeLearnBool?.id === 26
                                    ? "block"
                                    : "none"
                                }`,
                              }}
                            >
                              {!responsiveData && (
                                <TipsPopups1
                                  heading={t("prompt")}
                                  content={t("prompt_tooltip_video")}
                                  styles="220px"
                                  image={promptImageVideo}
                                  position={activeLearnBool?.position}
                                />
                              )}
                            </Portal>
                          </div>
                        </div>{" "}
                      </div>
                      <div className="archi__section__main1__input">
                        <textarea
                          ref={textarea2Ref}
                          id="myTextArea1"
                          placeholder={t("prompt_placeholder")}
                          value={defaultText2}
                          draggable={false}
                          style={{ resize: "none" }}
                          onChange={(e) => {
                            handleTextAreaChange2(e.target.value);
                            setTyping(true);
                          }}
                          className="auto-resize-textarea"
                          onFocus={handleTypingFocus}
                          onBlur={handleTypingBlur}
                        ></textarea>
                        {/* <p
                          style={{
                            color: remaining2 === 100 ? "red" : "inherit",
                          }}
                        >
                          <span>{remaining2}</span> / {100}
                        </p> */}
                      </div>
                    </div>
                  )}
                </>
              )}
              {!isMagicPrompt ? (
                <div className="archi__section__main__templates">
                  {!isAtmosphereSwift ? (
                    <>
                      <div className="archi__section__main__sub__main">
                        <Popover
                          overlayClassName="custom-popover-gallery"
                          content={t("template-disable")}
                          arrow={true}
                          trigger="hover"
                          placement="right"
                          open={isActiveToolset === 4 ? open : false}
                          onOpenChange={handleOpen}
                        >
                          <>
                            <Popover
                              overlayClassName="custom-popover-gallery"
                              content={t("enable_disable")}
                              arrow={true}
                              trigger="hover"
                              placement="right"
                              open={isActiveToolset !== 4 ? open4 : false}
                              onOpenChange={handleOpen4}
                            >
                              <div
                                className={`archi__section__main__sub__heading ${
                                  istemplateLocal?.isTemplate ? "" : "off"
                                }`}
                                onClick={() =>
                                  handleTemplate(isActiveToolset !== 4 ? 2 : 0)
                                }
                              >
                                <div className="archi__section__main__sub__heading__left">
                                  <h4>{t("Template")}</h4>
                                  <div
                                    className="tooltip-top"
                                    onClick={(event) =>
                                      handleActiveLearn(21, event)
                                    }
                                  >
                                    {!responsiveData && (
                                      <img src={intro} alt="intro" />
                                    )}
                                    <Portal
                                      style={{
                                        display: `${
                                          activeLearnBool?.boolean === true &&
                                          activeLearnBool?.id === 21
                                            ? "block"
                                            : "none"
                                        }`,
                                      }}
                                    >
                                      {!responsiveData && (
                                        <TipsPopups1
                                          heading={t("Template")}
                                          content={t("template_content")}
                                          styles={
                                            lang === "ch" ? "240px" : "300px"
                                          }
                                          image={template}
                                          position={activeLearnBool?.position}
                                        />
                                      )}
                                    </Portal>
                                  </div>
                                </div>
                                <div className="archi__section__main__sub__heading__right">
                                  <Switch
                                    onChange={() =>
                                      handleTemplate(
                                        isActiveToolset !== 4 ? 2 : 0
                                      )
                                    }
                                    checked={
                                      istemplateLocal?.isTemplate !== undefined
                                        ? istemplateLocal?.isTemplate
                                        : false
                                    }
                                    height={responsiveData ? 14 : 16}
                                    width={responsiveData ? 28 : 32}
                                    handleDiameter={12}
                                    offColor="#A8ADAF"
                                    onColor="#000"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    className={`${
                                      responsiveData
                                        ? istemplateLocal?.isTemplate !==
                                            undefined &&
                                          istemplateLocal?.isTemplate
                                          ? "small-on "
                                          : "small-off "
                                        : ""
                                    }react-switch-Undragable`}
                                  />
                                </div>
                              </div>
                            </Popover>
                          </>
                        </Popover>
                        {(istemplateLocal?.isTemplate ?? isTemplate) && (
                          <div className="archi__section__main__sub">
                            <div
                              className={`archi__section__main__sub__sec ${
                                designLibraryData?.isCloseTiresPopup === true &&
                                designLibraryData?.isDisplayAlldata === false
                                  ? designLibrary?.isDesignLibraryId === 22 &&
                                    "active"
                                  : designLibrary?.prevDesignLibraryId === 22 &&
                                    "active"
                              }`}
                              onClick={() => handleActiveTires(22)}
                            >
                              <img src={archib} alt="Archi-icon" />
                              <NavLink>{t("archi")}</NavLink>
                            </div>
                            <div
                              className={`archi__section__main__sub__sec ${
                                designLibraryData?.isCloseTiresPopup === true &&
                                designLibraryData?.isDisplayAlldata === false
                                  ? designLibrary?.isDesignLibraryId === 30 &&
                                    "active"
                                  : designLibrary?.prevDesignLibraryId === 30 &&
                                    "active"
                              }`}
                              onClick={() => handleActiveTires(30)}
                            >
                              <img src={interior} alt="Interior-icon" />
                              <NavLink>{t("interior")}</NavLink>
                            </div>
                            <div
                              className={`archi__section__main__sub__sec ${
                                designLibraryData?.isCloseTiresPopup === true &&
                                designLibraryData?.isDisplayAlldata === false
                                  ? designLibrary?.isDesignLibraryId === 31 &&
                                    "active"
                                  : designLibrary?.prevDesignLibraryId === 31 &&
                                    "active"
                              }`}
                              onClick={() => handleActiveTires(31)}
                            >
                              <img src={landsc} alt="Landscape-icon" />
                              <NavLink>{t("landsc")}</NavLink>
                            </div>
                            <div
                              className={`archi__section__main__sub__sec ${
                                designLibraryData?.isCloseTiresPopup === true &&
                                designLibraryData?.isDisplayAlldata === false
                                  ? designLibrary?.isDesignLibraryId === 32 &&
                                    "active"
                                  : designLibrary?.prevDesignLibraryId === 32 &&
                                    "active"
                              }`}
                              onClick={() => handleActiveTires(32)}
                            >
                              <img src={urbanDesign} alt="graphic-icon" />
                              <NavLink>{t("urbanDesign_name")}</NavLink>
                            </div>
                          </div>
                        )}
                      </div>
                      {(istemplateLocal?.isTemplate ?? isTemplate) && (
                        <div
                          className="archi__section__main"
                          style={{ display: checked ? "flex" : "none" }}
                        >
                          <div
                            className="archi__section__main__image"
                            onClick={handleTiresPopup}
                          >
                            {tiresData?.styleURL ? (
                              <img
                                src={tiresData?.styleURL}
                                alt={tiresData?.styleId}
                              />
                            ) : (
                              (
                                <img
                                  src={`${AIRI_AWS_URL}/ciTemp/22/1/1/1.jpg`}
                                  alt="defalut"
                                />
                              ) ?? (
                                <>
                                  <img src={defaultimg} alt="default-img" />
                                  <img
                                    src={defaulticon}
                                    alt="default-img"
                                    className="icon"
                                  />
                                </>
                              )
                            )}
                            <div className="archi__section__main__content">
                              {!tiresData?.styleURL ||
                              tiresData?.styleURL !== undefined ? (
                                <>
                                  <div className="archi__section__main__content__sec">
                                    <span>
                                      {selectedSecondTierData?.name ||
                                        tiresData?.secondTierName ||
                                        "High-rise"}
                                    </span>
                                  </div>
                                </>
                              ) : (
                                <Loader />
                              )}
                            </div>
                          </div>
                          <div className="archi__section__main__sec1">
                            <div className="archi__section__main__graphic">
                              <div
                                className="archi__section__main__graphic__heading"
                                style={{ position: "relative" }}
                              >
                                <h4>{t("graphic_style")}</h4>
                              </div>
                              <div className="archi__section__main__graphic__section">
                                <GraphicStyleDropDown data={apiGraphicData} />
                              </div>
                            </div>
                            <div className="archi__section__main__camera">
                              <div
                                className="archi__section__main__camera__heading"
                                style={{ position: "relative" }}
                              >
                                <h4>{t("camera")}</h4>
                                {!responsiveData && (
                                  <div
                                    className="tooltip-top"
                                    onClick={(e) => handleActiveLearn(1, e)}
                                  >
                                    <img src={intro} alt="intro" />
                                    <Portal
                                      style={{
                                        display: `${
                                          activeLearnBool?.boolean === true &&
                                          activeLearnBool?.id === 1
                                            ? "block"
                                            : "none"
                                        }`,
                                      }}
                                    >
                                      <TipsPopups1
                                        heading={t("camera")}
                                        content={t("camera_tooltip_design")}
                                        styles={
                                          lang === "ch" ? "100px" : "120px"
                                        }
                                        image={cameraimage}
                                        position={activeLearnBool?.position}
                                      />
                                    </Portal>
                                  </div>
                                )}
                              </div>
                              <div className="archi__section__main__camera__section">
                                <CameraDropDown data={apiCameraData} />
                              </div>
                            </div>
                            <div className="archi__section__main__atmosphere">
                              <div
                                className="archi__section__main__atmosphere__heading"
                                style={{ position: "relative" }}
                              >
                                <h4>{t("atmosphere")}</h4>
                                {!responsiveData && (
                                  <div
                                    className="tooltip-top"
                                    onClick={(e) => handleActiveLearn(2, e)}
                                  >
                                    <img src={intro} alt="intro" />
                                    <Portal
                                      style={{
                                        display: `${
                                          activeLearnBool?.boolean === true &&
                                          activeLearnBool?.id === 2
                                            ? "block"
                                            : "none"
                                        }`,
                                      }}
                                    >
                                      <TipsPopups1
                                        heading={t("atmosphere")}
                                        content={t("atmosphere_tooltip")}
                                        styles={
                                          lang === "ch" ? "100px" : "120px"
                                        }
                                        image={atmosphereimage}
                                        position={activeLearnBool?.position}
                                      />
                                    </Portal>
                                  </div>
                                )}
                              </div>
                              {dlData?.prevDesignLibraryId === 99 ? (
                                <div
                                  className="archi__section__main__atmosphere__sec"
                                  onClick={null}
                                >
                                  <div className="archi__section__main__atmosphere__sec__sub">
                                    <div className="archi__section__main__atmosphere__sec__sub__section">
                                      <div
                                        className="archi__section__main__atmosphere__sec__sub__section__atom"
                                        style={{
                                          cursor: "default",
                                          background: "white",
                                          border: "1px solid #EDEDED",
                                        }}
                                      >
                                        <span>{t("graphic")}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="archi__section__main__atmosphere__sec"
                                  onClick={handleClickOpenClose}
                                >
                                  <div className="archi__section__main__atmosphere__sec__sub">
                                    <div className="archi__section__main__atmosphere__sec__sub__section">
                                      <div className="archi__section__main__atmosphere__sec__sub__section__atom">
                                        <img
                                          src={atmosphere?.seasonIcon || sunny}
                                          alt={
                                            atmosphere?.seasonNameSelected ||
                                            "sunny"
                                          }
                                        />
                                        <span>
                                          {t(
                                            atmosphere?.seasonNameSelected ||
                                              "sunny"
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        className="archi__section__atmosphere"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {" "}
                        <Popover
                          overlayClassName="custom-popover-gallery"
                          content={t("style-ref-disable")}
                          arrow={true}
                          trigger="hover"
                          placement="right"
                          open={isActiveToolset === 4 ? open1 : false}
                          onOpenChange={handleOpen1}
                        >
                          <div style={{ width: "100%" }}>
                            <Popover
                              overlayClassName="custom-popover-gallery"
                              content={t("enable_disable")}
                              arrow={true}
                              trigger="hover"
                              placement="right"
                              open={isActiveToolset !== 4 ? open6 : false}
                              onOpenChange={handleOpen6}
                            >
                              <div
                                className={`archi__section__atmosphere__heading ${
                                  !istemplateLocal?.isAtmosphere ? "off" : ""
                                }`}
                                onClick={() =>
                                  handleTemplate(isActiveToolset !== 4 ? 1 : 0)
                                }
                              >
                                <div
                                  className="archi__section__atmosphere__heading__left"
                                  style={{ position: "relative" }}
                                >
                                  <h4>{t("atmosphere_shape_ref")}</h4>
                                  <div
                                    className="tooltip-top"
                                    onClick={(event) =>
                                      handleActiveLearn(23, event)
                                    }
                                  >
                                    {!responsiveData && (
                                      <img src={intro} alt="intro" />
                                    )}
                                    <Portal
                                      style={{
                                        display: `${
                                          activeLearnBool?.boolean === true &&
                                          activeLearnBool?.id === 23
                                            ? "block"
                                            : "none"
                                        }`,
                                      }}
                                    >
                                      {!responsiveData && (
                                        <TipsPopups1
                                          heading={isMjSelect ? t("mjstyle_ref_title") : t("atmosphere_shape_ref")}
                                          content={isMjSelect ? t("mjstyle_ref_content") : t("Reference_content")}
                                          styles={
                                            lang === "ch" ? "80px" : "100px"
                                          }
                                          image={isMjSelect ? stylerefimages1[lang] : stylerefimage}
                                          position={activeLearnBool?.position}
                                        />
                                      )}
                                    </Portal>
                                  </div>
                                </div>
                                <div className="archi__section__atmosphere__heading__right">
                                  <Switch
                                    onChange={() =>
                                      handleTemplate(
                                        isActiveToolset !== 4 ? 1 : 0
                                      )
                                    }
                                    checked={
                                      istemplateLocal?.isAtmosphere !==
                                      undefined
                                        ? istemplateLocal?.isAtmosphere
                                        : false
                                    }
                                    height={responsiveData ? 14 : 16}
                                    width={responsiveData ? 28 : 32}
                                    handleDiameter={12}
                                    offColor="#A8ADAF"
                                    onColor="#000"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    className={`${
                                      responsiveData
                                        ? istemplateLocal?.isAtmosphere !==
                                            undefined &&
                                          istemplateLocal?.isAtmosphere
                                          ? "small-on "
                                          : "small-off "
                                        : ""
                                    }react-switch-Undragable`}
                                  />
                                </div>
                              </div>
                            </Popover>
                          </div>
                        </Popover>
                        {(istemplateLocal?.isAtmosphere ?? isAtmosphere) && (
                          <Popover
                            overlayClassName="custom-popover-gallery"
                            content={t("style_ref_uploaded")}
                            arrow={true}
                            trigger="hover"
                            placement="right"
                            open={open8}
                          >
                            <>
                              <Popover
                                overlayClassName="custom-popover-gallery"
                                content={t("inpaint_ref_popover")}
                                arrow={true}
                                trigger="hover"
                                placement="right"
                                open={open10}
                              >
                                {isActiveToolset !== 0 ? (
                                  <div className="archi__section__atmosphere__image">
                                    <div className="archi__section__atmosphere__image__base">
                                      <div
                                        className="card"
                                        onDragOver={(e) => {
                                          handleDragOver(e);
                                          setIsToolset(11);
                                        }}
                                        onDrop={(e) => {
                                          handleDrop(e);
                                          setIsToolset(11);
                                        }}
                                        onClick={() => setIsToolset(11)}
                                        key={inputKey}
                                      >
                                        <input
                                          key={inputKey}
                                          type="file"
                                          accept=".jpeg, .jpg, .png, .webp"
                                          id="fileInputRef"
                                          style={{ display: "none" }}
                                          onChange={(event) =>
                                            handleFileChangeWithToolsetInput(
                                              event,
                                              11
                                            )
                                          }
                                        />
                                        {atmosphereRefLocal !== undefined &&
                                          atmosphereRefLocal !== null && (
                                            <>
                                              {!atmosphereRefLocal[0]
                                                ?.feedDesignBase64 ? (
                                                <label
                                                  htmlFor="fileInputRef"
                                                  key={inputKey}
                                                  style={{
                                                    cursor:
                                                      atmosphereRefLocal[0]
                                                        ?.uploadedBaseImageUrl &&
                                                      !atmosphereRefLocal[0]
                                                        ?.feedDesignBase64
                                                        ? "progress"
                                                        : "pointer",
                                                  }}
                                                >
                                                  <div
                                                    className="card-content"
                                                    onDragOver={(e) => {
                                                      handleDragOver(e);
                                                      setIsToolset(11);
                                                    }}
                                                    onDrop={(e) => {
                                                      handleDrop(e);
                                                      setIsToolset(11);
                                                    }}
                                                  >
                                                    {atmosphereRefLocal[0]
                                                      ?.uploadedBaseImageUrl ? (
                                                      <>
                                                        <img
                                                          className="uploaded"
                                                          src={
                                                            atmosphereRefLocal[0]
                                                              ?.uploadedBaseImageUrl
                                                          }
                                                          draggable="false"
                                                          alt=""
                                                        />
                                                        <div
                                                          className="ref-delete"
                                                          onClick={() =>
                                                            handleDeleteRef(0)
                                                          }
                                                        >
                                                          <img
                                                            className={`${
                                                              atmosphereRefLocal[0]
                                                                ?.feedDesignBase64
                                                                ? "active"
                                                                : ""
                                                            }`}
                                                            src={deleteicon}
                                                            alt="refDelete"
                                                          />
                                                        </div>
                                                        {atmosphereRefLocal[0]
                                                          ?.feedDesignBase64 ===
                                                          null ||
                                                        atmosphereRefLocal[0]
                                                          ?.feedDesignBase64 ===
                                                          undefined ? (
                                                          <>
                                                            <div className="gradient-mask"></div>
                                                            <div className="text-display">
                                                              {t(
                                                                "uploading..."
                                                              )}
                                                            </div>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    ) : (
                                                      <>
                                                        <img
                                                          className="icon"
                                                          src={baseicon}
                                                          alt=""
                                                        />
                                                        <p>
                                                          {t(
                                                            "upload_ref_image"
                                                          )}
                                                        </p>
                                                      </>
                                                    )}
                                                  </div>
                                                </label>
                                              ) : (
                                                <label
                                                  key={inputKey}
                                                  htmlFor="fileInputRef"
                                                  style={{
                                                    cursor:
                                                      atmosphereRefLocal[0]
                                                        ?.uploadedBaseImageUrl &&
                                                      !atmosphereRefLocal[0]
                                                        ?.feedDesignBase64
                                                        ? "progress"
                                                        : "pointer",
                                                  }}
                                                >
                                                  <div
                                                    className="card-content"
                                                    onDragOver={(e) => {
                                                      handleDragOver(e);
                                                      setIsToolset(11);
                                                    }}
                                                    onDrop={(e) => {
                                                      handleDrop(e);
                                                      setIsToolset(11);
                                                    }}
                                                  >
                                                    {atmosphereRefLocal[0]
                                                      ?.uploadedBaseImageUrl ? (
                                                      <>
                                                        <img
                                                          className="uploaded"
                                                          src={
                                                            atmosphereRefLocal[0]
                                                              ?.uploadedBaseImageUrl
                                                          }
                                                          draggable="false"
                                                          alt=""
                                                        />
                                                        <div
                                                          className="ref-delete"
                                                          onClick={() =>
                                                            handleDeleteRef(0)
                                                          }
                                                        >
                                                          <img
                                                            className={`${
                                                              atmosphereRefLocal[0]
                                                                ?.feedDesignBase64
                                                                ? "active"
                                                                : ""
                                                            }`}
                                                            src={deleteicon}
                                                            alt="refDelete"
                                                          />
                                                        </div>
                                                        {atmosphereRefLocal[0]
                                                          ?.feedDesignBase64 ===
                                                          null ||
                                                        atmosphereRefLocal[0]
                                                          ?.feedDesignBase64 ===
                                                          undefined ? (
                                                          <>
                                                            <div className="gradient-mask"></div>
                                                            <div className="text-display">
                                                              {t(
                                                                "uploading..."
                                                              )}
                                                            </div>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    ) : (
                                                      <>
                                                        <img
                                                          className="icon"
                                                          src={baseicon}
                                                          alt=""
                                                        />
                                                        <p>
                                                          {t(
                                                            "upload_ref_image"
                                                          )}
                                                        </p>
                                                      </>
                                                    )}
                                                  </div>
                                                </label>
                                              )}
                                            </>
                                          )}
                                      </div>
                                    </div>
                                    {atmosphereRefLocal !== undefined &&
                                      atmosphereRefLocal !== null && (
                                        <div className="archi__section__atmosphere__image__weight">
                                          <Slider
                                            // key={activeFidelityLevel[0]}
                                            value={
                                              activeFidelityLevel[0] ??
                                              atmosphereRefLocal[0]
                                                ?.refFidelityLevel
                                            }
                                            disabled={sliderDisable}
                                            step={1}
                                            min={1}
                                            max={9}
                                            onChange={(e) =>
                                              handleImageSliderChange(e, 0)
                                            }
                                          />
                                          <span>
                                            {activeFidelityLevel[0] ??
                                              atmosphereRefLocal[0]
                                                ?.refFidelityLevel}
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                ) : (
                                  <>
                                    <div className="archi__section__atmosphere__images">
                                      <div className="archi__section__atmosphere__image1">
                                        <div className="archi__section__atmosphere__image1__base">
                                          <div
                                            className="card"
                                            onDragOver={(e) => {
                                              handleDragOver(e);
                                              setIsToolset(11);
                                            }}
                                            onDrop={(e) => {
                                              handleDrop(e);
                                              setIsToolset(11);
                                            }}
                                            onClick={() => setIsToolset(11)}
                                            key={inputKey}
                                          >
                                            <input
                                              key={inputKey}
                                              type="file"
                                              accept=".jpeg, .jpg, .png, .webp"
                                              id="fileInputRef"
                                              style={{ display: "none" }}
                                              onChange={(event) =>
                                                handleFileChangeWithToolsetInput(
                                                  event,
                                                  11
                                                )
                                              }
                                            />
                                            {atmosphereRefLocal !== undefined &&
                                              atmosphereRefLocal !== null && (
                                                <>
                                                  {!atmosphereRefLocal[0]
                                                    ?.feedDesignBase64 ? (
                                                    <label
                                                      htmlFor="fileInputRef"
                                                      key={inputKey}
                                                      style={{
                                                        cursor:
                                                          atmosphereRefLocal[0]
                                                            ?.uploadedBaseImageUrl &&
                                                          !atmosphereRefLocal[0]
                                                            ?.feedDesignBase64
                                                            ? "progress"
                                                            : "pointer",
                                                      }}
                                                    >
                                                      <div
                                                        className="card-content"
                                                        onDragOver={(e) => {
                                                          handleDragOver(e);
                                                          setIsToolset(11);
                                                        }}
                                                        onDrop={(e) => {
                                                          handleDrop(e);
                                                          setIsToolset(11);
                                                        }}
                                                      >
                                                        {atmosphereRefLocal[0]
                                                          ?.uploadedBaseImageUrl ? (
                                                          <>
                                                            <img
                                                              className="uploaded"
                                                              src={
                                                                atmosphereRefLocal[0]
                                                                  ?.uploadedBaseImageUrl
                                                              }
                                                              draggable="false"
                                                              alt=""
                                                            />
                                                            <div
                                                              className="ref-delete"
                                                              onClick={() =>
                                                                handleDeleteRef(
                                                                  0
                                                                )
                                                              }
                                                            >
                                                              <img
                                                                className={`${
                                                                  atmosphereRefLocal[0]
                                                                    ?.feedDesignBase64
                                                                    ? "active"
                                                                    : ""
                                                                }`}
                                                                src={deleteicon}
                                                                alt="refDelete"
                                                              />
                                                            </div>
                                                            {atmosphereRefLocal[0]
                                                              ?.feedDesignBase64 ===
                                                              null ||
                                                            atmosphereRefLocal[0]
                                                              ?.feedDesignBase64 ===
                                                              undefined ? (
                                                              <>
                                                                <div className="gradient-mask"></div>
                                                                <div className="text-display">
                                                                  {t(
                                                                    "uploading..."
                                                                  )}
                                                                </div>
                                                              </>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </>
                                                        ) : (
                                                          <>
                                                            <img
                                                              className="icon"
                                                              src={baseicon}
                                                              alt=""
                                                            />
                                                            <p>
                                                              {t(
                                                                "upload_ref_image"
                                                              )}
                                                            </p>
                                                          </>
                                                        )}
                                                      </div>
                                                    </label>
                                                  ) : (
                                                    <label
                                                      key={inputKey}
                                                      htmlFor="fileInputRef"
                                                      style={{
                                                        cursor:
                                                          atmosphereRefLocal[0]
                                                            ?.uploadedBaseImageUrl &&
                                                          !atmosphereRefLocal[0]
                                                            ?.feedDesignBase64
                                                            ? "progress"
                                                            : "pointer",
                                                      }}
                                                    >
                                                      <div
                                                        className="card-content"
                                                        onDragOver={(e) => {
                                                          handleDragOver(e);
                                                          setIsToolset(11);
                                                        }}
                                                        onDrop={(e) => {
                                                          handleDrop(e);
                                                          setIsToolset(11);
                                                        }}
                                                      >
                                                        {atmosphereRefLocal[0]
                                                          ?.uploadedBaseImageUrl ? (
                                                          <>
                                                            <img
                                                              className="uploaded"
                                                              src={
                                                                atmosphereRefLocal[0]
                                                                  ?.uploadedBaseImageUrl
                                                              }
                                                              draggable="false"
                                                              alt=""
                                                            />
                                                            <div
                                                              className="ref-delete"
                                                              onClick={() =>
                                                                handleDeleteRef(
                                                                  0
                                                                )
                                                              }
                                                            >
                                                              <img
                                                                className={`${
                                                                  atmosphereRefLocal[0]
                                                                    ?.feedDesignBase64
                                                                    ? "active"
                                                                    : ""
                                                                }`}
                                                                src={deleteicon}
                                                                alt="refDelete"
                                                              />
                                                            </div>
                                                            {atmosphereRefLocal[0]
                                                              ?.feedDesignBase64 ===
                                                              null ||
                                                            atmosphereRefLocal[0]
                                                              ?.feedDesignBase64 ===
                                                              undefined ? (
                                                              <>
                                                                <div className="gradient-mask"></div>
                                                                <div className="text-display">
                                                                  {t(
                                                                    "uploading..."
                                                                  )}
                                                                </div>
                                                              </>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </>
                                                        ) : (
                                                          <>
                                                            <img
                                                              className="icon"
                                                              src={baseicon}
                                                              alt=""
                                                            />
                                                            <p>
                                                              {t(
                                                                "upload_ref_image"
                                                              )}
                                                            </p>
                                                          </>
                                                        )}
                                                      </div>
                                                    </label>
                                                  )}
                                                </>
                                              )}
                                          </div>
                                        </div>
                                        {atmosphereRefLocal !== undefined &&
                                          atmosphereRefLocal !== null &&
                                          inspireToolsetRedux?.model === 1 && (
                                            <div className="archi__section__atmosphere__image1__weight">
                                              <Slider
                                                // key={activeFidelityLevel[0]}
                                                value={
                                                  activeFidelityLevel[0] ??
                                                  atmosphereRefLocal[0]
                                                    ?.refFidelityLevel
                                                }
                                                disabled={sliderDisable}
                                                step={1}
                                                min={1}
                                                max={9}
                                                onChange={(e) =>
                                                  handleImageSliderChange(e, 0)
                                                }
                                              />
                                              <span>
                                                {activeFidelityLevel[0] ??
                                                  atmosphereRefLocal[0]
                                                    ?.refFidelityLevel}
                                              </span>
                                            </div>
                                          )}
                                      </div>
                                      <div className="archi__section__atmosphere__image1">
                                        <div className="archi__section__atmosphere__image1__base">
                                          <div
                                            className="card"
                                            onDragOver={(e) => {
                                              handleDragOver(e);
                                              setIsToolset(12);
                                            }}
                                            onDrop={(e) => {
                                              handleDrop(e);
                                              setIsToolset(12);
                                            }}
                                            onClick={() => setIsToolset(12)}
                                            key={inputKey}
                                          >
                                            <input
                                              key={inputKey}
                                              type="file"
                                              accept=".jpeg, .jpg, .png, .webp"
                                              id="fileInputRef12"
                                              style={{ display: "none" }}
                                              onChange={(event) =>
                                                handleFileChangeWithToolsetInput(
                                                  event,
                                                  12
                                                )
                                              }
                                            />
                                            {atmosphereRefLocal !== undefined &&
                                              atmosphereRefLocal !== null && (
                                                <>
                                                  {!atmosphereRefLocal[1]
                                                    ?.feedDesignBase64 ? (
                                                    <label
                                                      htmlFor="fileInputRef12"
                                                      key={inputKey}
                                                      style={{
                                                        cursor:
                                                          atmosphereRefLocal[1]
                                                            ?.uploadedBaseImageUrl &&
                                                          !atmosphereRefLocal[1]
                                                            ?.feedDesignBase64
                                                            ? "progress"
                                                            : "pointer",
                                                      }}
                                                    >
                                                      <div
                                                        className="card-content"
                                                        onDragOver={(e) => {
                                                          handleDragOver(e);
                                                          setIsToolset(12);
                                                        }}
                                                        onDrop={(e) => {
                                                          handleDrop(e);
                                                          setIsToolset(12);
                                                        }}
                                                      >
                                                        {atmosphereRefLocal[1]
                                                          ?.uploadedBaseImageUrl ? (
                                                          <>
                                                            <img
                                                              className="uploaded"
                                                              src={
                                                                atmosphereRefLocal[1]
                                                                  ?.uploadedBaseImageUrl
                                                              }
                                                              draggable="false"
                                                              alt=""
                                                            />
                                                            <div
                                                              className="ref-delete"
                                                              onClick={() =>
                                                                handleDeleteRef(
                                                                  2
                                                                )
                                                              }
                                                            >
                                                              <img
                                                                className={`${
                                                                  atmosphereRefLocal[1]
                                                                    ?.feedDesignBase64
                                                                    ? "active"
                                                                    : ""
                                                                }`}
                                                                src={deleteicon}
                                                                alt="refDelete"
                                                              />
                                                            </div>
                                                            {atmosphereRefLocal[1]
                                                              ?.feedDesignBase64 ===
                                                              null ||
                                                            atmosphereRefLocal[1]
                                                              ?.feedDesignBase64 ===
                                                              undefined ? (
                                                              <>
                                                                <div className="gradient-mask"></div>
                                                                <div className="text-display">
                                                                  {t(
                                                                    "uploading..."
                                                                  )}
                                                                </div>
                                                              </>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </>
                                                        ) : (
                                                          <>
                                                            <img
                                                              className="icon"
                                                              src={baseicon}
                                                              alt=""
                                                            />
                                                            <p>
                                                              {t(
                                                                "upload_ref_image"
                                                              )}
                                                            </p>
                                                          </>
                                                        )}
                                                      </div>
                                                    </label>
                                                  ) : (
                                                    <label
                                                      key={inputKey}
                                                      htmlFor="fileInputRef12"
                                                      style={{
                                                        cursor:
                                                          atmosphereRefLocal[1]
                                                            ?.uploadedBaseImageUrl &&
                                                          !atmosphereRefLocal[1]
                                                            ?.feedDesignBase64
                                                            ? "progress"
                                                            : "pointer",
                                                      }}
                                                    >
                                                      <div
                                                        className="card-content"
                                                        onDragOver={(e) => {
                                                          handleDragOver(e);
                                                          setIsToolset(12);
                                                        }}
                                                        onDrop={(e) => {
                                                          handleDrop(e);
                                                          setIsToolset(12);
                                                        }}
                                                      >
                                                        {atmosphereRefLocal[1]
                                                          ?.uploadedBaseImageUrl ? (
                                                          <>
                                                            <img
                                                              className="uploaded"
                                                              src={
                                                                atmosphereRefLocal[1]
                                                                  ?.uploadedBaseImageUrl
                                                              }
                                                              draggable="false"
                                                              alt=""
                                                            />
                                                            <div
                                                              className="ref-delete"
                                                              onClick={() =>
                                                                handleDeleteRef(
                                                                  2
                                                                )
                                                              }
                                                            >
                                                              <img
                                                                className={`${
                                                                  atmosphereRefLocal[1]
                                                                    ?.feedDesignBase64
                                                                    ? "active"
                                                                    : ""
                                                                }`}
                                                                src={deleteicon}
                                                                alt="refDelete"
                                                              />
                                                            </div>
                                                            {atmosphereRefLocal[1]
                                                              ?.feedDesignBase64 ===
                                                              null ||
                                                            atmosphereRefLocal[1]
                                                              ?.feedDesignBase64 ===
                                                              undefined ? (
                                                              <>
                                                                <div className="gradient-mask"></div>
                                                                <div className="text-display">
                                                                  {t(
                                                                    "uploading..."
                                                                  )}
                                                                </div>
                                                              </>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </>
                                                        ) : (
                                                          <>
                                                            <img
                                                              className="icon"
                                                              src={baseicon}
                                                              alt=""
                                                            />
                                                            <p>
                                                              {t(
                                                                "upload_ref_image"
                                                              )}
                                                            </p>
                                                          </>
                                                        )}
                                                      </div>
                                                    </label>
                                                  )}
                                                </>
                                              )}
                                          </div>
                                        </div>
                                        {atmosphereRefLocal !== undefined &&
                                          atmosphereRefLocal !== null &&
                                          inspireToolsetRedux?.model === 1 && (
                                            <div className="archi__section__atmosphere__image1__weight">
                                              <Slider
                                                // key={activeFidelityLevel[0]}
                                                value={
                                                  activeFidelityLevel[1] ??
                                                  atmosphereRefLocal[1]
                                                    ?.refFidelityLevel
                                                }
                                                disabled={sliderDisable}
                                                step={1}
                                                min={1}
                                                max={9}
                                                onChange={(e) =>
                                                  handleImageSliderChange(e, 1)
                                                }
                                              />
                                              <span>
                                                {activeFidelityLevel[1] ??
                                                  atmosphereRefLocal[1]
                                                    ?.refFidelityLevel}
                                              </span>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </Popover>
                            </>
                          </Popover>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="archi__section__main__atmosphere1">
                        <Popover
                          overlayClassName="custom-popover-gallery"
                          content={t("enable_disable")}
                          arrow={true}
                          trigger="hover"
                          placement="right"
                          open={open4}
                          onOpenChange={handleOpen4}
                        >
                          <div
                            className={`archi__section__main__atmosphere1__heading ${
                              istemplateLocal?.isTemplate ? "" : "off"
                            }`}
                            onClick={() =>
                              handleTemplate(isActiveToolset !== 4 ? 2 : 0)
                            }
                          >
                            <div className="archi__section__main__atmosphere1__heading__left">
                              <h4>{t("atmosphere")}</h4>
                            </div>
                            <div className="archi__section__main__atmosphere1__heading__right">
                              <Switch
                                onChange={() =>
                                  handleTemplate(isActiveToolset !== 4 ? 2 : 0)
                                }
                                checked={
                                  istemplateLocal?.isTemplate !== undefined
                                    ? istemplateLocal?.isTemplate
                                    : false
                                }
                                height={responsiveData ? 14 : 16}
                                width={responsiveData ? 28 : 32}
                                handleDiameter={12}
                                offColor="#A8ADAF"
                                onColor="#000"
                                uncheckedIcon={false}
                                checkedIcon={false}
                                className={`${
                                  responsiveData
                                    ? istemplateLocal?.isTemplate !==
                                        undefined && istemplateLocal?.isTemplate
                                      ? "small-on "
                                      : "small-off "
                                    : ""
                                }react-switch-Undragable`}
                              />
                            </div>
                          </div>
                        </Popover>
                        {istemplateLocal?.isTemplate && (
                          <Popover
                            overlayClassName="custom-popover-gallery"
                            content={t("atmosphere_swift_tooltip")}
                            arrow={true}
                            trigger="hover"
                            placement="right"
                            open={open5}
                            onOpenChange={handleOpen5}
                          >
                            <div
                              className="archi__section__main__atmosphere1__sec"
                              onClick={handleClickOpenClose}
                            >
                              <div className="archi__section__main__atmosphere1__sec__sub">
                                <div className="archi__section__main__atmosphere1__sec__sub__section">
                                  <div className="archi__section__main__atmosphere1__sec__sub__section__atom">
                                    <span>
                                      {atmosphereData1?.isDesignType === 1
                                        ? t("atmosphere_exterior")
                                        : t("atmosphere_interior")}
                                    </span>
                                    <span>{seasonName}</span>
                                    <img
                                      src={seasonIcon || sunny}
                                      alt={
                                        atmosphere?.seasonNameSelected ||
                                        "sunny"
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Popover>
                        )}
                      </div>
                      <Popover
                        overlayClassName="custom-popover-gallery"
                        content={t("atmosphere_swift_style_ref_tooltip")}
                        arrow={true}
                        trigger="hover"
                        placement="right"
                        open={open6}
                        // onOpenChange={handleOpen6}
                      >
                        <div
                          className="archi__section__atmosphere"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {" "}
                          <Popover
                            overlayClassName="custom-popover-gallery"
                            content={t("enable_disable")}
                            arrow={true}
                            trigger="hover"
                            placement="right"
                            open={open11}
                            onOpenChange={handleOpen11}
                          >
                            <div style={{ width: "100%" }}>
                              <div
                                className={`archi__section__atmosphere__heading ${
                                  !istemplateLocal?.isAtmosphere ? "off" : ""
                                }`}
                                onClick={() =>
                                  handleTemplate(isActiveToolset !== 4 ? 1 : 0)
                                }
                              >
                                <div
                                  className="archi__section__atmosphere__heading__left"
                                  style={{ position: "relative" }}
                                >
                                  <h4>{t("atmosphere_shape_ref")}</h4>
                                  <div
                                    className="tooltip-top"
                                    onClick={(event) =>
                                      handleActiveLearn(23, event)
                                    }
                                  >
                                    {!responsiveData && (
                                      <img src={intro} alt="intro" />
                                    )}
                                    <Portal
                                      style={{
                                        display: `${
                                          activeLearnBool?.boolean === true &&
                                          activeLearnBool?.id === 23
                                            ? "block"
                                            : "none"
                                        }`,
                                      }}
                                    >
                                      {!responsiveData && (
                                        <TipsPopups1
                                          heading={t("atmosphere_shape_ref")}
                                          content={t(
                                            "atmophere_ref_tip_content"
                                          )}
                                          styles={
                                            lang === "ch" ? "80px" : "100px"
                                          }
                                          image={stylerefimages[lang]}
                                          position={activeLearnBool?.position}
                                        />
                                      )}
                                    </Portal>
                                  </div>
                                </div>
                                <div className="archi__section__atmosphere__heading__right">
                                  <Switch
                                    onChange={() =>
                                      handleTemplate(
                                        isActiveToolset !== 4 ? 1 : 0
                                      )
                                    }
                                    checked={
                                      istemplateLocal?.isAtmosphere !==
                                      undefined
                                        ? istemplateLocal?.isAtmosphere
                                        : false
                                    }
                                    height={responsiveData ? 14 : 16}
                                    width={responsiveData ? 28 : 32}
                                    handleDiameter={12}
                                    offColor="#A8ADAF"
                                    onColor="#000"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    className={`${
                                      responsiveData
                                        ? istemplateLocal?.isAtmosphere !==
                                            undefined &&
                                          istemplateLocal?.isAtmosphere
                                          ? "small-on "
                                          : "small-off "
                                        : ""
                                    }react-switch-Undragable`}
                                  />
                                </div>
                              </div>
                            </div>
                          </Popover>
                          {(istemplateLocal?.isAtmosphere ?? isAtmosphere) && (
                            <Popover
                              overlayClassName="custom-popover-gallery"
                              content={t("style_ref_uploaded")}
                              arrow={true}
                              trigger="hover"
                              placement="right"
                              open={open8}
                            >
                              <>
                                <Popover
                                  overlayClassName="custom-popover-gallery"
                                  content={t("inpaint_ref_popover")}
                                  arrow={true}
                                  trigger="hover"
                                  placement="right"
                                  open={open10}
                                >
                                  <div className="archi__section__atmosphere__image">
                                    <div className="archi__section__atmosphere__image__base">
                                      <div
                                        className="card"
                                        onDragOver={(e) => {
                                          handleDragOver(e);
                                          setIsToolset(14);
                                        }}
                                        onDrop={(e) => {
                                          handleDrop(e);
                                          setIsToolset(14);
                                        }}
                                        onClick={() => setIsToolset(14)}
                                        key={inputKey1}
                                      >
                                        <input
                                          key={inputKey1}
                                          type="file"
                                          accept=".jpeg, .jpg, .png, .webp"
                                          id="fileInputRef"
                                          style={{ display: "none" }}
                                          onChange={(event) =>
                                            handleFileChangeWithToolsetInput(
                                              event,
                                              14
                                            )
                                          }
                                        />
                                        {styleRefSwift !== undefined &&
                                          styleRefSwift !== null && (
                                            <>
                                              {!styleRefSwift?.feedDesignBase64 ? (
                                                <label
                                                  htmlFor="fileInputRef"
                                                  key={inputKey1}
                                                  style={{
                                                    cursor:
                                                      styleRefSwift?.uploadedBaseImageUrl &&
                                                      !styleRefSwift?.feedDesignBase64
                                                        ? "progress"
                                                        : "pointer",
                                                  }}
                                                >
                                                  <div
                                                    className="card-content"
                                                    onDragOver={(e) => {
                                                      handleDragOver(e);
                                                      setIsToolset(14);
                                                    }}
                                                    onDrop={(e) => {
                                                      handleDrop(e);
                                                      setIsToolset(14);
                                                    }}
                                                  >
                                                    {styleRefSwift?.uploadedBaseImageUrl ? (
                                                      <>
                                                        <img
                                                          className="uploaded"
                                                          src={
                                                            styleRefSwift?.uploadedBaseImageUrl
                                                          }
                                                          draggable="false"
                                                          alt=""
                                                        />
                                                        <div
                                                          className="ref-delete"
                                                          onClick={() =>
                                                            handleDeleteRef(1)
                                                          }
                                                        >
                                                          <img
                                                            className={`${
                                                              styleRefSwift?.feedDesignBase64
                                                                ? "active"
                                                                : ""
                                                            }`}
                                                            src={deleteicon}
                                                            alt="refDelete"
                                                          />
                                                        </div>
                                                        {styleRefSwift?.feedDesignBase64 ===
                                                          null ||
                                                        styleRefSwift?.feedDesignBase64 ===
                                                          undefined ? (
                                                          <>
                                                            <div className="gradient-mask"></div>
                                                            <div className="text-display">
                                                              {t(
                                                                "uploading..."
                                                              )}
                                                            </div>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    ) : (
                                                      <>
                                                        <img
                                                          className="icon"
                                                          src={baseicon}
                                                          alt=""
                                                        />
                                                        <p>
                                                          {t(
                                                            "upload_ref_image"
                                                          )}
                                                        </p>
                                                      </>
                                                    )}
                                                  </div>
                                                </label>
                                              ) : (
                                                <label
                                                  key={inputKey1}
                                                  htmlFor="fileInputRef"
                                                  style={{
                                                    cursor:
                                                      styleRefSwift?.uploadedBaseImageUrl &&
                                                      !styleRefSwift?.feedDesignBase64
                                                        ? "progress"
                                                        : "pointer",
                                                  }}
                                                >
                                                  <div
                                                    className="card-content"
                                                    onDragOver={(e) => {
                                                      handleDragOver(e);
                                                      setIsToolset(14);
                                                    }}
                                                    onDrop={(e) => {
                                                      handleDrop(e);
                                                      setIsToolset(14);
                                                    }}
                                                  >
                                                    {styleRefSwift?.uploadedBaseImageUrl ? (
                                                      <>
                                                        <img
                                                          className="uploaded"
                                                          src={
                                                            styleRefSwift?.uploadedBaseImageUrl
                                                          }
                                                          draggable="false"
                                                          alt=""
                                                        />
                                                        <div
                                                          className="ref-delete"
                                                          onClick={() =>
                                                            handleDeleteRef(1)
                                                          }
                                                        >
                                                          <img
                                                            className={`${
                                                              styleRefSwift?.feedDesignBase64
                                                                ? "active"
                                                                : ""
                                                            }`}
                                                            src={deleteicon}
                                                            alt="refDelete"
                                                          />
                                                        </div>
                                                        {styleRefSwift?.feedDesignBase64 ===
                                                          null ||
                                                        styleRefSwift?.feedDesignBase64 ===
                                                          undefined ? (
                                                          <>
                                                            <div className="gradient-mask"></div>
                                                            <div className="text-display">
                                                              {t(
                                                                "uploading..."
                                                              )}
                                                            </div>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    ) : (
                                                      <>
                                                        <img
                                                          className="icon"
                                                          src={baseicon}
                                                          alt=""
                                                        />
                                                        <p>
                                                          {t(
                                                            "upload_ref_image"
                                                          )}
                                                        </p>
                                                      </>
                                                    )}
                                                  </div>
                                                </label>
                                              )}
                                            </>
                                          )}
                                      </div>
                                    </div>
                                    {styleRefSwift !== undefined &&
                                      styleRefSwift !== null && (
                                        <div className="archi__section__atmosphere__image__weight">
                                          <Slider
                                            value={
                                              activeFidelityLevel1 ??
                                              styleRefSwift?.refFidelityLevel
                                            }
                                            step={4}
                                            min={1}
                                            max={9}
                                            tooltip={{ open: false }}
                                            // marks={{
                                            //   1: "Low",
                                            //   5: "Mid",
                                            //   9: "High",
                                            // }}
                                            onChange={(e) =>
                                              handleImageSliderChange1(e)
                                            }
                                          />
                                          <span>
                                            {(() => {
                                              const val =
                                                activeFidelityLevel1 ??
                                                styleRefSwift?.refFidelityLevel;
                                              if (val <= 3) return t("low");
                                              if (val <= 6) return t("mid");
                                              return t("high");
                                            })()}
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                </Popover>
                              </>
                            </Popover>
                          )}
                        </div>
                      </Popover>
                    </>
                  )}
                  <div className="archi__section__main3">
                    <Popover
                      overlayClassName="custom-popover-gallery"
                      content={
                        isAtmosphereSwift
                          ? t("private_disable_atmosphere")
                          : t("private-model-disable")
                      }
                      arrow={true}
                      trigger="hover"
                      placement="right"
                      open={
                        isActiveToolset === 4 || isAtmosphereSwift
                          ? open2
                          : false
                      }
                      onOpenChange={handleOpen2}
                    >
                      <div style={{ width: "100%" }}>
                        <Popover
                          overlayClassName="custom-popover-gallery"
                          content={t("enable_disable")}
                          arrow={true}
                          trigger="hover"
                          placement="right"
                          open={
                            isActiveToolset !== 4 && !isAtmosphereSwift
                              ? open7
                              : false
                          }
                          onOpenChange={handleOpen7}
                        >
                          <div
                            className={`archi__section__main3__heading ${
                              istemplateLocal?.isPrivate ? "" : "off"
                            } ${isAtmosphereSwift ? "disable" : ""}`}
                            onClick={
                              isAtmosphereSwift
                                ? null
                                : () =>
                                    handleTemplate(
                                      isActiveToolset !== 4 ? 4 : 0
                                    )
                            }
                          >
                            <div
                              className={`archi__section__main3__heading__left ${
                                isAtmosphereSwift ? "disable" : ""
                              }`}
                              style={{ position: "relative" }}
                            >
                              <h4>{t("private_model")}</h4>
                              <div
                                className="tooltip-top"
                                onClick={(event) =>
                                  handleActiveLearn(27, event)
                                }
                              >
                                {!responsiveData && (
                                  <img src={intro} alt="intro" />
                                )}
                                <Portal
                                  style={{
                                    display: `${
                                      activeLearnBool?.boolean === true &&
                                      activeLearnBool?.id === 27
                                        ? "block"
                                        : "none"
                                    }`,
                                  }}
                                >
                                  {!responsiveData && (
                                    <TipsPopups1
                                      heading={t("private_model")}
                                      content={t("private_model_tooltip")}
                                      styles={lang === "ch" ? "40px" : "80px"}
                                      image={privateCustomModel}
                                      position={activeLearnBool?.position}
                                    />
                                  )}
                                </Portal>
                              </div>
                            </div>
                            <div className="archi__section__main3__heading__right">
                              <Switch
                                onChange={
                                  isAtmosphereSwift
                                    ? () => {}
                                    : () =>
                                        handleTemplate(
                                          isActiveToolset !== 4 ? 4 : 0
                                        )
                                }
                                checked={
                                  istemplateLocal?.isPrivate !== undefined
                                    ? istemplateLocal?.isPrivate
                                    : false
                                }
                                height={responsiveData ? 14 : 16}
                                width={responsiveData ? 28 : 32}
                                handleDiameter={12}
                                offColor="#A8ADAF"
                                onColor="#000"
                                uncheckedIcon={false}
                                checkedIcon={false}
                                className={`${
                                  responsiveData
                                    ? istemplateLocal?.isPrivate !==
                                        undefined && istemplateLocal?.isPrivate
                                      ? "small-on "
                                      : "small-off "
                                    : ""
                                }react-switch-Undragable`}
                              />
                            </div>
                          </div>
                        </Popover>
                      </div>
                    </Popover>
                    {isPrivate && (
                      <div className="archi__section__main3__options">
                        <div className="archi__section__main3__options__container">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            value={
                              modelSelected ||
                              (lang === "ch"
                                ? promptLocalData?.privateModelCh
                                : promptLocalData?.privateModel)
                            }
                            isDisabled={false}
                            isLoading={isModelDataLoad}
                            onChange={handleChange}
                            isClearable={false}
                            isSearchable={true}
                            name="model"
                            options={privateModelData}
                            styles={customStyles}
                          />
                          {promptLocalData?.privateModel?.value !== 1 ? (
                            <input
                              className="input-value"
                              value={value}
                              type="number"
                              step={0.1}
                              min={0.1}
                              max={1}
                              onWheel={handleScroll}
                              onChange={handleChange1}
                            />
                          ) : (
                            <h5>0</h5>
                          )}
                        </div>
                        {privateModelError && <p>{t("private_model_error")}</p>}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="archi__section__main__templates">
                  <div className="archi__section__main__sub__main">
                    <Popover
                      overlayClassName="custom-popover-gallery"
                      content={t("template-disable")}
                      arrow={true}
                      trigger="hover"
                      placement="right"
                      open={isActiveToolset === 4 ? open : false}
                      onOpenChange={handleOpen}
                    >
                      <div
                        className={`archi__section__main__sub__heading ${
                          istemplateLocal?.isTemplate ? "off" : "off"
                        }`}
                        onClick={() => handleTemplate(0)}
                      >
                        <div className="archi__section__main__sub__heading__left">
                          <h4>{t("Template")}</h4>
                          <div
                            className="tooltip-top"
                            onClick={(event) => handleActiveLearn(21, event)}
                          >
                            {!responsiveData && <img src={intro} alt="intro" />}
                            <Portal
                              style={{
                                display: `${
                                  activeLearnBool?.boolean === true &&
                                  activeLearnBool?.id === 21
                                    ? "block"
                                    : "none"
                                }`,
                              }}
                            >
                              {!responsiveData && (
                                <TipsPopups1
                                  heading={t("Template")}
                                  content={t("template_content")}
                                  styles={lang === "ch" ? "340px" : "400px"}
                                  image={template}
                                  position={activeLearnBool?.position}
                                />
                              )}
                            </Portal>
                          </div>
                        </div>
                        <div className="archi__section__main__sub__heading__right">
                          <Switch
                            onChange={() => handleTemplate(0)}
                            checked={false}
                            height={responsiveData ? 14 : 16}
                            width={responsiveData ? 28 : 32}
                            handleDiameter={12}
                            offColor="#A8ADAF"
                            onColor="#000"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            className={`${
                              responsiveData
                                ? istemplateLocal?.isTemplate !== undefined &&
                                  istemplateLocal?.isTemplate
                                  ? "small-on "
                                  : "small-off "
                                : ""
                            }react-switch-Undragable`}
                          />
                        </div>
                      </div>
                    </Popover>
                  </div>
                  <div
                    className="archi__section__atmosphere"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div
                      className={`archi__section__atmosphere__heading ${
                        !istemplateLocal?.isAtmosphere ? "off" : "off"
                      }`}
                      onClick={() => handleTemplate(0)}
                    >
                      <div
                        className="archi__section__atmosphere__heading__left"
                        style={{ position: "relative" }}
                      >
                        <h4>{t("atmosphere_shape_ref")}</h4>
                        <div
                          className="tooltip-top"
                          onClick={(event) => handleActiveLearn(23, event)}
                        >
                          {!responsiveData && <img src={intro} alt="intro" />}
                          <Portal
                            style={{
                              display: `${
                                activeLearnBool?.boolean === true &&
                                activeLearnBool?.id === 23
                                  ? "block"
                                  : "none"
                              }`,
                            }}
                          >
                            {!responsiveData && (
                              <TipsPopups1
                                heading={t("atmosphere_shape_ref")}
                                content={t("Reference_content")}
                                styles={lang === "ch" ? "150px" : "160px"}
                                image={stylerefimage}
                                position={activeLearnBool?.position}
                              />
                            )}
                          </Portal>
                        </div>
                      </div>
                      <div className="archi__section__atmosphere__heading__right">
                        <Switch
                          onChange={() => handleTemplate(0)}
                          checked={false}
                          height={responsiveData ? 14 : 16}
                          width={responsiveData ? 28 : 32}
                          handleDiameter={12}
                          offColor="#A8ADAF"
                          onColor="#000"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          className={`${
                            responsiveData
                              ? istemplateLocal?.isAtmosphere !== undefined &&
                                istemplateLocal?.isAtmosphere
                                ? "small-on "
                                : "small-off "
                              : ""
                          }react-switch-Undragable`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="archi__section__main3">
                    <div
                      className={`archi__section__main3__heading ${
                        istemplateLocal?.isPrivate ? "off" : "off"
                      }`}
                      onClick={() => handleTemplate(0)}
                    >
                      <div
                        className="archi__section__main3__heading__left"
                        style={{ position: "relative" }}
                      >
                        <h4>{t("private_model")}</h4>
                        <div
                          className="tooltip-top"
                          onClick={(event) => handleActiveLearn(27, event)}
                        >
                          {!responsiveData && <img src={intro} alt="intro" />}
                          <Portal
                            style={{
                              display: `${
                                activeLearnBool?.boolean === true &&
                                activeLearnBool?.id === 27
                                  ? "block"
                                  : "none"
                              }`,
                            }}
                          >
                            {!responsiveData && (
                              <TipsPopups1
                                heading={t("private_model")}
                                content={t("private_model_tooltip")}
                                styles={lang === "ch" ? "40px" : "80px"}
                                image={privateCustomModel}
                                position={activeLearnBool?.position}
                              />
                            )}
                          </Portal>
                        </div>
                      </div>
                      <div className="archi__section__main3__heading__right">
                        <Switch
                          onChange={() => handleTemplate(0)}
                          checked={false}
                          height={responsiveData ? 14 : 16}
                          width={responsiveData ? 28 : 32}
                          handleDiameter={12}
                          offColor="#A8ADAF"
                          onColor="#000"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          className={`${
                            responsiveData
                              ? istemplateLocal?.isPrivate !== undefined &&
                                istemplateLocal?.isPrivate
                                ? "small-on "
                                : "small-off "
                              : ""
                          }react-switch-Undragable`}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="archi__section__main__templates__loading">
                    <img src={gif} alt="loading-gif" />
                  </div> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignLibraryComopnent;
