import React, { useContext, useEffect, useState } from "react";
import "./CreateProject.scss";
import { useTranslation } from "react-i18next";
import Button from "../../button";
import { CreateOrUpdateProject } from "../../../utils/apiCalls";
import { UserSocketContext } from "../../../store/UserSocketData";
import { useNavigate } from "react-router-dom";
import { processText } from "../../../utils/commonUtils";
import { UserContext } from "../../../store/UserDetailsStore";

const CreateProject = ({
  onClose,
  setIsLoader1,
  isLoader1,
  open,
  filterProjectsList,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateSocketData } = useContext(UserSocketContext);
  const { getUserAllPrivilages } = useContext(UserContext);
  const isActiveTeam = JSON.parse(localStorage.getItem("activeTeam"));
  const [projectName, setProjectName] = useState("");
  const [remaining, setRemaining] = useState(0);
  const [error, setError] = useState("");
  const maxLimit = 24;

  useEffect(() => {
    setProjectName("");
  }, [open]);

  const handleChange = (e) => {
    setError("");
    const sanitizedValue = e.replace(/ {2,}/g, " ");
    const { truncatedText, remainingChars } = processText(
      sanitizedValue,
      maxLimit,
      1
    );
    setProjectName(truncatedText);
    setRemaining(Math.max(0, remainingChars));
  };

  const handleConfirm = () => {
    setIsLoader1(true);
    const isDuplicate = filterProjectsList?.some(
      (pro) => pro?.name === projectName
    );
    if (isDuplicate) {
      setError(t("project_name_duplicated"));
      setIsLoader1(false);
      return;
    }

    const teamId = isActiveTeam?.teamId;
    const payload = {
      name: projectName,
      teamId: teamId,
    };
    if (payload) {
      CreateOrUpdateProject((res) => {
        getUserAllPrivilages(teamId);
        setProjectName("");
        setIsLoader1(false);
        const id = res?.data?.id;
        const last = {
          createdOn: res?.data?.createdOn,
          projectId: res?.data?.id,
          projectName: res?.data?.name,
          teamId: teamId,
          teamName: isActiveTeam?.teamName,
        };
        localStorage.setItem("lastProjectGenerate", JSON.stringify(last));
        localStorage.setItem(`newProject_${id}`, JSON.stringify(id));
        navigate(`/workspace/${id}`);
        if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      }, payload);
    }
  };

  const handleClose = () => {
    onClose();
    setProjectName("");
    setRemaining(0);
  };

  return (
    <div className="project-create">
      <div className="project-create__main">
        <div className="project-create__main__container">
          <div className="project-create__main__container__heading">
            <h2>{t("create_project")}</h2>
          </div>
          <div className="project-create__main__container__content">
            <h4>{t("project_name")}</h4>
            <div className="project-create__main__container__content__input">
              <input
                value={projectName}
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
                placeholder={t("enter_project_name")}
              />
              <h6 style={{ color: remaining === maxLimit && "red" }}>
                <span>{remaining}</span> / {maxLimit}
              </h6>
              {error !== "" && <p>{error}</p>}
            </div>
          </div>
          <div className="project-create__main__container__buttons">
            <div className="project-create__main__container__buttons__main">
              <Button
                buttonClick={handleClose}
                buttonClass={"button_link"}
                isBtnDisabled={false}
                buttonText={t("cancel")}
              />
              <Button
                buttonClick={handleConfirm}
                buttonClass="button"
                isBtnDisabled={isLoader1 ? true : false}
                buttonText={t("confirm")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProject;
