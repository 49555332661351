/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import "./FAQ.scss";
import "../Tutorials/Tutorials.scss";
import arrowup from "../../assets/images/icons/faq-arrow.png";
import arrowdown from "../../assets/images/icons/faq-arrow1.png";
import loader from "../../assets/images/workspace/upscale-gif.gif";
import tutorialimg1 from "../../assets/images/tutorial1.jpg";
import tutorialimg2 from "../../assets/images/tutorial2.jpg";
import tutorialimg3 from "../../assets/images/tutorial3.jpg";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import { GetAllFAQs, GetAllTutorialsList } from "../../utils/apiCalls";
import { UserSocketContext } from "../../store/UserSocketData";
import { TeamDesignContext } from "../../store/TeamDesignData";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

const FAQ = () => {
  const { t } = useTranslation();
  const { updateSocketData } = useContext(UserSocketContext);
  const { isTeamChanges } = useContext(TeamDesignContext);
  const tutorialsApiRef = useRef(false);
  const baseUrl = window.location.origin;
  const [tutorialsList, setTutorialsList] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const faqApiRef = useRef(false);

  const [openPanels, setOpenPanels] = useState({});
  const [isAllFAQs, setIsAllFAQs] = useState([]);
  const [loading, setLoading] = useState(false);

  const handlePanelToggle = (panelId) => {
    setOpenPanels((prevOpenPanels) => {
      const newState = {
        ...prevOpenPanels,
        [panelId]: !prevOpenPanels[panelId],
      };
      return newState;
    });
  };

  const ExpansionPanel = ({ panelId, header, children }) => (
    <div className="expand-panel">
      <div
        className="expand-panel__heading"
        onClick={() => handlePanelToggle(panelId)}
      >
        <h4>{header}</h4>
        {openPanels[panelId] ? (
          <img src={arrowdown} alt="arrow" />
        ) : (
          <img src={arrowup} alt="arrow" />
        )}
      </div>
      {openPanels[panelId] && (
        <div className="expand-panel__content">{children}</div>
      )}
    </div>
  );

  useEffect(() => {
    if (!faqApiRef.current) {
      faqApiRef.current = true;
      getAllFaqs();
    }
  }, []);

  useEffect(() => {
    getAllFaqs();
  }, [isTeamChanges]);

  const getAllFaqs = () => {
    setLoading(true);
    const lang = localStorage.getItem("language");
    const chinese = lang === "ch" ? "chs" : lang;
    GetAllFAQs((res) => {
      if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      }
      if (res.status === 200) {
        setIsAllFAQs(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }, chinese);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  }, []);

  useEffect(() => {
    if (!tutorialsApiRef.current) {
      tutorialsApiRef.current = true;
      getTutorialsList();
    }
    return () => {
      tutorialsApiRef.current = false;
    };
  }, []);

  const getTutorialsList = () => {
    setLoading(true);
    const lang = localStorage.getItem("language");
    const chinese = lang === "ch" ? "chs" : lang;
    GetAllTutorialsList((res) => {
      if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      }
      if (res?.status === 200) {
        setTutorialsList(res?.data[0]?.cards);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }, chinese);
  };

  const handleVideo = (video) => {
    window.open(`${baseUrl}/tutorial-data/${video?.id}`, "_blank");
  };

  return (
    <UserPortalLayout>
      <div className="faq-airi">
        <div className="faq-airi__container">
          <div className="faq-airi__sections" data-scrollable="true">
            {isAllFAQs?.length !== 0 ? (
              <Tabs>
                <TabList>
                  <Tab>{t("tutorial")}</Tab>
                  {isAllFAQs?.length !== 0 &&
                    isAllFAQs?.map((faq, index) => {
                      return <Tab key={index}>{faq?.heading}</Tab>;
                    })}
                </TabList>
                <TabPanel>
                  <div className="tutorial__main__section">
                    {tutorialsList?.length !== 0 ? (
                      tutorialsList?.map((tutorial, index) => {
                        const lastChild = tutorial?.id === 3;
                        return (
                          <div
                            className="tutorial__main__card"
                            key={index}
                            onClick={() => handleVideo(tutorial)}
                          >
                            <div className="tutorial__main__card__container">
                              <div className="tutorial__main__card__content__video">
                                {isLoading ? (
                                  <Skeleton height={200} />
                                ) : (
                                  <div className="tutorial__main__card__content__video__section">
                                    <img
                                      src={
                                        index === 2
                                          ? tutorialimg3
                                          : index === 1
                                          ? tutorialimg2
                                          : tutorialimg1
                                      }
                                      alt="tutorial-img"
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="tutorial__main__card__heading">
                                <div className="tutorial__main__card__heading__left">
                                  <div className="tutorial__main__card__heading__left__top">
                                    <h3>
                                      {isLoading ? (
                                        <Skeleton width={100} />
                                      ) : (
                                        tutorial?.title
                                      )}
                                    </h3>
                                    {lastChild && (
                                      <h6>
                                        {isLoading ? (
                                          <Skeleton width={100} />
                                        ) : (
                                          t("new")
                                        )}
                                      </h6>
                                    )}
                                  </div>
                                  <span style={{ background: tutorial?.color }}>
                                    {isLoading ? (
                                      <Skeleton width={150} />
                                    ) : (
                                      tutorial?.subTitle
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="tutorial__main__card__content">
                                <p>
                                  {isLoading ? (
                                    <Skeleton count={5} />
                                  ) : (
                                    tutorial?.description
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="gif">
                        {loading ? (
                          <img src={loader} alt="gif-loader" />
                        ) : (
                          <p>{t("no_data_found")}</p>
                        )}
                      </div>
                    )}
                  </div>
                </TabPanel>
                {isAllFAQs?.length !== 0 &&
                  isAllFAQs?.map((faq, outerIndex) => {
                    return (
                      <TabPanel key={outerIndex}>
                        {faq?.content?.map((point, innerIndex) => {
                          return (
                            <div
                              className="faq-airi__sections__expantion"
                              key={innerIndex}
                            >
                              <div className="faq-airi__sections__expantion__heading">
                                <h3>{point?.subheading}</h3>
                              </div>
                              {point?.subContent?.map((points, subIndex) => {
                                const formattedMessage = points?.answer
                                  ? points?.answer
                                      ?.trim()
                                      ?.replace(/\\n/g, "<br>")
                                  : "";
                                const panelId = `faq-panel-${outerIndex + 1}-${
                                  innerIndex + 1
                                }-${subIndex + 1}`;
                                return (
                                  <div
                                    className="faq-airi__sections__expantion__content"
                                    key={subIndex}
                                  >
                                    <ExpansionPanel
                                      panelId={panelId}
                                      header={points.question}
                                    >
                                      <h5
                                        dangerouslySetInnerHTML={{
                                          __html: formattedMessage,
                                        }}
                                      ></h5>
                                    </ExpansionPanel>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </TabPanel>
                    );
                  })}
              </Tabs>
            ) : (
              <div className="gif">
                {loading ? (
                  <img src={loader} alt="gif-loader" />
                ) : (
                  <p>{t("no_data_found")}</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </UserPortalLayout>
  );
};

export default FAQ;
