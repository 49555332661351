/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect, useRef } from "react";
import {
  GetAllSubscriptionAndAddons,
  GetCurrentUserDetails,
  GetUserPrivileges,
} from "../utils/apiCalls";
import { sortAddonsByDate } from "../utils/commonUtils";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [isLoginUserDetails, setIsLoginUserDetails] = useState(null);
  const token = localStorage.getItem("token");
  const lastProject = JSON.parse(localStorage.getItem("lastProjectGenerate"));
  const apiCalledRef = useRef(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isUserMembershipDetails, setIsUserMembershipDetails] = useState();
  const [isPrivilagesDetails, setIsprivilagesDetails] = useState("");
  const [wechatCode, setWechatCode] = useState(null);
  const [soloTeamData, setSoloTeamData] = useState(null);
  const [allTeamData, setAllTeamData] = useState(null);
  const [showNews, setShowNews] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsSmallScreen(screenWidth < 1090);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!apiCalledRef.current && token !== null && token !== undefined) {
      apiCalledRef.current = true;
      getLoginUserDetails();
    }
  }, [token]);

  const getLoginUserDetails = () => {
    GetCurrentUserDetails((res) => {
      if (res?.status === 200) {
        setIsLoginUserDetails(res?.data);
        localStorage.setItem("isLoginUser", JSON.stringify(res?.data));
        const solo = {
          createdOn: res?.data?.createdOn,
          defaultTeam: false,
          roleId: 0,
          roleName: "solo",
          teamIcon: res?.data?.avatarUrl,
          teamId: 0,
          teamMembersCount: 1,
          teamName: res?.data?.name,
          userId: res?.data?.id,
          username: res?.data?.name,
        };
        const all = {
          createdOn: res?.data?.createdOn,
          defaultTeam: false,
          roleId: 0,
          roleName: "all",
          teamIcon: res?.data?.avatarUrl,
          teamId: -1,
          teamMembersCount: 1,
          teamName: res?.data?.name,
          userId: res?.data?.id,
          username: res?.data?.name,
        };
        setAllTeamData(all);
        setSoloTeamData(solo);
      }
    });
  };

  const updateLoginUserDetails = (newData) => {
    setIsLoginUserDetails((prevData) => ({ ...prevData, ...newData }));
  };

  const clearLoginUserDetails = () => {
    setIsLoginUserDetails(null);
  };

  useEffect(() => {
    if (
      isLoginUserDetails?.teams[0] !== undefined &&
      token !== null &&
      token !== undefined
    ) {
      getAllSubscriptionAndAddons();
      getUserAllPrivilages(lastProject?.teamId);
    }
  }, [isLoginUserDetails]);

  const getUserAllPrivilages = (teamId = 0) => {
    GetUserPrivileges((res) => {
      if (res?.status === 200) {
        setIsprivilagesDetails(res?.data);
      }
    }, teamId);
  };

  const getAllSubscriptionAndAddons = (teamId = 0) => {
    setIsUserMembershipDetails();
    const lang = localStorage.getItem("language");
    GetAllSubscriptionAndAddons(
      (res) => {
        if (res?.status === 200) {
          const addons = sortAddonsByDate(res?.data?.addons);
          setIsUserMembershipDetails({...res?.data, addons});
        }
      },
      lang,
      teamId
    );
  };

  const handleNews = () => {
    setShowNews(!showNews);
  };

  return (
    <UserContext.Provider
      value={{
        isPrivilagesDetails,
        isLoginUserDetails,
        setIsLoginUserDetails,
        updateLoginUserDetails,
        clearLoginUserDetails,
        getUserAllPrivilages,
        getLoginUserDetails,
        isSmallScreen,
        getAllSubscriptionAndAddons,
        isUserMembershipDetails,
        setIsprivilagesDetails,
        setIsUserMembershipDetails,
        wechatCode,
        setWechatCode,
        allTeamData,
        soloTeamData,
        handleNews,
        showNews,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
