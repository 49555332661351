import { createSlice } from "@reduxjs/toolkit";
 
 export let previousData = {
   previousBase64Mask: null,
   previousCNImageSize: { width: 200, height: 200 },
   previousCNImagePos: { x: 0, y: 0 },
   previousCNImageUrl: null,
 };
 
 const editToolsetSlice = createSlice({
   name: "editToolset",
   initialState: {
     editToolsetData: {},
     previousData: { previousData },
   },
   reducers: {
     updateEditToolsetData: (state, action) => {
       const { projectId, data, port, isPlugin } = action.payload;
       if (!state[projectId]) {
         state[projectId] = { editToolsetData: {} };
       }
       if (!state[projectId][port]) {
         state[projectId][port] = { editToolsetData: {} };
       }
       if (isPlugin) {
         state[projectId][port].editToolsetData = {
           ...state[projectId][port].editToolsetData,
           ...JSON.parse(JSON.stringify(data)),
         };
       } else {
         state[projectId].editToolsetData = {
           ...state[projectId].editToolsetData,
           ...JSON.parse(JSON.stringify(data)),
         };
       }
     },
     clearEditToolsetData: (state, action) => {
       const { projectId, port, isPlugin } = action.payload;
       if (isPlugin) {
         if (state[projectId][port]) {
           state[projectId][port].editToolsetData = {};
         }
       } else {
         if (state[projectId]) {
           state[projectId].editToolsetData = {};
         }
       }
     },
     resetMask: (state, action) => {
       const { projectId, port, isPlugin, mode } = action.payload;
 
       if (isPlugin) {
         if (!state[projectId]?.[port]) return;
       } else {
         if (!state[projectId]) return;
       }
 
       const target = isPlugin
         ? state[projectId][port].editToolsetData
         : state[projectId].editToolsetData;
 
       switch (mode) {
         case 0: // Full reset
           Object.assign(target, {
             previousBase64Mask: null,
             previousCNImageSize: { width: 200, height: 200 },
             previousCNImagePos: { x: 0, y: 0 },
             previousCNImageUrl: null,
           });
           break;
         case 1: // Just reset position and size
           Object.assign(target, {
             previousCNImageSize: { width: 200, height: 200 },
             previousCNImagePos: { x: 0, y: 0 },
           });
           break;
         default:
           break;
       }
     },
   },
 });
 
 export const { updateEditToolsetData, clearEditToolsetData, resetMask } =
   editToolsetSlice.actions;
 export default editToolsetSlice.reducer;