import { createSlice } from "@reduxjs/toolkit";

const inspireToolsetSlice = createSlice({
  name: "inspireToolset",
  initialState: {
    inspireToolsetData: {
        orientation: 0,
        orientationName: "hori",
        imageRatio: 0,
        imageRatioName: "fourthree",
        imageCount: 1,
        model: 1,
    },
  },
  reducers: {
    updateInspireToolsetData: (state, action) => {
      const { projectId, data } = action.payload;
      if (!state[projectId]) {
        state[projectId] = {};
      }
      state[projectId].inspireToolsetData = {
        ...state[projectId].inspireToolsetData,
        ...JSON.parse(JSON.stringify(data)),
      };
    },
  },
});

export const { updateInspireToolsetData } = inspireToolsetSlice.actions;
export default inspireToolsetSlice.reducer;
