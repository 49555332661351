import React from "react";
import "./ServerChangePopup.scss";
import info from "../../assets/images/icons/alert-icon.png";
import close from "../../assets/images/icons/cross.png";
import { useTranslation } from "react-i18next";

const ServerChangePopup = ({ onClose }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  return (
    <div className="server-change">
      <div className="server-change__container">
        <div
          className="server-change__sections"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="server-change__sections__header">
            <img src={info} alt="info-icon" />
            <h3>{t("switch_server_china_heading")}</h3>
            <img className="close" src={close} alt="close-icon" onClick={handleClose} />
          </div>
          <div className="server-change__sections__content">
            <div className="server-change__sections__content__sec">
              <h4>1.</h4>
              <h4
                dangerouslySetInnerHTML={{
                  __html: t("switch_server_china_content1"),
                }}
              ></h4>
            </div>
            <div className="server-change__sections__content__sec">
              <h4>2.</h4>
              <h4
                dangerouslySetInnerHTML={{
                  __html: t("switch_server_china_content2"),
                }}
              ></h4>
            </div>
            <p>{t("switch_server_china_content3")}</p>
          </div>
          <div className="server-change__sections__buttons">
            <button onClick={handleClose}>{t("ok")}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerChangePopup;
