/* eslint-disable default-case */
import { useCallback, useRef } from "react";
import { BaseURL } from "./api-constant";
import {
  maskFillColor,
  maskFillFullOpacity,
  maskFillColorL,
  CNOpacity,
  maskFillColorVisualization
} from "../components/WorkspaceComponents/MaskComponent/ImageMaskPopUp";

let i = 0;
const imageCache = new Map(); // Use Map to maintain insertion order

export const DESIGN_2M_MAX_PIXELS = 2262016; //2048 * 2048
export const DESIGN_4M_MAX_PIXELS = 4194304; //2048 * 2048
export const REFERENCE_MAX_PIXELS = 2262016; //1504 * 1504
export const EDIT_MAX_PIXELS = 16777216; //4096 * 4096
export const EDIT_MIN_PIXELS = 2262016; //1504 * 1504
export const ENHANCE_MAX_PIXELS = 16777216; //4096 * 4096
export const VIDEOAI_MAX_PIXELS = 4194304; // 2048 * 2048
export const SCALE_LIMIT = 9999; //setting above 1 will never show the popup ever

export const INITTAB = 1;

function preloadImage(src) {
  return new Promise((resolve, reject) => {
    if (imageCache.has(src)) {
      // If already in cache, retrieve it
      const image = imageCache.get(src);
      // Move the recently accessed item to the end to maintain order
      imageCache.delete(src);
      imageCache.set(src, image);
      resolve(image);
    } else {
      const img = new Image();
      img.onload = () => {
        // Check if cache exceeds the size limit
        if (imageCache.size >= 50) {
          // Delete the first (oldest) item from the map
          const firstKey = imageCache.keys().next().value;
          imageCache.delete(firstKey);
        }
        // Store the newly loaded image
        imageCache.set(src, img);
        resolve(img);
      };
      img.onerror = () => {
        reject(new Error("Failed to load image"));
      };
      img.src = src;
    }
  });
}

export function useCreateDefaultMask() {
  const apiCalledRef = useRef(false);

  const createDefaultMask = useCallback(
    (resizedImageUrl, width, height, CNImageBlob, isCNSwitchOn = true, updateSocketData) => {
      return new Promise((resolve) => {
        let bool = false
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = width;
        canvas.height = height;

        const img = new Image();
        img.src = resizedImageUrl;

        img.onload = () => {
          //draw the base image
          ctx.globalCompositeOperation = "source-over";
          ctx.drawImage(img, 0, 0, width, height);

          const performRest = () => {
            //stack a mask with another level of transparency
            ctx.fillStyle = maskFillColor;
            ctx.fillRect(0, 0, width, height);

            const visualizedMaskBase64 = canvas.toDataURL("image/png");

            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = "#ffffff";
            ctx.fillRect(0, 0, width, height);
            const actualMaskBase64 = canvas.toDataURL("image/png");
            let actualMaskData;

            fetch(actualMaskBase64)
              .then((res) => res.blob())
              .then((blob) => {
                const resizedImageFile = new File(
                  [blob],
                  "resized_image.jpeg",
                  {
                    type: "image/jpeg",
                  }
                );
                apiCalledRef.current = false;
                if (!apiCalledRef.current) {
                  apiCalledRef.current = true;
                  uploadFile(
                    "base-image",
                    bool,
                    resizedImageFile,
                    async (data) => {
                      actualMaskData = data;
                    },
                    () => {
                      resolve({
                        visualizedMaskBase64,
                        actualMaskData,
                      });
                    },
                    updateSocketData
                  );
                }
              })
              .catch((error) => {
                console.error("Error converting Data URL to File:", error);
              });
          };

          if (CNImageBlob && isCNSwitchOn) {
            //if there is a cn image, then stack the cn image on top with some level of transparency
            const CNImage = new Image();
            CNImage.src = CNImageBlob;
            CNImage.onload = () => {
              ctx.globalAlpha = CNOpacity;
              ctx.drawImage(CNImage, 0, 0, width, height);
              ctx.globalAlpha = 1;
              performRest();
            };
          } else {
            performRest();
          }
        };
      });
    },
    []
  ); // Add any dependencies if necessary

  return createDefaultMask;
}

export function createMaskWithCNImage(
  baseImageUrl,
  width,
  height,
  cnImageUrl,
  cnWidth,
  cnHeight,
  isCNSwitchOn = true,
  editToolsetRedux
) {
  //here no need to update actualMaskData because it is already present from last time the mask was created, just need to update visual for UX
  return new Promise(async (resolve) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = width;
    canvas.height = height;

    const bg = await preloadImage(baseImageUrl);
    const cn = await preloadImage(cnImageUrl);

    let tempCanvas;
    let CNtoBGRatio;
    let tempCtx;

    const proceedPart1 = () => {
      //draw the base image
      ctx.globalCompositeOperation = "source-over";
      ctx.drawImage(bg, 0, 0, width, height);
      let widthScale = width / cnWidth;
      let heightScale = height / cnHeight;
      CNtoBGRatio = Math.min(widthScale, heightScale); //this is needed because unlike send to image, the image size is not always the same, + there is scaling factor here.
      if (isCNSwitchOn) {
        //if there is a cn image, then stack the cn image on top with some level of transparency
        ctx.globalAlpha = CNOpacity;
        ctx.drawImage(
          cn,
          width / 2 - (cnWidth / 2) * CNtoBGRatio,
          height / 2 - (cnHeight / 2) * CNtoBGRatio,
          cnWidth * CNtoBGRatio,
          cnHeight * CNtoBGRatio
        );
        ctx.globalAlpha = 1;
      }

      ctx.globalCompositeOperation = "destination-in";
    };

    const proceedPart2 = () => {
      // take the background, and stack the union on top
      ctx.globalCompositeOperation = "source-over";

      tempCanvas = document.createElement("canvas");
      tempCanvas.width = width;
      tempCanvas.height = height;
      tempCtx = tempCanvas.getContext("2d");

      tempCtx.drawImage(bg, 0, 0, width, height);
      tempCtx.drawImage(canvas, 0, 0, width, height);
    };

    const makeGrayScale = () =>{
      // Get the image data from the canvas
      const imageData = ctx.getImageData(
        width / 2 - (cnWidth / 2) * CNtoBGRatio,
        height / 2 - (cnHeight / 2) * CNtoBGRatio,
        cnWidth * CNtoBGRatio,
        cnHeight * CNtoBGRatio
      );
    
      const data = imageData.data;

      // Loop through each pixel and convert it to grayscale
      for (let i = 0; i < data.length; i += 4) {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
    
        // Calculate the grayscale value and set RGB channels
        const gray = 0.3 * r + 0.59 * g + 0.11 * b;
        data[i] = data[i + 1] = data[i + 2] = gray;
      }
    
      // Put the modified image data back on the canvas
      ctx.putImageData(
        imageData,
        width / 2 - (cnWidth / 2) * CNtoBGRatio,
        height / 2 - (cnHeight / 2) * CNtoBGRatio
      );
    }


    if (!editToolsetRedux?.previousBase64Mask) {
      proceedPart1();
      //then add the mask for just the area within the mask ( but here its infact the whole area)
      ctx.fillStyle = maskFillFullOpacity;
      ctx.fillRect(0, 0, width, height);

      proceedPart2();
      tempCtx.fillStyle = maskFillColor;
      tempCtx.fillRect(0, 0, width, height);

      const visualizedMaskBase64 = tempCanvas.toDataURL("image/png");

      //handle CN Image just image
      ctx.clearRect(0, 0, width, height);
      ctx.fillStyle = "rgba(255, 255, 255, 0)"; // maybe this should be full transparent black
      ctx.fillRect(0, 0, width, height);
      if (isCNSwitchOn) {
        ctx.drawImage(
          cn,
          width / 2 - (cnWidth / 2) * CNtoBGRatio,
          height / 2 - (cnHeight / 2) * CNtoBGRatio,
          cnWidth * CNtoBGRatio,
          cnHeight * CNtoBGRatio
        );
      }
      const CNImageFittedInsideBG = canvas.toDataURL("image/png");
      // makeGrayScale();
      const finalVisualizedCNImageUrl = canvas.toDataURL("image/png");

      resolve({
        visualizedMaskBase64,
        CNImageFittedInsideBG,
        finalVisualizedCNImageUrl
      });

    } else {
      proceedPart1();
      //load previous mask
      const mask = await preloadImage(editToolsetRedux?.previousBase64Mask);
      const tempCanvas2 = document.createElement("canvas");
      tempCanvas2.width = width;
      tempCanvas2.height = height;
      const tempCtx2 = tempCanvas2.getContext("2d", {
        willReadFrequently: true,
      });
      tempCtx2.drawImage(mask, 0, 0, width, height);

      const isNoMask = isCanvasBlank(
        tempCtx2,
        tempCanvas2.width,
        tempCanvas2.height
      );

      let initialMaskData = tempCtx2.getImageData(0, 0, width, height); // this is canvas data, or mask data (Lasso)

      // draw previous mask
      if (isNoMask) {
        ctx.fillStyle = maskFillFullOpacity;
        ctx.fillRect(0, 0, width, height);  
      } else {
        let imageData1 = new ImageData(
          new Uint8ClampedArray(initialMaskData.data),
          initialMaskData.width,
          initialMaskData.height
        ); // this is canvas data, or mask data (Lasso)
        let data = imageData1.data;

        for (let i = 3; i < data.length; i += 4) {
          // make transparent opaque
          if (data[i] === maskFillColorL[3]) {
            data[i] = 255; // Set alpha to 255 (fully opaque)
          }
        }
        // Put the modified image data onto the destination canvas
        tempCtx2.putImageData(imageData1, 0, 0);
        ctx.drawImage(tempCanvas2, 0, 0);
      }

      proceedPart2();

      if (isNoMask) {
        tempCtx.fillStyle = maskFillColor;
        tempCtx.fillRect(0, 0, width, height);
      } else {
        tempCtx.drawImage(mask, 0, 0, width, height);
      }

      const visualizedMaskBase64 = tempCanvas.toDataURL("image/png");

      //handle CN Image just image
      ctx.clearRect(0, 0, width, height);
      ctx.fillStyle = "rgba(255, 255, 255, 0)"; // maybe this should be full transparent black
      ctx.fillRect(0, 0, width, height);
      if (isCNSwitchOn) {
        ctx.drawImage(
          cn,
          width / 2 - (cnWidth / 2) * CNtoBGRatio,
          height / 2 - (cnHeight / 2) * CNtoBGRatio,
          cnWidth * CNtoBGRatio,
          cnHeight * CNtoBGRatio
        );
      }
      const CNImageFittedInsideBG = canvas.toDataURL("image/png");

      //handle CN Image with visualization

      ctx.clearRect(0, 0, width, height);
      ctx.fillStyle = "rgba(255, 255, 255, 0)"; // maybe this should be full transparent black
      ctx.fillRect(0, 0, width, height);

      if (isCNSwitchOn) {
        ctx.drawImage(
          cn,
          width / 2 - (cnWidth / 2) * CNtoBGRatio,
          height / 2 - (cnHeight / 2) * CNtoBGRatio,
          cnWidth * CNtoBGRatio,
          cnHeight * CNtoBGRatio
        );

        if (!isNoMask) {
          let imageData2 = new ImageData(
            new Uint8ClampedArray(initialMaskData.data),
            initialMaskData.width,
            initialMaskData.height
          );
          let data = imageData2.data;

          for (let i = 0; i < data.length; i += 4) {
            const alpha = data[i + 3];
            if (alpha === maskFillColorL[3]) { //
              //if red, make it full transparent
              data[i + 3] = 0;
            } else {
              //make it semi transparent white
              data[i] = 255;
              data[i + 1] =255;
              data[i + 2] = 255;
              data[i + 3] = maskFillColorVisualization[3];
            }
          }
          tempCtx2.clearRect(0,0,width,height);
          // Put the modified image data onto the destination canvas
          tempCtx2.putImageData(imageData2, 0, 0);
          ctx.drawImage(tempCanvas2, 0, 0);
        }
      }

      // makeGrayScale();

      //get the union component for CN image
      const finalVisualizedCNImageUrl = canvas.toDataURL("image/png");
      resolve({
        visualizedMaskBase64,
        CNImageFittedInsideBG,
        finalVisualizedCNImageUrl
      });
    }
  });
}

export function isCanvasBlank(context, width, height) {
  const pixelBuffer = new Uint32Array(
    context.getImageData(0, 0, width, height).data.buffer
  );
  return !pixelBuffer.some((color) => color !== 0);
}

export function cropTo8Multiple(size, multiple = 8) {
  if (size % multiple !== 0) {
    const cropAmount = size % multiple;
    size = size - cropAmount;
  }
  return size;
}

export function computePostProcessedDimension(
  inputWidth,
  inputHeight,
  toolset = 1
) {
  const currentPixels = inputWidth * inputHeight;
  switch (toolset) {
    case 1: {
      // design //update 2024/9/20 this should be set as 1504x1504 as max, min no longer matters.
      let scale = Math.sqrt(DESIGN_2M_MAX_PIXELS / currentPixels);
      console.log("2M!!!", scale);
      scale = scale >= 1 ? 1 : scale;
      const preCroppedWidth = inputWidth * scale;
      const preCroppedHeight = inputHeight * scale;

      const resizedWidth = cropTo8Multiple(preCroppedWidth);
      const resizedHeight = cropTo8Multiple(preCroppedHeight);
      return {
        preCroppedWidth,
        preCroppedHeight,
        resizedWidth,
        resizedHeight,
        scale,
      };
    }
    case 11:
    case 12:
    case 14: {
      // reference image
      let scale = Math.sqrt(REFERENCE_MAX_PIXELS / currentPixels);
      scale = scale >= 1 ? 1 : scale;
      const preCroppedWidth = inputWidth * scale;
      const preCroppedHeight = inputHeight * scale;

      const resizedWidth = cropTo8Multiple(preCroppedWidth);
      const resizedHeight = cropTo8Multiple(preCroppedHeight);
      return {
        preCroppedWidth,
        preCroppedHeight,
        resizedWidth,
        resizedHeight,
        scale,
      };
    }
    case 2:
    case 10: {
      // image base & controlnet inpainting
      let scale = 1;
      if (currentPixels / EDIT_MAX_PIXELS > 1) {
        scale = Math.sqrt(EDIT_MAX_PIXELS / currentPixels);
      }
      const preCroppedWidth = inputWidth * scale;
      const preCroppedHeight = inputHeight * scale;

      const resizedWidth = cropTo8Multiple(preCroppedWidth);
      const resizedHeight = cropTo8Multiple(preCroppedHeight);
      return {
        preCroppedWidth,
        preCroppedHeight,
        resizedWidth,
        resizedHeight,
        scale,
      };
    }
    case 4: {
      // video AI
      const currentPixels = inputWidth * inputHeight;
      let scale = Math.sqrt(VIDEOAI_MAX_PIXELS / currentPixels);
      scale = scale >= 1 ? 1 : scale;

      const preCroppedWidth = inputWidth * scale;
      const preCroppedHeight = inputHeight * scale;

      const resizedWidth = cropTo8Multiple(preCroppedWidth);
      const resizedHeight = cropTo8Multiple(preCroppedHeight);

      return {
        preCroppedWidth,
        preCroppedHeight,
        resizedWidth,
        resizedHeight,
        scale,
      };
    }
    case 13: { // magic prompt
      const TARGET_WIDTH = 512;
      const TARGET_HEIGHT = 512;
      const resizedWidth = cropTo8Multiple(TARGET_WIDTH);
      const resizedHeight = cropTo8Multiple(TARGET_HEIGHT);
      
      return {
        preCroppedWidth: TARGET_WIDTH,
        preCroppedHeight: TARGET_HEIGHT,
        resizedWidth,
        resizedHeight,
        scale: resizedWidth / inputWidth,
      };
    }
    case 3: //upscale
      let scale = Math.sqrt(ENHANCE_MAX_PIXELS / currentPixels);
      scale = scale >= 1 ? 1 : scale;
      const preCroppedWidth = inputWidth * scale;
      const preCroppedHeight = inputHeight * scale;

      const resizedWidth = cropTo8Multiple(preCroppedWidth);
      const resizedHeight = cropTo8Multiple(preCroppedHeight);
      return {
        preCroppedWidth,
        preCroppedHeight,
        resizedWidth,
        resizedHeight,
        scale,
      };
  }
}

export function computePostProcessedDimension1(
  inputWidth,
  inputHeight,
  toolset = 1
) {
  const currentPixels = inputWidth * inputHeight;
  switch (toolset) {
    case 1: {
      // design
      const TARGET_PIXELS = 2048 * 2048;
      let scale = Math.sqrt(TARGET_PIXELS / currentPixels);
      scale = Math.abs(scale - 1) <= 0.05 ? 1 : scale;
      const preCroppedWidth = inputWidth * scale;
      const preCroppedHeight = inputHeight * scale;

      const resizedWidth = cropTo8Multiple(preCroppedWidth);
      const resizedHeight = cropTo8Multiple(preCroppedHeight);
      return {
        preCroppedWidth,
        preCroppedHeight,
        resizedWidth,
        resizedHeight,
        scale,
      };
    }
  }
}

export async function handleConvertDateTime(created, mode = "workspace") {
  const createdDate = new Date(created);
  const day = String(createdDate.getDate()).padStart(2, "0");
  const month = String(createdDate.getMonth() + 1).padStart(2, "0");
  const year = createdDate.getFullYear();
  const hours = String(createdDate.getHours()).padStart(2, "0");
  const minutes = String(createdDate.getMinutes()).padStart(2, "0");
  const formattedDate =
    mode == "workspace"
      ? `${year}-${month}-${day}@${hours}h-${minutes}m` // workspace format
      : `${year}-${day}-${month}@${hours}h-${minutes}m`; // gallery format
  return formattedDate;
}

export function convertDateTime1(dateString) {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formattedTime = `${hours}:${minutes}`;
  const formattedDate = `${day}/${month}/${year}`;
  return { formattedTime, formattedDate };
}

export const formatDate = (isoString) => {
  const date = new Date(isoString);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Adjust hours and format to 24-hour time if needed
  const formattedHours = String(hours).padStart(2, "0");

  return `${day}.${month}.${year} - ${formattedHours}:${minutes}`;
};

export function convertDateTimeBefore(dateString) {
  const now = new Date();

  const timeDifference = now - dateString;
  const millisecondsInMinute = 60 * 1000;
  const millisecondsInHour = 60 * millisecondsInMinute;
  const millisecondsInDay = 24 * millisecondsInHour;
  const millisecondsInWeek = 7 * millisecondsInDay;

  let formattedDate;

  if (timeDifference < millisecondsInMinute) {
    const secondsAgo = Math.floor(timeDifference / 1000);
    formattedDate = `${secondsAgo}s ago`;
  } else if (timeDifference < millisecondsInHour) {
    const minutesAgo = Math.floor(timeDifference / millisecondsInMinute);
    formattedDate =
      minutesAgo === 1 ? `${minutesAgo}m ago` : `${minutesAgo}m ago`;
  } else if (timeDifference < millisecondsInDay) {
    const hoursAgo = Math.floor(timeDifference / millisecondsInHour);
    formattedDate = hoursAgo === 1 ? `${hoursAgo}h ago` : `${hoursAgo}h ago`;
  } else if (timeDifference < millisecondsInWeek) {
    const daysAgo = Math.floor(timeDifference / millisecondsInDay);
    formattedDate = daysAgo === 1 ? `${daysAgo}d ago` : `${daysAgo}d ago`;
  } else {
    const options = { day: "numeric", month: "short" };
    formattedDate = dateString.toLocaleString("en-US", options);
  }

  return formattedDate;
}

export function extractNameFromUrl(url) {
  const lastSegment = url.split("/").pop();
  const name = lastSegment.split(".")[0];

  return name;
}

export const uploadFile = (
  imageType = "base-image",
  bool = false,
  file,
  onSuccess,
  onCompletion,
  onError,
  updateSocketData
) => {
  const isActiveTeam = JSON.parse(localStorage.getItem("activeTeam"));
  const url = window.location.pathname;
  const lastPart = url.substring(url.lastIndexOf("/") + 1);
  const type =
    JSON.parse(localStorage.getItem(`imageType_${lastPart}`)) ?? "base-image";
  const teamId = isActiveTeam?.teamId !== 0 ? isActiveTeam?.teamId : 0;
  const formData = new FormData();
  formData.append("myFile", file, file.name);
  formData.append("imagePart", type);
  formData.append("teamId", teamId);
  const token = localStorage.getItem("token");
  const baseUrl = i % 2 === 0 ? BaseURL.BASE_URL : BaseURL.WORKFLOW_BASE_URL;
  const endpoint = !bool ?
    i % 2 === 0 ? "/Workflow/UploadMedia" : "/GenerateWorkflow/UploadMedia" : "/GenerateWorkflow/UploadMediaForVideo";
  i = i + 1;
  if (i === 10) {
    i = 0;
  }
  fetch(`${baseUrl}${endpoint}`, {
    method: "POST",
    body: formData,
    headers: {
      authorization: "Bearer " + [token],
    },
  })
    .then((response) => {
      if (!response.ok) {
        updateSocketData({
          isExitPopup: false,
          isSocketClose: true,
        });
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((res) => {
      if (res?.status === 200) {
        onSuccess(res?.data);
      } else if (res.status === 203) {
        onError(res);
      } else if (res?.status === 212) {
        onError(res);
      }
    })
    .catch((error) => {
      const url = window.location.pathname;
      const lastPart = url.substring(url.lastIndexOf("/") + 1);
      console.error("Error:", error);
      const serializedError = JSON.stringify({
        message: error.message,
        name: error.name,
        stack: error.stack,
      });

      const data = {
        val: true,
        error: serializedError,
      };
      localStorage.setItem(`upload_error_${lastPart}`, JSON.stringify(data));
    })
    .finally(() => {
      onCompletion();
    });
};

export const handleBase64Data = (base64Data) => {
  if (!base64Data) return;

  const byteCharacters = atob(base64Data);
  const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "image/jpeg" });

  return URL.createObjectURL(blob);
};

export const handleGlobalPopup = (handleClosePopupGlobal) => {
  handleClosePopupGlobal();
};

export const processImage = (
  img,
  computePostProcessedDimension,
  toolset,
  callbackInfo
) => {
  const { width: originalWidth, height: originalHeight } = img;
  const megapixel = 1;
  let {
    preCroppedWidth,
    preCroppedHeight,
    resizedWidth,
    resizedHeight,
    scale,
  } = computePostProcessedDimension(originalWidth, originalHeight, toolset, megapixel);
  // console.log('HHHHHHH', originalWidth,  originalHeight, scale, preCroppedWidth, preCroppedHeight, resizedWidth, resizedHeight);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.width = resizedWidth;
  canvas.height = resizedHeight;

  const cropWidthAtOriginalScale = (preCroppedWidth - resizedWidth) / scale;
  const cropHeightAtOriginalScale = (preCroppedHeight - resizedHeight) / scale;
  // console.log('AAAAAAAAAAAAAAAAAAA: ', cropWidthAtOriginalScale, cropHeightAtOriginalScale, originalWidth, originalHeight);
  ctx.drawImage(
    img,
    Math.floor(cropWidthAtOriginalScale / 2),
    Math.ceil(cropHeightAtOriginalScale / 2),
    originalWidth - cropWidthAtOriginalScale,
    originalHeight - cropHeightAtOriginalScale,
    0,
    0,
    resizedWidth,
    resizedHeight
  );
  // console.log('BBBBBBBB: ', preCroppedWidth - resizedWidth, preCroppedHeight - resizedHeight, resizedWidth, resizedHeight);
  if (callbackInfo) callbackInfo({ resizedWidth, resizedHeight,scale});
  // console.log('111111',canvas.toDataURL("image/jpeg"));
  return canvas.toDataURL("image/jpeg");
};

export const processImage1 = (
  img,
  computePostProcessedDimension,
  toolset,
  callbackInfo
) => {
  const { width: originalWidth, height: originalHeight } = img;
  let {
    preCroppedWidth,
    preCroppedHeight,
    resizedWidth,
    resizedHeight,
    scale,
  } = computePostProcessedDimension1(originalWidth, originalHeight, toolset);
  // console.log('HHHHHHH', originalWidth,  originalHeight, scale, preCroppedWidth, preCroppedHeight, resizedWidth, resizedHeight);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.width = resizedWidth;
  canvas.height = resizedHeight;

  const cropWidthAtOriginalScale = (preCroppedWidth - resizedWidth) / scale;
  const cropHeightAtOriginalScale = (preCroppedHeight - resizedHeight) / scale;
  // console.log('AAAAAAAAAAAAAAAAAAA: ', cropWidthAtOriginalScale, cropHeightAtOriginalScale, originalWidth, originalHeight);
  ctx.drawImage(
    img,
    Math.floor(cropWidthAtOriginalScale / 2),
    Math.ceil(cropHeightAtOriginalScale / 2),
    originalWidth - cropWidthAtOriginalScale,
    originalHeight - cropHeightAtOriginalScale,
    0,
    0,
    resizedWidth,
    resizedHeight
  );
  // console.log('BBBBBBBB: ', preCroppedWidth - resizedWidth, preCroppedHeight - resizedHeight, resizedWidth, resizedHeight);
  if (callbackInfo) callbackInfo({ resizedWidth, resizedHeight, scale });
  return canvas.toDataURL("image/jpeg");
};
