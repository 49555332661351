import React, { useContext, useEffect, useRef, useState } from "react";
import "./Home.scss";
import banner from "../../assets/images/home/banner.jpg";
import inspire from "../../assets/images/home/inspire.png";
import render from "../../assets/images/home/render.png";
import design from "../../assets/images/home/Design.png";
import reference from "../../assets/images/home/reference.png";
import atmosphere from "../../assets/images/home/atmosphere.png";
import stylise from "../../assets/images/home/stylise.png";
import upscale from "../../assets/images/home/Upscale.png";
import plugin from "../../assets/images/home/plugin.png";
import video from "../../assets/images/home/video.png";
import chat from "../../assets/images/home/assistant_chat.png";
import wechatTooltip from "../../assets/images/home/airilab_wechat_qrcode.jpg";
import innovative from "../../assets/images/home/airi_innovative.png";
import customize from "../../assets/images/home/airi_customize.png";
import collab from "../../assets/images/home/airi_collab.png";
import cloudcomputing from "../../assets/images/home/airi_cloud_computing.png";
import security from "../../assets/images/home/airi_security.png";
import backarrow from "../../assets/images/icons/back-arrow.png";
import Offline from "../../assets/images/home/airi_lab_off_line_courses.jpg";
import operational from "../../assets/images/home/airi_lab_operational_services.jpg";
import tutorial from "../../assets/images/home/airi_lab_tutorial.jpg";
import customercare from "../../assets/images/home/airi_lab_customer_service.jpg";
import collab1 from "../../assets/images/home/Users_collaborating_with_airi_1.jpg";
import collab2 from "../../assets/images/home/Users_collaborating_with_airi_2.jpg";
import collab3 from "../../assets/images/home/Users_collaborating_with_airi_3.jpg";
import collab4 from "../../assets/images/home/Users_collaborating_with_airi_4.jpg";
import collab5 from "../../assets/images/home/Users_collaborating_with_airi_5.jpg";
import collab6 from "../../assets/images/home/Users_collaborating_with_airi_6.jpg";
import collab7 from "../../assets/images/home/Users_collaborating_with_airi_7.jpg";
import collab8 from "../../assets/images/home/Users_collaborating_with_airi_8.jpg";
import collab9 from "../../assets/images/home/Users_collaborating_with_airi_9.jpg";
import { useTranslation } from "react-i18next";
import HeighlightedFeatures from "../../components/HomeComponents/HeighlightedFeatures/HeighlightedFeatures";
import AiriOffers from "../../components/HomeComponents/AiriOffers/AiriOffers";
import ProfessionalsAndRsponsibe from "../../components/HomeComponents/ProfessionalsAndRsponsibe/ProfessionalsAndRsponsibe";
import Brands from "../../components/HomeComponents/Brands/Brands";
import HomeFooter from "../../components/HomeComponents/HomeFooter/HomeFooter";
import SacnnerTooltip from "../../components/HomeComponents/ScannerTooltip/SacnnerTooltip";
import { ToolsetDataContext } from "../../store/WorkSpaceToolsetData";
import { useLocation, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import { ResponsiveDataContext } from "../../store/ResponsiveData";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";
import ServerChangePopup from "../../components/ServerChangePopup/ServerChangePopup";
import { AIRI_AWS_URL } from "../../utils/api-constant";

const Home = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const videoRef3 = useRef(null);
  const videoRef4 = useRef(null);
  const videoRef5 = useRef(null);
  const videoRef6 = useRef(null);
  const videoRef7 = useRef(null);
  const videoRef8 = useRef(null);
  const { toolsetData } = useContext(ToolsetDataContext);
  const { responsiveData } = useContext(ResponsiveDataContext);
  const token = localStorage.getItem("token");
  const [activeTooltip, setActiveTooltip] = useState({ id: 1, bool: false });
  const [isLoaded, setIsLoaded] = useState(false);
  const lastProject = JSON.parse(localStorage.getItem("lastProjectGenerate"));

  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    const port = urlParams.get("port");
    if ((pathname === "/rhino" || pathname === "/sketchup" || pathname === "/revit") && token) {
      const plugins = {
        plugin: pathname,
        port: port ? port : null
      };
      sessionStorage.setItem("plugin", JSON.stringify(plugins));
      handleWorkspace();
    } else if ((pathname === "/rhino" || pathname === "/sketchup" || pathname === "/revit") && !token) {
      const plugins = {
        plugin: pathname,
        port: port ? port : null
      };
      sessionStorage.setItem("plugin", JSON.stringify(plugins));
    }
    const lang = urlParams.get("lang");
    if (!token && lang && lang !== undefined) {
      setShowPopup(true);
      localStorage.setItem("language", lang);
      i18n.changeLanguage(lang);
    }
  }, [pathname]);

  const handleSignIn = () => {
    const data = {
      heading: "| Register / Login",
      icon: backarrow,
      hide: "",
    };
    if (token) {
      handleWorkspace();
    } else {
      navigate("/sign-in", { state: data });
    }
  };

  const handleWorkspace = () => {
    if (
      lastProject === "undefined" ||
      lastProject === null ||
      lastProject === "null"
    ) {
      let projectId = toolsetData?.isSetLatestProjectCreated;
      navigate(`/workspace/${projectId}`);
    } else {
      navigate(`/workspace/${lastProject?.projectId}`);
    }
  };

  const featuresData = [
    {
      id: 1,
      icon: inspire,
      heading: t("feature1_heading"),
      sub_heading: t("feature1_heading_sub"),
      content: t("feature1_content"),
      video: `${AIRI_AWS_URL}/website/01+INSPIRE.mp4`,
      ref: videoRef1,
    },
    {
      id: 2,
      icon: render,
      heading: t("feature2_heading"),
      sub_heading: t("feature2_heading_sub"),
      content: t("feature2_content"),
      video: `${AIRI_AWS_URL}/website/06+REFERENCE.mp4`,
      ref: videoRef2,
    },
    {
      id: 3,
      icon: design,
      heading: t("feature3_heading"),
      sub_heading: t("feature3_heading_sub"),
      content: t("feature3_content"),
      video: `${AIRI_AWS_URL}/website/03+EDIT.mp4`,
      ref: videoRef3,
    },
    {
      id: 4,
      icon: upscale,
      heading: t("feature4_heading"),
      sub_heading: t("feature4_heading_sub"),
      content: t("feature4_content"),
      video: `${AIRI_AWS_URL}/website/07+4K+HIGH-DEFINITION+generation.mp4`,
      ref: videoRef4,
    },
    {
      id: 5,
      icon: stylise,
      heading: t("feature5_heading"),
      sub_heading: t("feature5_heading_sub"),
      content: t("feature5_content"),
      video: `${AIRI_AWS_URL}/website/02+RENDER.mp4`,
      ref: videoRef5,
    },
    {
      id: 6,
      icon: atmosphere,
      heading: t("feature6_heading"),
      sub_heading: t("feature6_heading_sub"),
      content: t("feature6_content"),
      video: `${AIRI_AWS_URL}/website/04+ATMOSPHERE.mp4`,
      ref: videoRef6,
    },
    {
      id: 7,
      icon: reference,
      heading: t("feature7_heading"),
      sub_heading: t("feature7_heading_sub"),
      content: t("feature7_content"),
      video: `${AIRI_AWS_URL}/website/09+GRAPHIC+STYLE.mp4`,
      ref: videoRef7,
    },
    {
      id: 9,
      icon: video,
      heading: t("feature9_heading"),
      sub_heading: t("feature9_heading_sub"),
      content: t("feature9_content"),
      video: `${AIRI_AWS_URL}/website/10+Video.mp4`,
      ref: videoRef5,
    },
    {
      id: 10,
      icon: plugin,
      heading: t("feature10_heading"),
      sub_heading: t("feature10_heading_sub"),
      content: t("feature10_content"),
      video: `${AIRI_AWS_URL}/website/11+rhino.mp4`,
      ref: videoRef5,
    },
    {
      id: 8,
      icon: chat,
      heading: t("feature8_heading"),
      sub_heading: t("feature8_heading_sub"),
      content: t("feature8_content"),
      video: `${AIRI_AWS_URL}/website/08+ASSISTANT.mp4`,
      ref: videoRef8,
    },
  ];

  const offersData = [
    {
      id: 1,
      image: innovative,
      heading: t("offers1_heading"),
      content: t("offers1_content"),
    },
    {
      id: 2,
      image: customize,
      heading: t("offers2_heading"),
      content: t("offers2_content"),
    },
    {
      id: 3,
      image: collab,
      heading: t("offers3_heading"),
      content: t("offers3_content"),
    },
    {
      id: 4,
      image: cloudcomputing,
      heading: t("offers4_heading"),
      content: t("offers4_content"),
    },
    {
      id: 5,
      image: security,
      heading: t("offers5_heading"),
      content: t("offers5_content"),
    },
  ];

  const professionalsData = [
    {
      id: 1,
      image: tutorial,
      heading: t("profession1_heading"),
      content: t("profession1_content"),
    },
    {
      id: 2,
      image: Offline,
      heading: t("profession2_heading"),
      content: t("profession2_content"),
    },
    {
      id: 3,
      image: operational,
      heading: t("profession3_heading"),
      content: t("profession3_content"),
    },
    {
      id: 4,
      image: customercare,
      heading: t("profession4_heading"),
      content: t("profession4_content"),
    },
  ];

  const brandsData = [
    {
      id: 1,
      image: collab1,
      content: t("brand1_content"),
      name: t("brand1_name"),
      company: t("brand1_company"),
      position: t("brand1_position"),
    },
    {
      id: 2,
      image: collab2,
      content: t("brand2_content"),
      name: t("brand2_name"),
      company: t("brand2_company"),
      position: t("brand2_position"),
    },
    {
      id: 3,
      image: collab3,
      content: t("brand3_content"),
      name: t("brand3_name"),
      company: t("brand3_company"),
      position: t("brand3_position"),
    },
    {
      id: 4,
      image: collab4,
      content: t("brand4_content"),
      name: t("brand4_name"),
      company: t("brand4_company"),
      position: t("brand4_position"),
    },
    {
      id: 5,
      image: collab5,
      content: t("brand5_content"),
      name: t("brand5_name"),
      company: t("brand5_company"),
      position: t("brand5_position"),
    },
    {
      id: 6,
      image: collab6,
      content: t("brand6_content"),
      name: t("brand6_name"),
      company: t("brand6_company"),
      position: t("brand6_position"),
    },
    {
      id: 7,
      image: collab7,
      content: t("brand7_content"),
      name: t("brand7_name"),
      company: t("brand7_company"),
      position: t("brand7_position"),
    },
    {
      id: 9,
      image: collab9,
      content: t("brand9_content"),
      name: t("brand9_name"),
      company: t("brand9_company"),
      position: t("brand9_position"),
    },
    {
      id: 8,
      image: collab8,
      content: t("brand8_content"),
      name: t("brand8_name"),
      company: t("brand8_company"),
      position: t("brand8_position"),
    },
  ];

  const handleTooltip = (id) => {
    if (activeTooltip?.id === id) {
      setActiveTooltip({ id: id, bool: !activeTooltip?.bool });
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = 0.33;
    }
  }, []);

  const handleClose = () => {
    setShowPopup(false);
  };

  return (
    <UserPortalLayout>
      <div className="home">
        <div className="home__container">
          <div className="home__sections" data-draggable="true">
            <div className="home__banner">
              <div className="home__banner__section">
                {!isLoaded && (
                  <div className="home__banner__section__banner-img">
                    <img
                      className="banner-img"
                      src={banner}
                      alt="Video Thumbnail"
                    />
                  </div>
                )}
                <video
                  ref={videoRef}
                  style={{ display: isLoaded ? "block" : "none" }}
                  // controls
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="instruct__element"
                  onLoadedData={() => setIsLoaded(true)}
                >
                  <source
                    src={`${AIRI_AWS_URL}/website/20240606++video.mp4`}
                    type="video/mp4"
                  />
                </video>
                {isLoaded && (
                  <div className="home__banner__section__content">
                    <div className="home__banner__section__content__text">
                      <h1>{t("home_banner_text1")}</h1>
                      <p>{t("home_banner_text2")}</p>
                    </div>
                    <div className="home__banner__section__content__button">
                      <button onClick={handleSignIn}>
                        {token === "undefined" ||
                        token === null ||
                        token === "null"
                          ? t("home_button")
                          : t("home_button_back_project")}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="home__features">
              <div className="home__features__container">
                <div className="home__features__heading">
                  <h2>{t("home_features_heading")}</h2>
                </div>
                <div className="home__features__cards">
                  {featuresData?.map((feat, index) => {
                    return <HeighlightedFeatures data={feat} key={index} />;
                  })}
                </div>
              </div>
            </div>
            <div className="home__offers">
              <div className="home__offers__container">
                <div className="home__offers__heading">
                  <h2>{t("home_offers_heading")}</h2>
                </div>
                <div className="home__offers__cards">
                  {offersData?.map((offer, index) => {
                    return <AiriOffers data={offer} key={index} />;
                  })}
                </div>
              </div>
            </div>
            <div className="home__professionals">
              <div className="home__professionals__container">
                <div className="home__professionals__heading">
                  <h2>{t("home_profession_heading")}</h2>
                </div>
                <div className="home__professionals__cards">
                  {professionalsData?.map((profession, index) => {
                    return (
                      <ProfessionalsAndRsponsibe
                        data={profession}
                        key={index}
                      />
                    );
                  })}
                </div>
                <div className="home__professionals__button">
                  <div
                    className="home__professionals__button__section"
                    key={activeTooltip}
                  >
                    <button onClick={() => handleTooltip(1)}>
                      {t("home_contact_us")}
                    </button>
                    <div
                      className={`sec-tooltip ${
                        activeTooltip?.id === 1 && activeTooltip?.bool
                          ? "active"
                          : ""
                      }`}
                    >
                      <SacnnerTooltip
                        tooltipContent={t("wechat_tooltip")}
                        image={wechatTooltip}
                        left="1.75vw"
                        bottom="2.6vw"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="home__brands">
              <div className="home__brands__container">
                <div className="home__brands__heading">
                  <h2>{t("home_brands_heading")}</h2>
                </div>
                <div className="home__brands__cards">
                  <Swiper
                    slidesPerView={responsiveData ? 2 : 3}
                    spaceBetween={responsiveData ? 10 : 30}
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: true,
                    }}
                    loop
                    modules={[Autoplay]}
                    className="mySwiper"
                    allowTouchMove={false}
                  >
                    {brandsData?.map((profession, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <Brands data={profession} />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            </div>
            <div className="home__footer">
              <div className="home__footer__container">
                <HomeFooter />
              </div>
            </div>
          </div>
        </div>
        {showPopup && <ServerChangePopup onClose={handleClose} />}
      </div>
    </UserPortalLayout>
  );
};

export default Home;
