import { createSlice } from "@reduxjs/toolkit";
 
 const upscaleToolsetSlice = createSlice({
   name: "upscaleToolset",
   initialState: {
     upscaleToolsetData: {},
   },
   reducers: {
     updateUpscaleToolsetData: (state, action) => {
       const { projectId, data, port, isPlugin } = action.payload;
       if (!state[projectId]) {
         state[projectId] = { upscaleToolsetData: {} };
       }
       if (!state[projectId][port]) {
         state[projectId][port] = { upscaleToolsetData: {} };
       }
       if (isPlugin) {
         state[projectId][port].upscaleToolsetData = {
           ...state[projectId][port].upscaleToolsetData,
           ...JSON.parse(JSON.stringify(data)),
         };
       } else {
         state[projectId].upscaleToolsetData = {
           ...state[projectId].upscaleToolsetData,
           ...JSON.parse(JSON.stringify(data)),
         };
       }
     },
     clearUpscaleToolsetData: (state, action) => {
       const { projectId, port, isPlugin } = action.payload;
       if (isPlugin) {
         if (state[projectId][port]) {
           state[projectId][port].upscaleToolsetData = {};
         }
       } else {
         if (state[projectId]) {
           state[projectId].upscaleToolsetData = {};
         }
       }
     },
   },
 });
 
 export const { updateUpscaleToolsetData, clearUpscaleToolsetData } =
   upscaleToolsetSlice.actions;
 export default upscaleToolsetSlice.reducer;