import React, { useContext, useRef, useState } from "react";
import "./FirstFrameCrop.scss";
import { useTranslation } from "react-i18next";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import cropload from "../../../assets/images/crop-load.png";
import anim from "../../../assets/images/workspace/dots.gif";
import { ResponsiveDataContext } from "../../../store/ResponsiveData";
import { updateVideoToolsetData } from "../../../store/slices/videoToolsetSlice";
import { useDispatch } from "react-redux";

const FirstFrameCrop = ({
  imageUploadEvent = null,
  setImageUploadEvent,
  setShowModal,
  projectId,
  setVideoCameraId,
}) => {
  const { t } = useTranslation();
  const { screenWidth } = useContext(ResponsiveDataContext);

  const url = window.location.pathname;
  const lastPart = url.substring(url.lastIndexOf("/") + 1);

  const plugins = JSON.parse(sessionStorage.getItem("plugin"));
  const isPlugin =
    plugins?.plugin === "/rhino" ||
    plugins?.plugin === "/sketchup" ||
    plugins?.plugin === "/revit";

  const dispatch = useDispatch();

  const [image, setImage] = useState(imageUploadEvent);
  const [aspectRatio, setAspectRatio] = useState(5 / 3);
  const cropperRef = useRef(null);
  const [croppedPreview, setCroppedPreview] = useState(null);
  const [isLoader, setIsLoader] = useState(true);

  const updateCroppedPreview = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas({
        width: aspectRatio === 5 / 3 ? 1280 : 768,
        height: aspectRatio !== 5 / 3 ? 1280 : 768,
      });
      setCroppedPreview(croppedCanvas.toDataURL());
    }
  };

  const changeAspectRatio = (newAspectRatio) => {
    setAspectRatio(newAspectRatio);
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      cropper.setAspectRatio(newAspectRatio);

      const canvasData = cropper.getCanvasData();
      const width =
        newAspectRatio > 1
          ? canvasData.width
          : canvasData.height * newAspectRatio;
      const height =
        newAspectRatio > 1
          ? canvasData.width / newAspectRatio
          : canvasData.height;

      cropper.setCropBoxData({
        left: (canvasData.width - width) / 2,
        top: (canvasData.height - height) / 2,
        width: width,
        height: height,
      });
    }
  };

  const cropImage = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas({
        width: aspectRatio === 5 / 3 ? 1280 : 768,
        height: aspectRatio !== 5 / 3 ? 1280 : 768,
      });
      if (croppedCanvas) {
        localStorage.setItem(
          `videoCamera_${projectId}`,
          JSON.stringify({ id: 1, bool: true })
        );
        setVideoCameraId({ id: 1, bool: true });
        const croppedImageUrl = croppedCanvas.toDataURL();
        const videoData = {
          orientation: aspectRatio === 5 / 3 ? 1 : 2,
          isCropped: true,
          isCroppedImage: croppedImageUrl,
          id: 1,
          bool: true,
        };
        dispatch(
          updateVideoToolsetData({
            projectId: lastPart,
            data: videoData,
            port: plugins?.port,
            isPlugin: isPlugin,
          })
        );
        setImageUploadEvent(null);
        setShowModal(false);
      }
    }
  };

  const handleClose = () => {
    setImageUploadEvent(null);
    setShowModal(false);
  };

  const handleCropperInit = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const canvasData = cropper.getCanvasData();
      const width =
        aspectRatio > 1 ? canvasData.width : canvasData.height * aspectRatio;
      const height =
        aspectRatio > 1 ? canvasData.width / aspectRatio : canvasData.height;
      cropper.setCropBoxData({
        left: (canvasData.width - width) / 2,
        top: (canvasData.height - height) / 2,
        width: width,
        height: height,
      });
      setIsLoader(false);
    }
  };

  const cropHeight =
    screenWidth < 800
      ? 100
      : screenWidth < 1090
      ? 280
      : screenWidth < 1250
      ? 340
      : screenWidth < 1600
      ? 486
      : 576;

  return (
    <div className="first-frame">
      <div
        className="first-frame__container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="first-frame__sections">
          <div className="first-frame__crop">
            {image && (
              <div className="first-frame__crop__container">
                {isLoader && (
                  <div className="first-frame__crop__container__load">
                    <img src={cropload} alt="crop-load" />
                    <img className="anim" src={anim} alt="anim-gif" />
                  </div>
                )}
                <div
                  className="first-frame__crop__section"
                  onDoubleClick={cropImage}
                >
                  <Cropper
                    src={image}
                    style={{ height: cropHeight, width: "100%" }}
                    aspectRatio={aspectRatio}
                    guides={true}
                    cropBoxResizable={false}
                    cropBoxMovable={true}
                    dragMode="crop"
                    viewMode={1}
                    zoomable={false}
                    ready={handleCropperInit}
                    ref={cropperRef}
                    crop={updateCroppedPreview}
                  />
                </div>
              </div>
            )}
            {image && (
              <div className="first-frame__crop__buttons">
                <div className="first-frame__crop__buttons__top">
                  <div className="first-frame__crop__buttons__top__main">
                    <button
                      className={`${aspectRatio === 5 / 3 ? "active" : ""}`}
                      onClick={() => changeAspectRatio(5 / 3)}
                    >
                      <span></span>
                      {t("horizontal_crop")} (1280×768)
                    </button>
                    <button
                      className={`${aspectRatio === 3 / 5 ? "active" : ""}`}
                      onClick={() => changeAspectRatio(3 / 5)}
                    >
                      <span className="span1"></span>
                      {t("vertical_crop")} (768×1280)
                    </button>
                  </div>
                </div>
                <div className="first-frame__crop__buttons__bottom">
                  <button className="cancel" onClick={handleClose}>
                    {t("cancel")}
                  </button>
                  <button disabled={isLoader} onClick={cropImage}>
                    {t("crop")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstFrameCrop;
