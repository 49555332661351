import { createSlice } from "@reduxjs/toolkit";

const toolsetSlice = createSlice({
  name: "toolset",
  initialState: {
    toolsetData: {},
  },
  reducers: {
    updateToolsetData: (state, action) => {
      const { projectId, data } = action.payload;
      if (!state[projectId]) {
        state[projectId] = {};
      }
      state[projectId].toolsetData = {
        ...state[projectId].toolsetData,
        ...JSON.parse(JSON.stringify(data)),
      };
    },
  },
});

export const { updateToolsetData } = toolsetSlice.actions;
export default toolsetSlice.reducer;
