import { createSlice } from "@reduxjs/toolkit";
 
 const editToolsetSlice = createSlice({
   name: "videoToolset",
   initialState: {
     videoToolsetData: {
       id: 1,
       bool: true,
     },
   },
   reducers: {
     updateVideoToolsetData: (state, action) => {
       const { projectId, data, port, isPlugin } = action.payload;
       if (!state[projectId]) {
         state[projectId] = { videoToolsetData: {} };
       }
       if (!state[projectId][port]) {
         state[projectId][port] = { videoToolsetData: {} };
       }
       if (isPlugin) {
         state[projectId][port].videoToolsetData = {
           ...state[projectId][port].videoToolsetData,
           ...JSON.parse(JSON.stringify(data)),
         };
       } else {
         state[projectId].videoToolsetData = {
           ...state[projectId].videoToolsetData,
           ...JSON.parse(JSON.stringify(data)),
         };
       }
     },
     clearVideoToolsetData: (state, action) => {
       const { projectId, port, isPlugin } = action.payload;
       if (isPlugin) {
         if (state[projectId][port]) {
           state[projectId][port].videoToolsetData = {};
         }
       } else {
         if (state[projectId]) {
           state[projectId].videoToolsetData = {};
         }
       }
     },
   },
 });
 
 export const { updateVideoToolsetData, clearVideoToolsetData } =
   editToolsetSlice.actions;
 export default editToolsetSlice.reducer;