/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Routes,
  Route,
  useLocation,
  useAsyncError,
  useNavigate,
} from "react-router-dom";
import SignIn from "../pages/SignIn/SignIn";
import {
  AdminRoute,
  AdminSignInRoute,
  ProtectedRoute,
  SignInRoute,
} from "./ProtectedRoute";
import AccountComponent from "../pages/Account/AccountComponent";
import {
  GetAllSubscriptionAndAddons,
  GetUserPrivileges,
  GetUserStealthData,
  GetUserTeamsInvites,
} from "../utils/apiCalls";
import RenewPopup from "../components/renew-popup/RenewPopup";
import ErrorPopup from "../components/ErrorPopup/ErrorPopup";
import Notification from "../components/Notification/Notification";
import SubscriptionDetails from "../components/SubScriptionPopups/subscription-details/SubscriptionDetails";
import AiriBotComponent from "../pages/AiriBot/AiriBotComponent";
import Workspace from "../pages/Workspace/Workspace";
import Gallery from "../pages/Gallery/Gallery";
import CommunityGallery from "../pages/CommunityGallery/CommunityGallery";
import FAQ from "../pages/FAQ/FAQ";
import News from "../pages/News/News";
import Tutorials from "../pages/Tutorials/Tutorials";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import RenewalPolicy from "../pages/RenewalPolicy/RenewalPolicy";
import TermsOfServices from "../pages/TermsOfService/TermsOfServices";
import SuccessPage from "../pages/success/Success";
import NewVersion from "../components/NewVersion/NewVersion";
import ExitPopup from "../components/ExitPopup/ExitPopup";
import ContactComponent from "../pages/Contact/Contact";
import { UserSocketContext } from "../store/UserSocketData";
import { UserContext } from "../store/UserDetailsStore";
import TeamNotifications from "../components/Teams/TeamNofications/TeamNotifications";
import Confirm from "../components/confirmModal/confirm";
import { useTranslation } from "react-i18next";
import TokenExpirePopup from "../components/TokenExpirePopup/TokenExpirePopup";
import AIProjectMangement from "../pages/AIProjectMangement/AIProjectMangement";
import { apiError } from "../utils/apiCalls";
import ApiErrorPopup from "../components/ApiErrorPopup/ApiErrorPopup";
import SubscriptionLimit from "../components/SubScriptionPopups/SubscriptionLimit/SubscriptionLimit";
import Home from "../pages/Home/Home";
import TeamGallery from "../pages/TeamGallery/TeamGallery";
import AdminSignIn from "../pages/AdminPortal/Admin-SignIn/AdminSignIn";
import Dashboard from "../pages/AdminPortal/Dashboard/Dashboard";
import UsersList from "../pages/AdminPortal/UsersList/UsersList";
import TutorialVideo from "../pages/TutorialVideo/TutorialVideo";
import WorkShop from "../pages/Workshop/Workshop";
import GifLoader from "../components/gifLoader";
import { TeamDesignContext } from "../store/TeamDesignData";
import Galleries from "../pages/Galleries/Galleries";
import { sortAddonsByDate } from "../utils/commonUtils";
import MembershipUpgrade from "../components/SubScriptionPopups/MembershipUpgrade/MembershipUpgrade";
import NewMembershipPlan from "../components/SubScriptionPopups/NewMembershipPlan/NewMembershipPlan";
import StripeSuccessPage from "../pages/success/StripeSuccess";
import Plugins from "../pages/Plugins/Plugins";

const RouterComopnent = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const apiCalledRef = useRef(false);
  const token = localStorage.getItem("token");
  const langnuage = localStorage.getItem("language");
  const isActiveTeam = JSON.parse(localStorage.getItem("lastProjectGenerate"));
  const {
    socketData,
    isNotification,
    updateSocketData,
    isTeamInvite,
    openTeamNotification,
    handleTeamNofications,
    setIsTeamInvite,
    subscriptionLimit,
    handleShowSubscription,
    isShowSubscription,
    teamMemberNotification,
    teamMemberNotificationData,
    handleTeamMemberNofications,
    teamMemberNotification1,
    handleTeamMemberNofications1,
    teamMemberNotification2,
    handleTeamMemberNofications2,
    isSubscriptionUpgrade,
    isSubscriptionUpgrade1,
  } = useContext(UserSocketContext);
  const {
    wechatCode,
    isLoginUserDetails,
    setIsprivilagesDetails,
    setIsUserMembershipDetails,
    showNews,
  } = useContext(UserContext);
  const { getTeamsOrder, getAllUserTeams, apiCalledRef1 } =
    useContext(TeamDesignContext);
  const [allTeamsInvites, setAllteamsInvites] = useState([]);

  const [isShowRenew, setIsShowRenew] = useState(false);
  const [isErrorPopup, setIsErrorPopup] = useState(false);
  const [isErrorFromLogin, setIsErrorFromLogin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isShowPostLoginLoader, setIsShowPostLoginLoader] = useState(false);

  useEffect(() => {
    const path = pathname.split("/");
    path.shift();
    if (path?.includes("admin")) {
      setIsAdmin(true);
    } else setIsAdmin(false);

    if (pathname !== "/projects") {
      localStorage.setItem("isWorkshop", false);
    }
    if (pathname !== "/galleries") {
      const isGalley = {
        isGallery: 1,
      };
      localStorage.setItem("isGallery", JSON.stringify(isGalley));
    }
  }, [pathname]);

  useEffect(() => {
    const isLoginUserSetInLocalStorage = localStorage.getItem("isLoginUser"); //important because if token expired local storage is not cleared but context is cleared
    if (!isLoginUserDetails && !isLoginUserSetInLocalStorage) {
      setIsShowPostLoginLoader(true);
    } else {
      setTimeout(() => {
        setIsShowPostLoginLoader(false);
      }, 1000);
    }
  }, [isLoginUserDetails]);

  const handleShowRenew = () => {
    setIsShowRenew(!isShowRenew);
  };

  const handleShowErrorMessage = (state) => {
    if (state) {
      setIsErrorPopup(state);
      setIsErrorFromLogin(state);
    } else {
      setIsErrorPopup(!isErrorPopup);
    }
  };

  const handleShowNotification = () => {
    updateSocketData({
      isShowNotification: false,
    });
  };

  useEffect(() => {
    const stealthLocal = localStorage.getItem("stealth");
    if (stealthLocal === undefined || stealthLocal === null) {
      if (token) {
        getStealthData();
      }
    }
  }, [token]);

  useEffect(() => {
    if (token !== null && token !== undefined) {
      getStealthData();
      getAllSubscriptionAndAddons();
      getUserAllPrivilages(isActiveTeam?.teamId);
      getAllInvites();
      getTeamsOrder();
      getAllUserTeams();
    }
  }, [token]);

  const getStealthData = () => {
    const teamId = 0;
    GetUserStealthData((res) => {
      if (res?.status === 200) {
        localStorage.setItem("stealth", res.stealth);
      }
      if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      }
    }, teamId);
  };

  const getUserAllPrivilages = () => {
    const teamId = isActiveTeam?.teamId;
    GetUserPrivileges((res) => {
      if (res?.status === 200) {
        setIsprivilagesDetails(res?.data);
      }
    }, teamId);
  };

  const getAllSubscriptionAndAddons = () => {
    const teamId = 0;
    setIsUserMembershipDetails();
    const lang = localStorage.getItem("language");
    GetAllSubscriptionAndAddons(
      (res) => {
        if (res?.status === 200) {
          const addons = sortAddonsByDate(res?.data?.addons);
          setIsUserMembershipDetails({ ...res?.data, addons });
        }
      },
      lang,
      teamId
    );
  };

  const handleOpen = () => {
    handleTeamNofications();
    setIsTeamInvite((prev) => ({
      ...prev,
      showTeamNotification: false,
    }));
  };

  const handleClose = () => {
    setIsTeamInvite((prev) => ({
      ...prev,
      showTeamNotification: false,
    }));
  };

  const handleOpen1 = () => {
    handleTeamMemberNofications();
  };

  const handleOpen2 = () => {
    handleTeamMemberNofications1();
    navigate("/projects");
    apiCalledRef1.current = false;
    getTeamsOrder();
    getAllUserTeams();
  };

  const handleOpen3 = () => {
    handleTeamMemberNofications2();
    navigate("/projects");
    apiCalledRef1.current = false;
    getTeamsOrder();
    getAllUserTeams();
  };

  useEffect(() => {
    apiCalledRef.current = false;
    if (!apiCalledRef.current && token !== null && token !== undefined) {
      apiCalledRef.current = true;
      getAllInvites();
    }
  }, [pathname, isTeamInvite?.showTeamNotification]);

  const getAllInvites = () => {
    const language = langnuage === "ch" ? true : false;
    GetUserTeamsInvites((res) => {
      if (res?.status === 200) {
        if (res?.data?.length !== 0) {
          setAllteamsInvites(res?.data);
          setIsTeamInvite((prev) => ({
            ...prev,
            showRedDot: true,
          }));
        } else {
          setIsTeamInvite((prev) => ({
            ...prev,
            showRedDot: false,
          }));
        }
      } else {
        setAllteamsInvites([]);
        setIsTeamInvite((prev) => ({
          ...prev,
          showRedDot: false,
        }));
      }
    }, language);
  };

  const teamContent =
    langnuage !== "ch" ? isTeamInvite?.engContent : isTeamInvite?.chContent;
  const teamStatus =
    langnuage !== "ch"
      ? teamMemberNotificationData?.en
      : teamMemberNotificationData?.ch;

  return (
    <>
      {apiError && <ApiErrorPopup />}
      {socketData?.isExitPopup === true &&
        token !== null &&
        token !== undefined && <ExitPopup />}
      {socketData?.isTokenExpirePopup === true &&
        token !== null &&
        token !== undefined && <TokenExpirePopup />}
      {!socketData?.isExitPopup &&
        socketData?.isNewVersionPopup &&
        token !== null &&
        token !== undefined && <NewVersion />}
      {isErrorPopup && token !== null && token !== undefined && <ErrorPopup />}
      {isErrorPopup && isErrorFromLogin && <ErrorPopup />}
      {isShowRenew && token !== null && token !== undefined && (
        <RenewPopup handleShowRenew={handleShowRenew} />
      )}
      {isShowSubscription === true && token !== null && token !== undefined && (
        <SubscriptionDetails />
      )}
      {socketData?.isShowNotification &&
        token !== null &&
        token !== undefined && (
          <Notification
            handleShowNotification={handleShowNotification}
            isNotification={isNotification}
          />
        )}
      {subscriptionLimit && <SubscriptionLimit />}
      {openTeamNotification && token !== null && token !== undefined && (
        <TeamNotifications
          allTeamsInvites={allTeamsInvites}
          getAllInvites={getAllInvites}
        />
      )}
      {isTeamInvite?.showTeamNotification &&
        token !== null &&
        token !== undefined && (
          <Confirm
            confirmTitle={t("team_collab_invite")}
            content={teamContent}
            isCancelRequired={true}
            onConfirm={handleOpen}
            onCancel={handleClose}
            buttonText={t("view_notifications")}
            cancelButtonText={t("cancel")}
          />
        )}
      {teamMemberNotification && token !== null && token !== undefined && (
        <Confirm
          confirmTitle={t("team_invite_status")}
          content={teamStatus}
          isCancelRequired={false}
          onConfirm={handleOpen1}
          buttonText={t("ok")}
        />
      )}
      {teamMemberNotification1 && token !== null && token !== undefined && (
        <Confirm
          confirmTitle={t("team_remove_status")}
          content={teamStatus}
          isCancelRequired={false}
          onConfirm={handleOpen2}
          buttonText={t("ok")}
        />
      )}
      {teamMemberNotification2 && token !== null && token !== undefined && (
        <Confirm
          confirmTitle={t("team_delete_status")}
          content={teamStatus}
          isCancelRequired={false}
          onConfirm={handleOpen3}
          buttonText={t("ok")}
        />
      )}
      {isSubscriptionUpgrade && token !== null && token !== undefined && (
        <MembershipUpgrade />
      )}
      {isSubscriptionUpgrade1 && token !== null && token !== undefined && (
        <NewMembershipPlan />
      )}
      {wechatCode === null &&
        isShowPostLoginLoader &&
        token !== null &&
        token !== undefined && <GifLoader message={t("initializing_data")} />}
      {showNews && <News />}
      <Routes>
        <Route
          path="/sign-in"
          element={
            <SignInRoute>
              <SignIn handleShowErrorMessage={handleShowErrorMessage} />
            </SignInRoute>
          }
        />
        <Route path="/*" element={<Home />} />
        <Route
          path="/projects"
          element={
            <ProtectedRoute>
              <WorkShop />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account"
          element={
            <ProtectedRoute>
              <AccountComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/airi-bot"
          element={
            <ProtectedRoute>
              <AiriBotComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/airi-project-management"
          element={
            <ProtectedRoute>
              <AIProjectMangement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/workspace/:id"
          element={
            <ProtectedRoute>
              <Workspace />
            </ProtectedRoute>
          }
        />
        <Route
          path="/galleries"
          element={
            <ProtectedRoute>
              <Galleries />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gallery"
          element={
            <ProtectedRoute>
              <Gallery />
            </ProtectedRoute>
          }
        />
        <Route
          path="/community-gallery"
          element={
            <ProtectedRoute>
              <CommunityGallery />
            </ProtectedRoute>
          }
        />
        <Route
          path="/team-gallery"
          element={
            <ProtectedRoute>
              <TeamGallery handleShowSubscription={handleShowSubscription} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tutorials"
          element={
            <ProtectedRoute>
              <FAQ />
            </ProtectedRoute>
          }
        />
        <Route
          path="/news"
          element={
            <ProtectedRoute>
              <News />
            </ProtectedRoute>
          }
        />

        <Route
          path="/tutorials"
          element={
            <ProtectedRoute>
              <Tutorials />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tutorial-data/:id"
          element={
            <ProtectedRoute>
              <TutorialVideo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contact"
          element={
            <ProtectedRoute>
              <ContactComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/plugins"
          element={
            <ProtectedRoute>
              <Plugins />
            </ProtectedRoute>
          }
        />
        <Route
          path="/success/*"
          element={
            <ProtectedRoute>
              <SuccessPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/stripe-success/*"
          element={
            <ProtectedRoute>
              <StripeSuccessPage />
            </ProtectedRoute>
          }
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-services" element={<TermsOfServices />} />
        <Route path="/renewal-policy" element={<RenewalPolicy />} />
        <Route path="admin/*">
          <Route
            path="sign-in"
            element={
              <AdminSignInRoute>
                <AdminSignIn handleShowErrorMessage={handleShowErrorMessage} />
              </AdminSignInRoute>
            }
          />
          <Route
            path="dashboard"
            element={
              <AdminRoute>
                <Dashboard />
              </AdminRoute>
            }
          />
          <Route
            path="users"
            element={
              <AdminRoute>
              <UsersList />
              </AdminRoute>
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default RouterComopnent;
