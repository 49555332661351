/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import "./GalleryHeader.scss";
import allicon from "../../assets/images/workspace/icons/allb.png";
import upscaledicon from "../../assets/images/workspace/icons/upscaledb.png";
import upscalediconhover from "../../assets/images/workspace/icons/upscalebh1.png";
import favorite from "../../assets/images/workspace/icons/favorite.png";
import alliconactive from "../../assets/images/icons/all-active.png";
import upscalediconactive from "../../assets/images/icons/enhance-active.png";
import favoriteactive from "../../assets/images/icons/favorite-active.png";
import selecticon from "../../assets/images/icons/select.png";
import downloadicon from "../../assets/images/icons/download.png";
import deleteicon from "../../assets/images/icons/delete.png";
import selecticonactive from "../../assets/images/icons/select-active.png";
import downloadiconactive from "../../assets/images/icons/download-active.png";
import deleteiconactive from "../../assets/images/icons/delete-active.png";
import videoicon from "../../assets/images/icons/video-d.png";
import videoactive from "../../assets/images/icons/video-a.png";
import { t } from "i18next";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { ResponsiveDataContext } from "../../store/ResponsiveData";
import { Popover } from "antd";
import {
  GetAllGalleryTeamProjectsList,
  GetAllGalleryTeamsList,
} from "../../utils/apiCalls";
import { UserSocketContext } from "../../store/UserSocketData";

const CustomOption = (props) => {
  const { responsiveData } = useContext(ResponsiveDataContext);
  return (
    <components.Option {...props}>
      {props.data.icon && (
        <img
          src={props.data.icon}
          alt="option-icon"
          style={{
            marginRight: 10,
            width: `${responsiveData ? "12px" : "18px"}`,
            height: `${responsiveData ? "12px" : "18px"}`,
          }}
        />
      )}
      <span style={{ fontSize: `${responsiveData ? "12px" : "16px"}` }}>
        {props.data.label}
      </span>
    </components.Option>
  );
};

const GalleryHeader = ({
  isSelectMode,
  setIsSelectMode,
  selectedImages,
  setSelectedImages,
  downloadIcon,
  setDownloadIcon,
  deleteIcon,
  setDeleteIcon,
  setAlertText,
  setShowConfirmModal,
  searchOptions,
  setSearchOptions,
  activeTab,
  setActiveTab,
  isShowIcons,
  isLoading,
  setAlertContent,
  isFavoriteTab,
  isFavoriteImages,
  showDeleteIcon = true,
  directDelete = false,
  selectTeam = true,
  styles = {
    width: "960px",
  },
  selectedTeamId,
  setSelectedTeamId,
  selectedProjectId,
  setSelectedProjectId,
  isActiveTab,
  setTeams,
}) => {
  const abortControllerRef2 = useRef(null);
  const abortControllerRef1 = useRef(null);
  const { responsiveData, screenWidth } = useContext(ResponsiveDataContext);
  const { updateSocketData } = useContext(UserSocketContext);
  const lang = localStorage.getItem("language");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [isTeamsList, setIsTeamList] = useState([]);
  const [projectsList, setProjectsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const options = [];

  const customStyles = {
    control: (base, state) => ({
      ...base,
      width: styles.width,
      borderRadius: "8px",
      border: state.isFocused ? "1px solid #F8F8F9" : "1px solid #F8F8F9",
      boxShadow: state.isFocused ? "0 0 0 1px #263238" : "none",
      "&:hover": {
        border: state.isFocused ? "1px solid #F8F8F9" : "none",
      },
    }),
    menu: (base) => ({
      ...base,
      display: "none",
      width: "100%",
      borderColor: "#263238",
      backgroundColor: "#f8f8f9",
      borderRadius: "8px",
    }),
    option: (base) => ({
      ...base,
      padding: `${responsiveData ? "4px 12px" : "8px 12px"}`,
      // color: "#A8ADAF",
      color: "#677074",
      borderRadius: "8px",
      backgroundColor: "#f8f8f9",
      opacity: 0.7,
      ":hover": {
        backgroundColor: "white",
        // color: "#677074",
        borderRadius: "8px",
        opacity: 1,
      },
      ":active": {
        ...base[":active"],
        backgroundColor: "white",
        // color: "#000000",
      },
    }),
  };

  const customStyles1 = {
    control: (base, state) => ({
      ...base,
      width: "142px",
      borderRadius: "8px",
      border: state.isFocused ? "1px solid #F8F8F9" : "1px solid #F8F8F9",
      boxShadow: state.isFocused ? "0 0 0 1px #263238" : "none",
      "&:hover": {
        border: state.isFocused ? "1px solid #F8F8F9" : "none",
      },
    }),
    menu: (base) => ({
      ...base,
      width: screenWidth < 767 ? "200px" : "237px",
      minHeight: "auto !important",
      maxHeight: "400px",
      borderColor: "#263238",
      backgroundColor: "#f8f8f9",
      // borderRadius: "8px",,
      padding: "8px 0",
    }),
    option: (base, state) => ({
      ...base,
      width: "230px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      padding: `${responsiveData ? "4px 12px" : "8px 12px"}`,
      color: state.isSelected ? "#ffffff" : "#263238",
      // borderRadius: "8px",
      backgroundColor: state.isSelected ? "#263238" : "#f8f8f9",
      opacity: 0.7,
      ":hover": {
        backgroundColor: "white",
        color: "#263238",
        opacity: 1,
      },
      ":active": {
        ...base[":active"],
        backgroundColor: "#263238",
        color: "#FFFFFF",
      },
    }),
  };

  const customComponents = {
    IndicatorSeparator: () => null,
  };

  useEffect(() => {
    if (isActiveTab !== 3) {
      getAllGalleryteams();
    }
  }, [isActiveTab]);

  useEffect(() => {
    if (isTeamsList?.length !== 0) {
      getAllGalleryteamProjects(selectedTeamId?.value);
    }
  }, [selectedTeamId]);

  const getAllGalleryteams = () => {
    setLoading(true);
    const bool = isActiveTab === 1 ? true : false;

    if (abortControllerRef2.current) {
      abortControllerRef2.current.abort();
    }
    const controller = new AbortController();
    abortControllerRef2.current = controller;
    const signal = controller.signal;
    GetAllGalleryTeamsList(
      (res) => {
        if (signal.aborted) {
          return;
        }
        if (res?.status === 200) {
          setLoading(false);
          if (isActiveTab === 2) {
            setTeams(res?.data);
          }
          if (res?.data?.length !== 1) {
            const all = {
              label: t(`all_teams_gallery`),
              value: "null",
            };
            const updatedData = res?.data?.map((team) => ({
              label: team?.teamId === 0 ? t("my_space") : team?.teamName,
              value: team?.teamId,
            }));
            const refactoredData = [all, ...updatedData];
            if(showDeleteIcon) {
              setSelectedTeamId(refactoredData[0]);
            } else {
              setSelectedTeamId(refactoredData[1]);
            }
            setIsTeamList(refactoredData);
          } else {
            const updatedData = res?.data?.map((team) => ({
              label: team?.teamId === 0 ? t("my_space") : team?.teamName,
              value: team?.teamId,
            }));
            setSelectedTeamId(updatedData[0]);
            setIsTeamList(updatedData);
          }
        } else if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      },
      bool,
      signal
    );
  };

  const getAllGalleryteamProjects = (id) => {
    setLoading1(true);
    const bool = isActiveTab === 1 ? true : false;

    if (abortControllerRef1.current) {
      abortControllerRef1.current.abort();
    }
    const controller = new AbortController();
    abortControllerRef1.current = controller;
    const signal = controller.signal;
    GetAllGalleryTeamProjectsList(
      (res) => {
        if (signal.aborted) {
          return;
        }
        if (res?.status === 200) {
          setLoading1(false);
          if (res?.data?.length !== 0) {
            const all = {
              label: t(`all_projects_gallery`),
              value: 0,
            };
            const updatedData = res?.data
              ?.filter((team) => team?.projectId !== null)
              ?.map((team) => ({
                label: team?.projectName,
                value: team?.projectId,
              }));
            const refactoredData = [all, ...updatedData];
            setSelectedProjectId(refactoredData[0]);
            setProjectsList(refactoredData);
          } else {
            const noProjects = {
              label: t(`no_projects_available`),
              value: -1,
              isDisabled: true,
            };
            const refactoredData = [noProjects];
            setSelectedProjectId(refactoredData[0]);
            setProjectsList(refactoredData);
          }
        } else if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      },
      bool,
      id,
      signal
    );
  };

  useEffect(() => {
    if (isSelectMode === false) {
      setSelectedImages([]);
    }
  }, [isSelectMode]);

  const handleSelectIconClick = () => {
    setIsSelectMode(!isSelectMode);
  };

  const handleDownloadPopUp = () => {
    setDownloadIcon(true);
  };

  const handleDeletePopUp = () => {
    if (directDelete) {
      setDeleteIcon(true);
    } else if (selectedImages.some((id) => isFavoriteImages[id])) {
      alert(t("cannot_delete_favorite"));
    } else {
      setDeleteIcon(true);
    }
  };

  const handleChange = (selected) => {
    const values = selected.map((option) => option.value);
    const valueString = values.join(",");
    const valueObject = { valueString };

    setSelectedOptions(selected);
    setSearchOptions(valueObject);
  };

  const handleOpen = (isOpen) => {
    setOpen(isOpen);
  };

  const handleOpen1 = (isOpen) => {
    setOpen1(isOpen);
  };

  return (
    <div className="gallery-header">
      <div className="gallery-header__container">
        <div className="gallery-header__container__top">
          {selectTeam && (
            <div className="gallery-header__container__top__sec">
              <Select
                value={selectedTeamId}
                onChange={setSelectedTeamId}
                options={isTeamsList}
                styles={customStyles1}
                components={customComponents}
                placeholder={t(`select_team_gallery`)}
                isDisabled={loading}
                // isLoading={loading}
              />
            </div>
          )}
          {selectTeam && (
            <div className="gallery-header__container__top__sec">
              <Select
                value={selectedProjectId}
                onChange={setSelectedProjectId}
                options={projectsList}
                styles={customStyles1}
                components={customComponents}
                placeholder={t(`select_project_gallery`)}
                isDisabled={
                  loading1 || loading || selectedProjectId?.value === -1
                }
                // isLoading={!loading1 || !loading}
              />
            </div>
          )}
          <div className="gallery-header__container__top__search">
            <CreatableSelect
              options={options}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ DropdownIndicator: null, Option: CustomOption }}
              value={selectedOptions}
              onChange={handleChange}
              classNamePrefix="react-select"
              styles={customStyles}
              placeholder={t(`search_prompt`)}
            />
          </div>
        </div>
        <div className="gallery-header__container__bottom">
          <div className="gallery-header__container__left">
            <button
              className={`gallery-header__container__left__sec ${
                activeTab === "all" && "active"
              } ${lang === "ch" ? "ch" : ""}`}
              onClick={() => setActiveTab("all")}
              disabled={isLoading}
            >
              <img className="default-icon" src={allicon} alt="all-icon" />
              <img className="hover-icon" src={allicon} alt="all-icon" />
              <img className="active-icon" src={alliconactive} alt="all-icon" />
              <h4>{t("all")}</h4>
            </button>
            <button
              className={`gallery-header__container__left__sec ${
                activeTab === "upscale" && "active"
              } ${lang === "ch" ? "ch" : ""}`}
              onClick={() => setActiveTab("upscale")}
              disabled={isLoading}
            >
              <img
                className="default-icon"
                src={upscaledicon}
                alt="upscaled-icon"
              />
              <img
                className="hover-icon"
                src={upscalediconhover}
                alt="upscaled-icon"
              />
              <img
                className="active-icon"
                src={upscalediconactive}
                alt="upscaled-icon"
              />
              <h4>{t("enhance")}</h4>
            </button>
            {isFavoriteTab && (
              <button
                className={`gallery-header__container__left__sec ${
                  activeTab === "favorite" && "active"
                } ${lang === "ch" ? "ch" : ""}`}
                onClick={() => setActiveTab("favorite")}
                disabled={isLoading}
              >
                <img
                  className="default-icon"
                  src={favorite}
                  alt="favorite-icon"
                />
                <img
                  className="hover-icon"
                  src={favorite}
                  alt="favorite-icon"
                />
                <img
                  className="active-icon"
                  src={favoriteactive}
                  alt="favorite-icon"
                />
                <h4>{t("favorite")}</h4>
              </button>
            )}
            <button
              className={`gallery-header__container__left__sec ${
                activeTab === "video" && "active"
              } ${lang === "ch" ? "ch" : ""}`}
              onClick={() => setActiveTab("video")}
              disabled={isLoading}
            >
              <img
                className="default-icon"
                src={videoicon}
                alt="upscaled-icon"
              />
              <img
                className="hover-icon"
                src={videoicon}
                alt="upscaled-icon"
              />
              <img
                className="active-icon"
                src={videoactive}
                alt="upscaled-icon"
              />
              <h4>{t("VIDEO")}</h4>
            </button>
          </div>
          <div className="gallery-header__container__right">
            {isShowIcons && (
              <>
                <div
                  className={`gallery-header__container__right__sec ${
                    isSelectMode === true ? "active" : ""
                  }`}
                  onClick={handleSelectIconClick}
                >
                  <img
                    className="default-icon"
                    src={selecticon}
                    alt="select-icon"
                  />
                  <img
                    className="active-icon"
                    src={selecticonactive}
                    alt="select-icon"
                  />
                  <h5>{t("multiselect")}</h5>
                </div>
                <Popover
                  overlayClassName="custom-popover-gallery"
                  content={t("disable-gallery")}
                  arrow={true}
                  trigger="hover"
                  placement="top"
                  open={selectedImages?.length === 0 ? open : false}
                  onOpenChange={handleOpen}
                >
                  <div
                    className={`gallery-header__container__right__sec ${
                      downloadIcon === true ? "active" : ""
                    } ${selectedImages?.length === 0 ? "disable" : ""}`}
                    onClick={
                      selectedImages?.length === 0
                        ? null
                        : () => handleDownloadPopUp()
                    }
                  >
                    <img
                      className="default-icon"
                      src={downloadicon}
                      alt="download-icon"
                    />
                    <img
                      className="active-icon"
                      src={downloadiconactive}
                      alt="download-icon"
                    />
                    <h5>{t("download-gallery")}</h5>
                  </div>
                </Popover>
                {showDeleteIcon && (
                  <Popover
                    overlayClassName="custom-popover-gallery"
                    content={t("disable-gallery")}
                    arrow={true}
                    trigger="hover"
                    placement="top"
                    open={selectedImages?.length === 0 ? open1 : false}
                    onOpenChange={handleOpen1}
                  >
                    <div
                      className={`gallery-header__container__right__sec ${
                        deleteIcon === true ? "active" : ""
                      } ${selectedImages?.length === 0 ? "disable" : ""}`}
                      onClick={
                        selectedImages?.length === 0
                          ? null
                          : () => handleDeletePopUp()
                      }
                    >
                      <img
                        className="default-icon"
                        src={deleteicon}
                        alt="delete-icon"
                      />
                      <img
                        className="active-icon"
                        src={deleteiconactive}
                        alt="delete-icon"
                      />
                      <h5>{t("delete-gallery")}</h5>
                    </div>
                  </Popover>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryHeader;
