import React from "react";
import "./NewMembershipPlan.scss";
import successicon from "../../../assets/images/icons/upgrade-notification1.png";
import gifticon from "../../../assets/images/icons/gift.png";
import eyeicon from "../../../assets/images/icons/eye.png";
import crossicon from "../../../assets/images/icons/cross.png";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { UserSocketContext } from "../../../store/UserSocketData";

const NewMembershipPlan = () => {
  const { t } = useTranslation();
  const {
    setIsSubscriptionUpgrade,
    subscriptionUpgradeContent1,
    setIsSubscriptionUpgrade1,
    setSubscriptionUpgradeContent1,
    handleShowSubscription,
  } = useContext(UserSocketContext);

  const langnuage = localStorage.getItem("language");

  const mainHeading =
    langnuage !== "ch"
      ? subscriptionUpgradeContent1[1]
      : subscriptionUpgradeContent1[4];
  const heading =
    langnuage !== "ch"
      ? subscriptionUpgradeContent1[2]
      : subscriptionUpgradeContent1[5];
  const content =
    langnuage !== "ch"
      ? subscriptionUpgradeContent1[3]
      : subscriptionUpgradeContent1[6];

  const updatedContent = content.replace(
    /<h6>/g,
    `<div style="display: flex; align-items: flex-start; gap: 8px"><img src="${gifticon}" alt="gift-icon" /><h6>`
  );
  const updatedContent1 = updatedContent.replace(/<\/h6>/g, "</h6></div>");

  const updatedContent2 = updatedContent1.replace(
    /<h5>/g,
    `<div style="display: flex; align-items: flex-start; gap: 8px; margin:32px 0 12px 0;"><img src="${eyeicon}" alt="gift-icon" /><h5>`
  );
  const updatedContent3 = updatedContent2.replace(/<\/h5>/g, "</h5></div>");

  const handleViewPlans = () => {
    setIsSubscriptionUpgrade(false);
    setIsSubscriptionUpgrade1(false);
    setSubscriptionUpgradeContent1(null);
    handleShowSubscription();
  };

  return (
    <div className="plan-upgrade">
      <div className="plan-upgrade__container">
        <div className="plan-upgrade__sections">
          <div className="plan-upgrade__heading">
            <img src={crossicon} className="close-icon" alt="close-icon" onClick={() => setIsSubscriptionUpgrade1(false)} />
            <div className="plan-upgrade__heading__top">
              <img src={successicon} alt="upgrade-icon" />
              <h2>{mainHeading}</h2>
            </div>
            <div className="plan-upgrade__heading__bottom">
              {/* <span></span> */}
              <h5>{heading}</h5>
            </div>
          </div>
          <div
            className="plan-upgrade__content"
            dangerouslySetInnerHTML={{
              __html: updatedContent3,
            }}
          />
          <div className="plan-upgrade__buttons">
            <button className="success" onClick={handleViewPlans}>
              {t("view_plans")}
            </button>
            <button onClick={() => setIsSubscriptionUpgrade1(false)}>
              {t("ok")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewMembershipPlan;
