/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useContext } from "react";
import "./AtmosphereSwift.scss";
import cross from "../../../assets/images/workspace/icons/cross-icon.png";
import sunny from "../../../assets/images/workspace/atmosphere/sunny.png";
import { GetCameraViewUrlUser } from "../../../utils/apiCalls";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/loader";
import { DesignLibraryContext } from "../../../store/DesignLibraryData";
import { UserSocketContext } from "../../../store/UserSocketData";

const AtmosphereSwift = ({ mainMenu, sideMenu }) => {
  const { t } = useTranslation();

  const url = window.location.pathname;
  const lastPart = url.substring(url.lastIndexOf("/") + 1);

  const atmosphere = JSON.parse(
    localStorage.getItem(`atmosphereData1_${lastPart}`)
  );
  const cameraData = JSON.parse(localStorage.getItem(`cameraData_${lastPart}`));
  const lang = localStorage.getItem("language");
  const { updateDesignLibraryData } = useContext(DesignLibraryContext);
  const { updateSocketData } = useContext(UserSocketContext);

  const [atmosphereData, setAtmosphereData] = useState([]);
  const [atmosphereData1, setAtmosphereData1] = useState([]);
  const [selectedAtmosphereId, setSelectedAtmosphereId] = useState(null);
  const [selectedAtmosphereUrl, setSelectedAtmosphereUrl] = useState();
  const [isLibrary, setIsLibrary] = useState(1);

  useEffect(() => {
    if(atmosphere?.isDesignType) {
      setIsLibrary(atmosphere?.isDesignType);
    }
  }, [atmosphere?.isDesignType])

  useEffect(() => {
    getAtmosphereData();
  }, [cameraData?.cameraViewId]);

  const handleAtmosphereClick = (atom, sideMenuItem, mainMenuItem) => {
    setSelectedAtmosphereId(atom?.id);
    setSelectedAtmosphereUrl(atom?.thumbnail);
    let atmosphere = JSON.parse(
      localStorage.getItem(`atmosphereData1_${lastPart}`)
    );

    if (atmosphere?.defaultSelectedAtmosphereUrl) {
      atmosphere.isDesignType = isLibrary;
      atmosphere.defaultSelectedAtmosphereId = atom?.id || selectedAtmosphereId;
      atmosphere.defaultSelectedAtmosphereUrl =
        atom?.thumbnail || selectedAtmosphereUrl;
      atmosphere.seasonName = sideMenuItem?.name || "sunny";
      atmosphere.seasonIcon = sideMenuItem?.image || sunny;
      atmosphere.seasonNameSelected = mainMenuItem?.name || "spring";
      atmosphere.seasonNameSelectedId = mainMenuItem?.id || 1;
    }
    localStorage.setItem(
      `atmosphereData1_${lastPart}`,
      JSON.stringify(atmosphere)
    );

    const newData = {
      isShowTrueOrFalse: false,
    };
    updateDesignLibraryData(newData);
  };

  const getAtmosphereData = () => {
    const designLibraryType = -1;
    const language = lang === "ch" ? "chs" : lang;
    if (designLibraryType) {
      if (cameraData?.cameraViewId) {
        GetCameraViewUrlUser(
          (res) => {
            if (res?.status === 200) {
              setAtmosphereData(res?.data?.slice(0, 18));
              setAtmosphereData1(res?.data?.slice(18, 36));
            } else if (res?.message === "Invalid access token") {
              updateSocketData({
                isExitPopup: true,
              });
            } else {
              setAtmosphereData([]);
            }
          },
          designLibraryType,
          language
        );
      }
    }
  };

  const handleCloseClick = () => {
    const newData = {
      isShowTrueOrFalse: false,
    };
    updateDesignLibraryData(newData);
  };

  const handleLibrary = (id) => {
    setIsLibrary(id);
  };

  const totalChunks = 6;
  const atmosphereDataChunks = Array.from({ length: totalChunks }, () => []);

  const data = isLibrary === 1 ? atmosphereData : atmosphereData1;
  data.forEach((atom, index) => {
    const chunkIndex = index % totalChunks;
    atmosphereDataChunks[chunkIndex].push({
      atom,
      sideMenuItem: sideMenu[chunkIndex],
    });
  });

  return (
    <div className="background" onClick={handleCloseClick}>
      <div className="atmosphere-swift" onClick={(e) => e.stopPropagation()}>
        <div className="atmosphere-swift__container">
          <div className="atmosphere-swift__main">
            <div className="atmosphere-swift__main__heading">
              <div className="atmosphere-swift__main__heading__tabs">
                <div
                  className={`atmosphere-swift__main__heading__tab ${
                    isLibrary === 1 ? "active" : ""
                  }`}
                  onClick={() => handleLibrary(1)}
                >
                  <h3>{t("atmosphere_exterior")}</h3>
                </div>
                <div
                  className={`atmosphere-swift__main__heading__tab ${
                    isLibrary === 2 ? "active" : ""
                  }`}
                  onClick={() => handleLibrary(2)}
                >
                  <h3>{t("atmosphere_interior")}</h3>
                </div>
              </div>
            </div>
            <div className="atmosphere-swift__main__section">
              <div className="atmosphere-swift__main__section__main-menus">
                {mainMenu?.length &&
                  mainMenu?.map((menu) => {
                    return (
                      <div
                        className={`atmosphere-swift__main__section__main-menus__sec ${
                          atmosphere?.seasonNameSelectedId === menu?.id
                            ? "active"
                            : ""
                        }`}
                        key={menu?.id}
                      >
                        <img src={menu?.image} alt={menu?.name} />
                        <h5>{menu?.name}</h5>
                      </div>
                    );
                  })}
              </div>
              <div className="atmosphere-swift__main__section__images">
                {atmosphereDataChunks?.length ? (
                  atmosphereDataChunks?.length &&
                  atmosphereDataChunks?.map((atmosphereChunk, chunkIndex) => {
                    const sideMenuItem = sideMenu[chunkIndex];
                    return (
                      <div
                        key={chunkIndex}
                        className="atmosphere-swift__main__section__images__side-menu"
                      >
                        <div
                          className={`atmosphere-swift__main__section__images__side-menu__header ${
                            atmosphere?.seasonName === sideMenuItem?.name
                              ? "active"
                              : ""
                          }`}
                        >
                          <img
                            src={sideMenuItem?.image}
                            alt={sideMenuItem?.name}
                          />
                          <h5>{sideMenuItem?.name}</h5>
                        </div>
                        <div className="atmosphere-swift__main__section__images__side-menu__content">
                          {atmosphereChunk?.length ? (
                            atmosphereChunk?.length &&
                            atmosphereChunk?.map(
                              ({ atom, sideMenuItem }, index) => {
                                const isSelected =
                                  atmosphere?.defaultSelectedAtmosphereId ===
                                  atom?.id;
                                return (
                                  <div
                                    className="atmosphere-swift__main__section__image"
                                    key={atom?.id}
                                  >
                                    <img
                                      className={`${
                                        isSelected ? "active" : ""
                                      }`}
                                      src={atom?.thumbnail}
                                      onClick={() =>
                                        handleAtmosphereClick(
                                          atom,
                                          sideMenuItem,
                                          mainMenu[index]
                                        )
                                      }
                                    />
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <Loader />
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AtmosphereSwift;
