import React, { useContext } from "react";
import "./UserPortalLayout.scss";
import { UserContext } from "../../store/UserDetailsStore";
import Header from "../Header/Header";

const UserPortalLayout = ({ children }) => {
  const { wechatCode } = useContext(UserContext);

  return (
    <div className="user-layout">
      <div className="user-layout__container">
        <div className="user-layout__sections">
          <div className="user-layout__sections__header">
            {wechatCode === null && <Header />}
          </div>
          <div className="user-layout__sections__main">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default UserPortalLayout;
