/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect } from "react";
import { GetOemInfo } from "../utils/apiCalls";
import load from "../assets/images/workspace/upscale-gif.gif";
import { AIRI_BASE_URL_INFO } from "../utils/api-constant";

const ClientDataContext = createContext();

function ClientDataProvider({ children }) {
  const [clientData, setClientData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getclientInfo();
  }, []);

  const getclientInfo = () => {
    setIsLoading(true);
    const url = AIRI_BASE_URL_INFO;
    const payload = {
      baseUrl: url,
    };
    GetOemInfo((res) => {
      if (res?.status === 200) {
        setClientData(res?.data);
        localStorage.setItem("clientData", JSON.stringify(res.data));
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, payload);
  };

  const updateClientData = (newData) => {
    setClientData((prevData) => ({ ...prevData, ...newData }));
  };

  const clearClientData = () => {
    setClientData(null);
  };

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={load} alt="loading-gif" style={{ width: "100px" }} />
      </div>
    );
  }

  return (
    <ClientDataContext.Provider
      value={{ clientData, updateClientData, clearClientData }}
    >
      {children}
    </ClientDataContext.Provider>
  );
}

export { ClientDataContext, ClientDataProvider };
