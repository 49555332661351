import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './locales/en.json';
import chTranslation from './locales/ch.json';
import frTranslation from './locales/fr.json';

const defaultLanguage = 'ch';

const savedLanguage = localStorage.getItem('language');
const selectedLanguage = savedLanguage || defaultLanguage;

const resources = {
  en: { translation: enTranslation },
  ch: { translation: chTranslation },
  fr: { translation: frTranslation },
};

i18n.use(initReactI18next).init({
  resources,
  lng: selectedLanguage,
  fallbackLng: defaultLanguage,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
