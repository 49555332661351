/* eslint-disable default-case */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-self-assign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect, useContext } from "react";
import "./ToolSetStyles.scss";
import texti from "../../../assets/images/workspace/icons/textb.png";
import texth from "../../../assets/images/workspace/icons/textbh.png";
import textd from "../../../assets/images/workspace/icons/textbd.png";
import designi from "../../../assets/images/workspace/icons/designb.png";
import designh from "../../../assets/images/workspace/icons/designbh.png";
import designd from "../../../assets/images/workspace/icons/designbd.png";
import imagei from "../../../assets/images/workspace/icons/imageb.png";
import imageh from "../../../assets/images/workspace/icons/imagebh.png";
import imaged from "../../../assets/images/workspace/icons/imagebd.png";
import inpainti from "../../../assets/images/workspace/icons/inpainti.png";
import inpainth from "../../../assets/images/workspace/icons/inpainth.png";
import inpaintd from "../../../assets/images/workspace/icons/inpaintd.png";
import shapei from "../../../assets/images/workspace/icons/shapei.png";
import shapeh from "../../../assets/images/workspace/icons/shapeh.png";
import shaped from "../../../assets/images/workspace/icons/shaped.png";
import eraseri from "../../../assets/images/workspace/icons/eraseri.png";
import eraserh from "../../../assets/images/workspace/icons/eraserh.png";
import eraserd from "../../../assets/images/workspace/icons/eraserd.png";
import videoa from "../../../assets/images/workspace/icons/video_a.png";
import videoh from "../../../assets/images/workspace/icons/video_h.png";
import videod from "../../../assets/images/workspace/icons/video_d.png";
import extendi from "../../../assets/images/workspace/icons/extend-a.png";
import extendh from "../../../assets/images/workspace/icons/extend-h.png";
import extendd from "../../../assets/images/workspace/icons/extend-d.png";
import atmospherei from "../../../assets/images/workspace/icons/atmosphere-a.png";
import atmosphereh from "../../../assets/images/workspace/icons/atmosphere-h.png";
import atmosphered from "../../../assets/images/workspace/icons/atmosphere-d.png";
import upscalei from "../../../assets/images/workspace/icons/upscaleb.png";
import upscaleh from "../../../assets/images/workspace/icons/upscalebh.png";
import upscaled from "../../../assets/images/workspace/icons/upscalebd.png";
import HDi from "../../../assets/images/workspace/icons/HDb.png";
import HDh from "../../../assets/images/workspace/icons/HDh.png";
import HDd from "../../../assets/images/workspace/icons/HDd.png";
import horizontal from "../../../assets/images/workspace/icons/verti.png";
import vertical from "../../../assets/images/workspace/icons/hori.png";
import baseicon from "../../../assets/images/workspace/icons/base-icon1.png";
import screenshot from "../../../assets/images/workspace/icons/screenshot.png";
import deleteicon from "../../../assets/images/workspace/icons/upload-delete.png";
import deleteicona from "../../../assets/images/workspace/icons/upload-deletea.png";
import deleteiconh from "../../../assets/images/workspace/icons/upload-deleteh.png";
import refreshicon from "../../../assets/images/workspace/icons/refresh.png";
import refreshicona from "../../../assets/images/workspace/icons/refresha.png";
import refreshiconh from "../../../assets/images/workspace/icons/refreshh.png";
import intro from "../../../assets/images/workspace/icons/intro.png";
import modificationLevelImage from "../../../assets/images/hover-tips-images/modification-level.jpg";
import renderimage from "../../../assets/images/hover-tips-images/base-image-for-render.png";
import maskimage from "../../../assets/images/hover-tips-images/mask.png";
import preserveimage from "../../../assets/images/hover-tips-images/preserve_image.jpg";
import extendinfo from "../../../assets/images/hover-tips-images/extendinfo.jpg";
import upscaleinfoCh from "../../../assets/images/hover-tips-images/upscale-ch.jpg";
import upscaleinfoEn from "../../../assets/images/hover-tips-images/upscale-en.jpg";
import atmosphereen from "../../../assets/images/hover-tips-images/atmosphere-swift-en.png";
import atmospherefr from "../../../assets/images/hover-tips-images/atmosphere-swift-fr.png";
import atmospherech from "../../../assets/images/hover-tips-images/atmosphere-swift-ch.png";
import imageresolutionimage from "../../../assets/images/hover-tips-images/image-resolution.jpg";
import controlanim from "../../../assets/images/control_inpaint_anim.gif";
import inpaintimage from "../../../assets/images/hover-tips-images/inpaint.jpg";
import eraserimage from "../../../assets/images/hover-tips-images/eraser.jpg";
import controlinpaintimage from "../../../assets/images/hover-tips-images/control_reference_image.jpg";
import video from "../../../assets/images/hover-tips-images/video.png";
import timelapse from "../../../assets/images/hover-tips-images/time-lapse.gif";
import controlLevelrenderCh from "../../../assets/images/hover-tips-images/control_level_ch.jpg";
import controlLevelrenderEn from "../../../assets/images/hover-tips-images/control_level_en.jpg";
import controlLevelEn from "../../../assets/images/hover-tips-images/control-line-en.jpg";
import controlLevelCh from "../../../assets/images/hover-tips-images/control-line-ch.jpg";
import upscaleEn from "../../../assets/images/hover-tips-images/upscale-en.jpg";
import upscaleCh from "../../../assets/images/hover-tips-images/upscale-ch.jpg";
import videoTip from "../../../assets/images/icons/tip.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Popover, Radio, Slider } from "antd";
import { useTranslation } from "react-i18next";
import {
  computePostProcessedDimension,
  uploadFile,
  processImage,
  useCreateDefaultMask,
  handleBase64Data,
  createMaskWithCNImage,
  SCALE_LIMIT,
} from "../../../utils/workspaceUtils";
import { CallAwsUrl } from "../../../utils/apiCalls";
import LearnPopups from "../../TipsPopup/TipsPopups";
import Switch from "react-switch";
import { json, useParams } from "react-router-dom";
import Portal from "../../TipsPopup/Portal1";
import HoverTip from "../../TipsPopup/HoverTip";
import { ResponsiveDataContext } from "../../../store/ResponsiveData";
import { UserSocketContext } from "../../../store/UserSocketData";
import { ensureBase64Prefix } from "../../../utils/commonUtils";
import { AIRI_AWS_URL1 } from "../../../utils/api-constant";
import Confirm from "../../confirmModal/confirm";
import { useDispatch, useSelector } from "react-redux";
import { updateRenderToolsetData } from "../../../store/slices/renderToolsetSlice";
import {
  clearEditToolsetData,
  resetMask,
  updateEditToolsetData,
} from "../../../store/slices/editToolsetSlice";
import { updateUpscaleToolsetData } from "../../../store/slices/upscaleToolsetSlice";
import { updateVideoToolsetData } from "../../../store/slices/videoToolsetSlice";
import { updateInspireToolsetData } from "../../../store/slices/inspireToolsetSlice";
import { updateToolsetData } from "../../../store/slices/toolsetSlice";

const atmosphereinfo = {
  en: atmosphereen,
  fr: atmosphereen,
  ch: atmosphereen,
};

function ToolSetComponent({
  setIsButtonDisabled,
  setIsLimitPopup,
  setGlobalErrorMessage,
  handleActiveLearn,
  activeLearnBool,
  setActiveLearnBool,
  setIsUploadScaled,
  setTemplateDisable,
  setIsActiveToolset,
  isActiveToolset,
  controlFocus,
  setControlFocus,
  setRatioLimitPopup,
  setImageUploadEvent,
  setShowModal,
  videoCameraId,
  setVideoCameraId,
}) {
  const { t } = useTranslation();
  const labels = {
    "-4": `${t("upscale_vertical")} +100%`,
    "-3": `${t("upscale_vertical")} +80%`,
    "-2": `${t("upscale_vertical")} +60%`,
    "-1": `${t("upscale_vertical")} +40%`,
    1: `${t("upscale_horizontal")} +40%`,
    2: `${t("upscale_horizontal")} +60%`,
    3: `${t("upscale_horizontal")} +80%`,
    4: `${t("upscale_horizontal")} +100%`,
  };
  const values = {
    "-4": -100,
    "-3": -80,
    "-2": -60,
    "-1": -40,
    1: 40,
    2: 60,
    3: 80,
    4: 100,
  };
  const params = useParams();
  const imgRef = useRef(null);
  const createDefaultMask = useCreateDefaultMask();
  const url = window.location.pathname;
  const lastPart = url.substring(url.lastIndexOf("/") + 1);
  const sectionsRef = useRef(null);

  const plugins = JSON.parse(sessionStorage.getItem("plugin"));
  const isPlugin =
    plugins?.plugin === "/rhino" ||
    plugins?.plugin === "/sketchup" ||
    plugins?.plugin === "/revit";

  const dispatch = useDispatch();
  const designSelector = isPlugin
    ? (state) =>
        state.renderToolset[lastPart]?.[plugins?.port]?.renderToolsetData
    : (state) => state.renderToolset[lastPart]?.renderToolsetData;
  const renderToolsetRedux = useSelector(designSelector);
  const editSelector = isPlugin
    ? (state) => state.editToolset[lastPart]?.[plugins?.port]?.editToolsetData
    : (state) => state.editToolset[lastPart]?.editToolsetData;
  const editToolsetRedux = useSelector(editSelector);
  const upscaleSelector = isPlugin
    ? (state) =>
        state.upscaleToolset[lastPart]?.[plugins?.port]?.upscaleToolsetData
    : (state) => state.upscaleToolset[lastPart]?.upscaleToolsetData;
  const upscaleToolsetRedux = useSelector(upscaleSelector);
  const videoSelector = isPlugin
    ? (state) => state.videoToolset[lastPart]?.[plugins?.port]?.videoToolsetData
    : (state) => state.videoToolset[lastPart]?.videoToolsetData;
  const videoToolsetRedux = useSelector(videoSelector);
  const inspireSelector = (state) =>
    state.inspireToolset[lastPart]?.inspireToolsetData;
  const inspireToolsetRedux = useSelector(inspireSelector);
  const toolsetSelector = (state) => state.toolset[lastPart]?.toolsetData;
  const toolsetRedux = useSelector(toolsetSelector);

  const { responsiveData } = useContext(ResponsiveDataContext);
  const { openUserSocket } = useContext(UserSocketContext);

  const languageActive = localStorage.getItem("language");
  const enhanceLocal = JSON.parse(
    localStorage.getItem(`enhanceData_${lastPart}`)
  );
  const imageCountLocal = localStorage.getItem(
    `imageCount_${lastPart}`,
    JSON.stringify("1")
  );
  let promptLocalData = JSON.parse(
    localStorage.getItem(`promptData_${lastPart}`)
  );
  const activeToolsetLocal = JSON.parse(
    localStorage.getItem(`activeToolset_${lastPart}`)
  );
  const imageTypeData = JSON.parse(
    localStorage.getItem(`imageTypeData_${lastPart}`)
  );

  const [activeSubSection, setActiveSubSection] = useState(
    inspireToolsetRedux?.imageRatioName | 0
  );
  const [activeOrientation, setActiveOrientation] = useState(
    inspireToolsetRedux?.imageOrientationName || 0
  );
  const [activeFidelityLevel, setActiveFidelityLevel] = useState(
    toolsetRedux?.renderControlId || 5
  );
  const [activePreserveMaterial, setActivePreserveMaterial] = useState(
    toolsetRedux?.renderPreserveId || 1
  );
  const [activeImageFidelityLevel, setActiveImageFidelityLevel] = useState(
    toolsetRedux?.editPreserveId || 1
  );
  const [activeMaskFidelityLevel, setActiveMaskFidelityLevel] = useState(
    toolsetRedux?.editControlId || 5
  );
  const [activeExtendFidelityLevel, setActiveExtendFidelityLevel] = useState(
    enhanceLocal?.extendFidelityLevel || 1
  );
  const [extendValue, setExtendValue] = useState(
    `${t("upscale_horizontal")} +40%`
  );
  const [popupVisible, setPopupVisible] = useState(false);
  const [videoResetKey, setVideoResetKey] = useState(0);
  const [inputKey, setInputKey] = useState(0);
  const [imageCount, setImageCount] = useState(1);
  const [isVisualizedMaskUpdating, setIsVisualizedMaskUpdating] =
    useState(false);
  const [selection, setSelection] = useState(
    toolsetRedux?.editToolsetSelection || 1
  );
  const [resolutionType, setResolutionType] = useState(
    toolsetRedux?.imageResolutionId || 1
  );
  const [upscaleType, setUpscaleType] = useState(
    upscaleToolsetRedux?.isUpscaleId || 1
  );
  const [preserve, setPreserve] = useState(
    toolsetRedux?.renderPreserve || false
  );
  const [imageResolution, setImageResolution] = useState(
    toolsetRedux?.imageResolution || false
  );
  const [imageFidelity, setImageFidelity] = useState(
    toolsetRedux?.editPreserve || false
  );
  const [activeLearnBool1, setActiveLearnBool1] = useState([
    { id: 1, boolean: false },
  ]);
  const [enhanceOption, setEnhanceOption] = useState(
    upscaleToolsetRedux?.toolset || 2
  );
  const [percentage, setPercentage] = useState(40);
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [pluginPopup, setPluginPopup] = useState(false);
  const [selectedModal, setSelectedModal] = useState(
    inspireToolsetRedux?.model || 1
  );

  const handleModalSelection = (id) => {
    setSelectedModal(id);
    const data = {
      model: id,
      imageCount: id === 1 ? 1 : 4,
    };
    dispatch(
      updateInspireToolsetData({
        projectId: lastPart,
        data: data,
      })
    );
  };

  useEffect(() => {
    const editData = {
      isFromDesignActivated: toolsetRedux?.editToolsetSelection == 2,
    };
    dispatch(
      updateEditToolsetData({
        projectId: lastPart,
        data: editData,
        port: plugins?.port,
        isPlugin: isPlugin,
      })
    );
    setSelection(toolsetRedux?.editToolsetSelection);
  }, [toolsetRedux?.editToolsetSelection]);

  useEffect(() => {
    if (!upscaleToolsetRedux) {
      const upscaleData = {
        toolset: 2,
        isDesign: true,
        isUpscaleId: 1,
      };
      dispatch(
        updateUpscaleToolsetData({
          projectId: lastPart,
          data: upscaleData,
          port: plugins?.port,
          isPlugin: isPlugin,
        })
      );
    }

    if (!inspireToolsetRedux) {
      const initialData = {
        orientation: 0,
        orientationName: "hori",
        imageRatio: 0,
        imageRatioName: "fourthree",
        imageCount: 1,
        model: 1,
      };
      dispatch(
        updateInspireToolsetData({
          projectId: lastPart,
          data: initialData,
        })
      );
    }

    if (!toolsetRedux) {
      const initialData = {
        toolset: 1,
        renderControlId: 5,
        renderPreserve: false,
        renderPreserveId: 1,
        imageResolution: false,
        imageResolutionId: 1,
        editToolsetSelection: 1,
        editControlId: 5,
        editPreserve: false,
        editPreserveId: 1,
      };
      dispatch(
        updateToolsetData({
          projectId: lastPart,
          data: initialData,
        })
      );
    }

    const enhance = JSON.parse(localStorage.getItem(`enhanceData_${lastPart}`));
    if (!enhance) {
      const data = {
        extendFidelityLevel: 1,
      };
      localStorage.setItem(`enhanceData_${lastPart}`, JSON.stringify(data));
    }
  }, []);

  useEffect(() => {
    let check = JSON.parse(
      localStorage.getItem(`image-resolution_${lastPart}`)
    );
    if (check) {
      if (check?.id !== resolutionType) {
        setResolutionType(check?.id);
        if (
          check?.id === 2 &&
          (imageCountLocal === "3" ||
            imageCountLocal === "4" ||
            imageCountLocal === "2")
        ) {
          setImageCount(1);
          localStorage.setItem(`imageCount_${lastPart}`, JSON.stringify(1));
        }
      }
    }
  }, [localStorage.getItem(`image-resolution_${lastPart}`)]);

  function base64ToBlob(base64, mimeType) {
    const byteCharacters = atob(base64.split(",")[1]); // Decode base64 string after the comma
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  }

  const handleFileChangeWithToolsetInput = (toolset) => {
    return (event) => {
      setInputKey((prevKey) => prevKey + 1);
      let imageType = "base-image";
      let bool = false;
      if (toolset === 1) {
        imageType = "cn-image";
        bool = false;
      } else if (toolset === 2) {
        imageType = "base-image";
        bool = false;
      } else if (toolset === 3) {
        imageType = "base-image";
        bool = false;
      } else if (toolset === 4) {
        imageType = "base-image";
        bool = true;
      } else if (toolset === 10) {
        imageType = "cn-image";
        bool = false;
      }
      localStorage.setItem(`imageType_${lastPart}`, JSON.stringify(imageType));
      const imageUrl = handleFileChange(toolset, event);
      if (imageUrl) {
        openUserSocket();
        updatePreUploadState(toolset, imageUrl);
        setTimeout(() => {
          handleBaseImageData(toolset, imageUrl, bool);
        }, 0);
      }
    };
  };

  const handleBaseImageData = (
    toolset,
    imageUrl,
    bool = false,
    CNImageBlob = null,
    CNImageUrl = null,
    initialCNImageUrl = null
  ) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = async function () {
      let resizedWidth, resizedHeight, scale;
      let finalResizedImageUrl = processImage(
        img,
        computePostProcessedDimension,
        toolset,
        (dimensions) => {
          resizedWidth = dimensions.resizedWidth;
          resizedHeight = dimensions.resizedHeight;
          scale = dimensions.scale;
        }
      );

      const aspectRatio = resizedWidth / resizedHeight;

      try {
        const response = await fetch(finalResizedImageUrl);
        const blob = await response.blob();

        // Check if the file size exceeds 10MB
        if (toolset === 4 && blob.size > 10 * 1024 * 1024) {
          // 10MB in bytes
          setIsButtonDisabled(false);
          setRatioLimitPopup({
            heading: t("size_limit_heading"),
            text: t("size_limit_content"),
            bool: true,
          });
          handleRatioLimitPopup(toolset);
          return;
        }
      } catch (error) {
        console.error("Error fetching the processed image:", error);
      }

      // Check if the image dimensions are smaller than 300×300
      if (toolset === 4 && (resizedWidth < 300 || resizedHeight < 300)) {
        setIsButtonDisabled(false);
        setRatioLimitPopup({
          heading: t("pixel_limit_heading"),
          text: t("pixel_limit_content"),
          bool: true,
        });
        handleRatioLimitPopup(toolset);
        return;
      }

      // Check if the cropped image ratio exceeds 5:2 (2.5)
      if (toolset === 4) {
        if (aspectRatio >= 2.5 || aspectRatio <= 0.4) {
          setIsButtonDisabled(false);
          setRatioLimitPopup({
            heading: t("ratio_limit_heading1"),
            text: t("ratio_limit_content1"),
            bool: true,
          });
          handleRatioLimitPopup(toolset);
          return;
        }
      }
      // check the cropped image ratio not in between 3:1 and 1:3 return showing alert
      else if (aspectRatio >= 3 || aspectRatio <= 0.33) {
        setIsButtonDisabled(false);
        setRatioLimitPopup({
          heading: t("ratio_limit_heading"),
          text: t("ratio_limit_content"),
          bool: true,
        });
        handleRatioLimitPopup(toolset);
        return;
      }

      if (scale >= SCALE_LIMIT) {
        setIsUploadScaled(true);
      }

      const proceedNextStep = () => {
        fetch(finalResizedImageUrl)
          .then((res) => res.blob())
          .then((blob) => {
            const resizedImageFile = new File([blob], "resized_image.jpeg", {
              type: "image/jpeg",
            });
            uploadFile(
              "base-image",
              bool,
              resizedImageFile,
              async (data) => {
                switch (toolset) {
                  case 0:
                  case 1:
                    const renderData = {
                      feedDesignBase64: data,
                      uploadedBaseImageUrl: finalResizedImageUrl,
                    };
                    dispatch(
                      updateRenderToolsetData({
                        projectId: lastPart,
                        data: renderData,
                        port: plugins?.port,
                        isPlugin: isPlugin,
                      })
                    );
                    break;
                  case 2:
                    defaultMask = await maskCreationPromise;
                    defaultCNMask = await CNmaskCreationPromise;
                    dispatch(
                      resetMask({
                        projectId: lastPart,
                        mode: 0,
                        port: plugins?.port,
                        isPlugin: isPlugin,
                      })
                    );
                    const editData = {
                      isFromDesignActivated: selection == 2,

                      uploadedBaseImage: finalResizedImageUrl,
                      feedImageBase64: data,

                      maskImageData: defaultMask.visualizedMaskBase64,
                      maskImageBase64: defaultMask.actualMaskData,

                      cnImage: defaultCNMask.finalVisualizedCNImageUrl, // update since new render UI
                      cnImageBase64: CNImageUrl ? { path: CNImageUrl } : data, //if there was a CN in the image, use it, else same as base image

                      originalCnImage: CNImageBlob
                        ? CNImageBlob
                        : finalResizedImageUrl,
                      originalCnImageBase64: CNImageUrl
                        ? { path: initialCNImageUrl || CNImageUrl } //this initialCNImageUrl is not affected by change of CNImage, only use if exists TODO:check if the updates fucks this up
                        : data, //but original should be initial cn if it exists, pass just for future use
                    };
                    dispatch(
                      updateEditToolsetData({
                        projectId: lastPart,
                        data: editData,
                        port: plugins?.port,
                        isPlugin: isPlugin,
                      })
                    );
                    break;
                  case 3:
                    const upscaleData = {
                      feedUpBase64: data,
                      isUpScaleURLImgUrl: finalResizedImageUrl,
                    };
                    dispatch(
                      updateUpscaleToolsetData({
                        projectId: lastPart,
                        data: upscaleData,
                        port: plugins?.port,
                        isPlugin: isPlugin,
                      })
                    );
                    break;
                  case 4:
                    const videoData = {
                      feedDesignBase64: data,
                      uploadedBaseImageUrl: finalResizedImageUrl,
                      id: 1,
                      bool: true,
                    };
                    setVideoCameraId({
                      id: 1,
                      bool: true,
                    });
                    dispatch(
                      updateVideoToolsetData({
                        projectId: lastPart,
                        data: videoData,
                        port: plugins?.port,
                        isPlugin: isPlugin,
                      })
                    );
                    break;
                  case 10:
                    const editData1 = {
                      isFromDesignActivated: selection == 2,

                      // uploadedBaseImage:
                      // feedImageBase64:  no need because we don't want to change the base image

                      maskImageData: defaultMask.visualizedMaskBase64, // update what the user sees
                      // maskImageBase64: no need to update because we are reusing from case 2, which is always a step before case 10

                      cnImage: defaultMask.finalVisualizedCNImageUrl,
                      cnImageBase64: data,

                      originalCnImage: finalResizedImageUrl,
                      originalCnImageBase64: data,
                      // maskImageBase64: defaultMask.actualMaskData,//no need because this always happens after toolset == 2, meaning there will always be a default mask
                    };
                    dispatch(
                      updateEditToolsetData({
                        projectId: lastPart,
                        data: editData1,
                        port: plugins?.port,
                        isPlugin: isPlugin,
                      })
                    );
                    break;
                  default:
                    break;
                }
              },
              () => setIsButtonDisabled(false),
              (error) => {
                handleRateLimitPopup(error);
                switch (toolset) {
                  case 0:
                  case 1:
                    const renderUpdate = {
                      feedDesignBase64: null,
                      uploadedBaseImageUrl: null,
                    };
                    dispatch(
                      updateRenderToolsetData({
                        projectId: lastPart,
                        data: renderUpdate,
                        port: plugins?.port,
                        isPlugin: isPlugin,
                      })
                    );
                    break;
                  case 2:
                    defaultMask = null;
                    dispatch(
                      resetMask({
                        projectId: lastPart,
                        mode: 0,
                        port: plugins?.port,
                        isPlugin: isPlugin,
                      })
                    );
                    const editData = {
                      isFromDesignActivated: selection == 2,
                      feedImageBase64: null,
                      uploadedBaseImage: null,
                      maskImageData: null,
                      maskImageBase64: null,
                      cnImageBase64: null,
                      originalCnImageBase64: null,
                    };
                    dispatch(
                      updateEditToolsetData({
                        projectId: lastPart,
                        data: editData,
                        port: plugins?.port,
                        isPlugin: isPlugin,
                      })
                    );
                    break;
                  case 3:
                    const upscaleData = {
                      feedUpBase64: null,
                      isUpScaleURLImgUrl: null,
                    };
                    dispatch(
                      updateUpscaleToolsetData({
                        projectId: lastPart,
                        data: upscaleData,
                        port: plugins?.port,
                        isPlugin: isPlugin,
                      })
                    );
                    break;
                  case 4:
                    const videoData = {
                      feedDesignBase64: null,
                      uploadedBaseImageUrl: null,
                    };
                    dispatch(
                      updateVideoToolsetData({
                        projectId: lastPart,
                        data: videoData,
                        port: plugins?.port,
                        isPlugin: isPlugin,
                      })
                    );
                    break;
                  case 10:
                    const editData1 = {
                      isFromDesignActivated: true,
                      cnImage: null,
                      cnImageBase64: null,
                      originalCnImageBase64: null,
                      maskImageData: null,
                    };
                    dispatch(
                      updateEditToolsetData({
                        projectId: lastPart,
                        data: editData1,
                        port: plugins?.port,
                        isPlugin: isPlugin,
                      })
                    );
                    break;
                  default:
                    break;
                }
              }
            );
          })
          .catch((error) => {
            console.error("Error converting Data URL to File:", error);
          });
      };

      if (toolset === 2) {
        resetMask(0);
      }

      let defaultMask = null;
      let defaultCNMask = null;
      //if its first time upload, we shall just create a default mask, where both images are the same size at least
      const maskCreationPromise =
        toolset === 2
          ? createDefaultMask(
              finalResizedImageUrl,
              resizedWidth,
              resizedHeight,
              CNImageBlob,
              selection === 2
            )
          : null;

      const CNmaskCreationPromise = //todo: figure this out
        toolset === 2
          ? createMaskWithCNImage(
              finalResizedImageUrl,
              resizedWidth,
              resizedHeight,
              CNImageBlob || finalResizedImageUrl,
              resizedWidth,
              resizedHeight,
              true //legacy code
            )
          : null;

      //if its later upload, sizes of the images might not be the same, needs heavy image manipulation therefore need to go through this way

      if (toolset === 10) {
        createMaskWithCNImage(
          editToolsetRedux.uploadedBaseImage,
          editToolsetRedux.feedImageBase64.width,
          editToolsetRedux.feedImageBase64.height,
          finalResizedImageUrl,
          resizedWidth,
          resizedHeight,
          editToolsetRedux.isFromDesignActivated,
          editToolsetRedux
        )
          .then((result) => {
            defaultMask = result;
            finalResizedImageUrl = defaultMask.CNImageFittedInsideBG;
            proceedNextStep();
          })
          .catch((error) => {
            console.error("Error in creating mask with CN image:", error);
          });
      } else {
        proceedNextStep();
      }
    };
  };

  const handleRateLimitPopup = (error) => {
    const renderData = { uploadedBaseImageUrl: null };
    dispatch(
      updateRenderToolsetData({
        projectId: lastPart,
        data: renderData,
        port: plugins?.port,
        isPlugin: isPlugin,
      })
    );
    const editData = { uploadedBaseImage: null };
    dispatch(
      updateEditToolsetData({
        projectId: lastPart,
        data: editData,
        port: plugins?.port,
        isPlugin: isPlugin,
      })
    );
    const upscaleData = { isUpScaleURLImgUrl: null };
    dispatch(
      updateUpscaleToolsetData({
        projectId: lastPart,
        data: upscaleData,
        port: plugins?.port,
        isPlugin: isPlugin,
      })
    );
    const videoData = { uploadedBaseImageUrl: null };
    dispatch(
      updateVideoToolsetData({
        projectId: lastPart,
        data: videoData,
        port: plugins?.port,
        isPlugin: isPlugin,
      })
    );
    setIsLimitPopup(true);
    setGlobalErrorMessage(error.message);
  };

  const handleRatioLimitPopup = (toolset) => {
    switch (toolset) {
      case 0:
      case 1:
        const renderUpdate = {
          uploadedBaseImageUrl: null,
        };
        dispatch(
          updateRenderToolsetData({
            projectId: lastPart,
            data: renderUpdate,
            port: plugins?.port,
            isPlugin: isPlugin,
          })
        );
        break;
      case 2:
        const editData = { uploadedBaseImage: null };
        dispatch(
          updateEditToolsetData({
            projectId: lastPart,
            data: editData,
            port: plugins?.port,
            isPlugin: isPlugin,
          })
        );
        break;
      case 10:
        const editData1 = {
          isFromDesignActivated: false,
          cnImage: null,
          cnImageBase64: null,
          originalCnImage: null,
          originalCnImageBase64: null,
        };
        dispatch(
          updateEditToolsetData({
            projectId: lastPart,
            data: editData,
            port: plugins?.port,
            isPlugin: isPlugin,
          })
        );
        break;
      case 3:
        const upscaleData = { isUpScaleURLImgUrl: null };
        dispatch(
          updateUpscaleToolsetData({
            projectId: lastPart,
            data: upscaleData,
            port: plugins?.port,
            isPlugin: isPlugin,
          })
        );
        break;
      case 4:
        const videoData = { uploadedBaseImageUrl: null };
        dispatch(
          updateVideoToolsetData({
            projectId: lastPart,
            data: videoData,
            port: plugins?.port,
            isPlugin: isPlugin,
          })
        );
        break;
      default:
        break;
    }
  };

  const updatePreUploadState = (toolset, uploadedUrl) => {
    switch (toolset) {
      case 0:
      case 1:
        const renderUpdate = {
          feedDesignBase64: null,
          uploadedBaseImageUrl: uploadedUrl,
        };
        dispatch(
          updateRenderToolsetData({
            projectId: lastPart,
            data: renderUpdate,
            port: plugins?.port,
            isPlugin: isPlugin,
          })
        );
        break;
      case 2:
        const editData = {
          uploadedBaseImage: uploadedUrl,
          feedImageBase64: null,
          maskImageData: uploadedUrl,
          maskImageBase64: null,
          isFromDesignActivated: false,
          cnImage: selection === 2 ? uploadedUrl : null,
          cnImageBase64: null,
          originalCnImage: null,
          originalCnImageBase64: null,
        };
        dispatch(
          updateEditToolsetData({
            projectId: lastPart,
            data: editData,
            port: plugins?.port,
            isPlugin: isPlugin,
          })
        );
        break;
      case 3:
        const upscaleData = {
          isUpScaleURLImgUrl: uploadedUrl,
          feedUpBase64: null,
        };
        dispatch(
          updateUpscaleToolsetData({
            projectId: lastPart,
            data: upscaleData,
            port: plugins?.port,
            isPlugin: isPlugin,
          })
        );
        break;
      case 4:
        const videoData = {
          uploadedBaseImageUrl: uploadedUrl,
          feedDesignBase64: null,
        };
        dispatch(
          updateVideoToolsetData({
            projectId: lastPart,
            data: videoData,
            port: plugins?.port,
            isPlugin: isPlugin,
          })
        );
        break;
      case 10: //for controlnet inpainting
        const editData1 = {
          isFromDesignActivated: true,
          cnImage: uploadedUrl,
          cnImageBase64: null,
          originalCnImage: uploadedUrl,
          originalCnImageBase64: null,
          // maskImageBase64: null,
        };
        dispatch(
          updateEditToolsetData({
            projectId: lastPart,
            data: editData1,
            port: plugins?.port,
            isPlugin: isPlugin,
          })
        );
        break;
      default:
        break;
    }
  };

  const handleFileChange = (toolset, event) => {
    if (!event) {
      console.log(event);
      return null;
    }
    const files = event.target.files || event.dataTransfer.files;
    if (!files || files.length === 0) {
      console.log("No files selected");
      return null;
    }
    setIsButtonDisabled(true);
    const file = files[0];
    if (!file) {
      setIsButtonDisabled(false);
      return null;
    }

    const validTypes = ["image/jpeg", "image/png", "image/jpg", "image/webp"];
    if (validTypes.includes(file.type)) {
      const imageUrl = URL.createObjectURL(file);
      return imageUrl;
    } else {
      alert(t("image_alart_format"));
      return null;
    }
  };

  const handleSliderChange = (value) => {
    if (value === undefined) return;

    const data = { ...toolsetRedux, renderControlId: value };

    setActiveFidelityLevel(value);

    dispatch(
      updateToolsetData({
        projectId: lastPart,
        data: data,
      })
    );
  };

  const handlePreserveMaterial = (value) => {
    if (value === undefined) return;

    const data = { ...toolsetRedux, renderPreserveId: value };

    setActivePreserveMaterial(value);

    dispatch(
      updateToolsetData({
        projectId: lastPart,
        data: data,
      })
    );
  };

  const handleImageSliderChange = (value) => {
    const data = { ...toolsetRedux, editPreserveId: value };

    setActiveImageFidelityLevel(value);

    dispatch(
      updateToolsetData({
        projectId: lastPart,
        data: data,
      })
    );
  };

  const handleMaskSliderChange = (value) => {
    const data = { ...toolsetRedux, editControlId: value };

    setActiveMaskFidelityLevel(value);

    dispatch(
      updateToolsetData({
        projectId: lastPart,
        data: data,
      })
    );
  };

  const parsedValues = Object.fromEntries(
    Object.entries(values).map(([key, val]) => [Number(key), val])
  );

  const handleExtendSliderChange = (value) => {
    if (typeof value !== "number") return;
    let check = JSON.parse(localStorage.getItem(`enhanceData_${lastPart}`));
    if (check?.extendFidelityLevel) {
      if (value) {
        check.extendFidelityLevel = value;
        setActiveExtendFidelityLevel(value);
        setExtendValue(labels[value]);
        setPercentage(parsedValues[value]);
      }
    } else if (check?.extendFidelityLevel === 0) {
      if (value) {
        check.extendFidelityLevel = value;
        setActiveExtendFidelityLevel(value);
        setExtendValue(labels[value]);
        setPercentage(parsedValues[value]);
      }
    }
    localStorage.setItem(`enhanceData_${lastPart}`, JSON.stringify(check));
  };

  useEffect(() => {
    if (enhanceLocal?.extendFidelityLevel) {
      setActiveExtendFidelityLevel(enhanceLocal?.extendFidelityLevel);
      setExtendValue(labels[enhanceLocal?.extendFidelityLevel]);
      setPercentage(values[enhanceLocal?.extendFidelityLevel]);
    }
  }, [enhanceLocal?.extendFidelityLevel]);

  const handleOrientation = (active) => {
    let orientation;
    let orientationName;

    if (active === 0) {
      orientation = 0;
      orientationName = "hori";
      setActiveOrientation(0);
    } else if (active === 1) {
      orientation = 1;
      orientationName = "verti";
      setActiveOrientation(1);
    }
    const data = {
      orientation: orientation,
      orientationName: orientationName,
    };
    dispatch(
      updateInspireToolsetData({
        projectId: lastPart,
        data: data,
      })
    );
  };

  const handleClick = (active) => {
    let imageRatio;
    let imageRatioName;

    if (active === 1) {
      imageRatio = 1;
      imageRatioName = "threetwo";
      setActiveSubSection(1);
    } else if (active === 3) {
      imageRatio = 3;
      imageRatioName = "sixteennine";
      setActiveSubSection(3);
    } else if (active === 2) {
      imageRatio = 2;
      imageRatioName = "oneone";
      setActiveSubSection(2);
    } else if (active === 0) {
      imageRatio = 0;
      imageRatioName = "fourthree";
      setActiveSubSection(0);
    }
    const data = {
      imageRatio: imageRatio,
      imageRatioName: imageRatioName,
    };
    dispatch(
      updateInspireToolsetData({
        projectId: lastPart,
        data: data,
      })
    );
  };

  const handleToolsetClick = (id) => {
    if (id === 1 && toolsetRedux?.imageResolutionId === 2) {
      handleImageCount(1);
    }
    setIsActiveToolset(id);
    if (toolsetRedux?.toolset === 3 && id !== 4) {
      const check1 = {
        isTemplate: true,
        isAtmosphere: false,
        isPrompt: true,
        isPrivate: false,
      };
      localStorage.setItem(`isTemplate_${lastPart}`, JSON.stringify(check1));
    } else if (id !== 3 && toolsetRedux?.toolset === 4) {
      const check1 = {
        isTemplate: true,
        isAtmosphere: false,
        isPrompt: true,
        isPrivate: false,
      };
      localStorage.setItem(`isTemplate_${lastPart}`, JSON.stringify(check1));
    } else if (id === 3 && upscaleToolsetRedux?.toolset === 1) {
      const check1 = {
        isTemplate: false,
        isAtmosphere: false,
        isPrompt: false,
        isPrivate: false,
      };
      localStorage.setItem(`isTemplate_${lastPart}`, JSON.stringify(check1));
    } else if (id === 4) {
      const check1 = {
        isTemplate: false,
        isAtmosphere: false,
        isPrompt: true,
        isPrivate: false,
      };
      localStorage.setItem(`isTemplate_${lastPart}`, JSON.stringify(check1));
    }
    if (id === 3) {
      const val = true;
      setTemplateDisable(val);
      setUpscaleType(1);
      const videoData = {
        toolset: 2,
        isDesign: val,
        isUpscaleId: 1,
      };
      dispatch(
        updateUpscaleToolsetData({
          projectId: lastPart,
          data: videoData,
          port: plugins?.port,
          isPlugin: isPlugin,
        })
      );
    } else if (id === 2) {
      setTemplateDisable(selection === 3 ? true : false);
      const videoData = {
        isDesign: selection === 3 ? true : false,
      };
      dispatch(
        updateUpscaleToolsetData({
          projectId: lastPart,
          data: videoData,
          port: plugins?.port,
          isPlugin: isPlugin,
        })
      );
    } else {
      setTemplateDisable(false);
      const videoData = {
        isDesign: false,
      };
      dispatch(
        updateUpscaleToolsetData({
          projectId: lastPart,
          data: videoData,
          port: plugins?.port,
          isPlugin: isPlugin,
        })
      );
    }
    if(toolsetRedux?.toolset === 0 && id !== 0) {
      const inspireToolsetData = {
        imageCount: 1,
        model: 1,
      };
      dispatch(
        updateInspireToolsetData({
          projectId: lastPart,
          data: inspireToolsetData,
        })
      );
    }
    const newToolsetData = {
      toolset: id,
    };
    dispatch(
      updateToolsetData({
        projectId: lastPart,
        data: newToolsetData,
      })
    );
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event, toolset = null) => {
    event.preventDefault();
    event.stopPropagation();
    let bool = false;
    let imageType = "base-image";
    if (toolset === 1) {
      imageType = "base-image";
    } else if (toolset === 2) {
      imageType = "base-image";
    } else if (toolset === 3) {
      imageType = "base-image";
    } else if (toolset === 4) {
      imageType = "base-image";
      bool = true;
    } else if (toolset === 10) {
      imageType = "cn-image";
    }
    localStorage.setItem(`imageType_${lastPart}`, JSON.stringify(imageType));
    const selectedToolset =
      toolset !== null && toolset !== undefined ? toolset : isActiveToolset;

    if (toolset === 10 && !editToolsetRedux?.feedImageBase64) return; // will cause an error if update earlier, try to fix this later

    if (event.dataTransfer.files?.length === 0) {
      openUserSocket();
      const media = JSON.parse(event.dataTransfer.getData("media"));
      const updatedLink =
        media?.toolset === "video" ? media?.baseImage : media?.url;
      updatePreUploadState(selectedToolset, updatedLink); // clear everything
      if (toolset === 10) {
        CallAwsUrl((res) => {
          //for CN image
          const editData = {
            cnImage: handleBase64Data(res.data),
            originalCnImage: handleBase64Data(res.data),
          };
          dispatch(
            updateEditToolsetData({
              projectId: lastPart,
              data: editData,
              port: plugins?.port,
              isPlugin: isPlugin,
            })
          );
          //now handle CN image and upload
          CallAwsUrl((res) => {
            handleBaseImageData(
              selectedToolset,
              handleBase64Data(res.data),
              bool
            );
          }, updatedLink);
        }, updatedLink);
      } else if (media?.toolset === "design") {
        //update ahead of time to ensure user will not need to wait, below is in url, so there still is a bit of delay
        CallAwsUrl((res) => {
          //for CN image
          const CNImageBlob = handleBase64Data(res.data);
          const editData = {
            cnImage: CNImageBlob,
            originalCnImage: CNImageBlob,
          };
          dispatch(
            updateEditToolsetData({
              projectId: lastPart,
              data: editData,
              port: plugins?.port,
              isPlugin: isPlugin,
            })
          );
          //now handle base image and upload
          CallAwsUrl((res) => {
            handleBaseImageData(
              selectedToolset,
              handleBase64Data(res.data),
              bool,
              CNImageBlob,
              media?.baseImage
            );
          }, updatedLink);
        }, media?.baseImage);
      } else if (media?.toolset === "image" && media?.originalImage) {
        CallAwsUrl((res) => {
          //for CN image
          const CNImageBlob = handleBase64Data(res.data);
          const editData = {
            cnImage: CNImageBlob,
            originalCnImage: CNImageBlob,
          };
          dispatch(
            updateEditToolsetData({
              projectId: lastPart,
              data: editData,
              port: plugins?.port,
              isPlugin: isPlugin,
            })
          );
          //now handle base image and upload
          CallAwsUrl((res) => {
            handleBaseImageData(
              selectedToolset,
              handleBase64Data(res.data),
              bool,
              CNImageBlob,
              media?.originalImage,
              media?.initialCNImage
            ); //initialCNImage should be the initial else original if it does not exist
          }, updatedLink);
        }, media?.originalImage);
      } else {
        //just handle base image and upload
        CallAwsUrl((res) => {
          handleBaseImageData(
            selectedToolset,
            handleBase64Data(res.data),
            bool
          );
        }, updatedLink);
      }
    } else if (event.dataTransfer.files?.length === 1) {
      openUserSocket();
      const imageUrl = handleFileChange(selectedToolset, event);
      if (imageUrl) {
        updatePreUploadState(selectedToolset, imageUrl);
        handleBaseImageData(selectedToolset, imageUrl, bool);
      }
    }
  };

  const handleOpenMaskPopup = () => {
    if (
      (editToolsetRedux?.isFromDesignActivated &&
        editToolsetRedux?.feedImageBase64 &&
        editToolsetRedux?.cnImageBase64 &&
        editToolsetRedux?.maskImageBase64) ||
      (!editToolsetRedux?.isFromDesignActivated &&
        editToolsetRedux?.feedImageBase64 &&
        editToolsetRedux?.maskImageBase64)
    ) {
      const editData = {
        showPopUpForBaseImage: true,
      };
      dispatch(
        updateEditToolsetData({
          projectId: lastPart,
          data: editData,
          port: plugins?.port,
          isPlugin: isPlugin,
        })
      );
    }
  };

  useEffect(() => {
    if (!popupVisible) {
      setVideoResetKey((prevKey) => prevKey + 1);
    }
  }, [popupVisible]);

  useEffect(() => {
    if (!editToolsetRedux?.uploadedBaseImage) {
      setIsVisualizedMaskUpdating(false);
    }
  }, [editToolsetRedux?.uploadedBaseImage]);

  const handleIsDesignActivatedToggleChange = (mode) => {
    // console.log('We are in mode:', mode);
    if (
      editToolsetRedux?.uploadedBaseImage &&
      editToolsetRedux?.feedImageBase64 &&
      editToolsetRedux?.cnImage
    ) {
      // console.log('All required data is present.');
      setIsVisualizedMaskUpdating(true);
      // console.log('Mask updating state set to true.');
      setTimeout(() => {
        //force a rerender
        createMaskWithCNImage(
          editToolsetRedux.uploadedBaseImage,
          editToolsetRedux.feedImageBase64.width,
          editToolsetRedux.feedImageBase64.height,
          editToolsetRedux.cnImage,
          editToolsetRedux.feedImageBase64.width, // Verify size correctness
          editToolsetRedux.feedImageBase64.height,
          mode,
          editToolsetRedux
        )
          .then((result) => {
            // console.log("Image processing finished, updating state.");
            setIsVisualizedMaskUpdating(false);
            const editData = {
              maskImageData: result.visualizedMaskBase64,
              isFromDesignActivated: mode,
            };
            dispatch(
              updateEditToolsetData({
                projectId: lastPart,
                data: editData,
                port: plugins?.port,
                isPlugin: isPlugin,
              })
            );
            // console.log('Mask updating state set to false.');
          })
          .catch((error) => {
            console.log("Error during image processing:", error);
            setIsVisualizedMaskUpdating(false);
          });
      }, 0);
    } else {
      // console.log('Not all data is available to process images.');
      const editData = {
        isFromDesignActivated: mode,
      };
      dispatch(
        updateEditToolsetData({
          projectId: lastPart,
          data: editData,
          port: plugins?.port,
          isPlugin: isPlugin,
        })
      );
    }
  };

  const handleImageCount = (id) => {
    setImageCount(id);
    const data = {
      imageCount: id,
    };
    dispatch(
      updateInspireToolsetData({
        projectId: lastPart,
        data: data,
      })
    );
  };

  const textTooltip = t("text_to_image_content")?.split("/n");
  const designTooltip = t("image_to_image_content")?.split("/n");
  const imageTooltip = t("inpainting_content")?.split("/n");
  const enhanceTooltip = t("expand_pixels_content")?.split("/n");
  const videoTooltip = t("first_frame_tip")?.split("/n");

  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({
    left: 1700.90625,
    top: 171.578125,
  });

  const showTooltip = (no, event) => {
    if (sectionsRef.current) {
      const rect = sectionsRef.current.getBoundingClientRect();
      const left = rect.left + window.scrollX;
      const top = rect.top + window.scrollY;
      setTooltipContent(no);
      setTooltipPosition({ left, top });
    }
  };

  const hideTooltip = () => {
    setTooltipContent(0);
  };

  const handleActiveSelection = (e) => {
    const value = e;
    if (value === toolsetRedux?.editToolsetSelection) return;
    if (checkIfSelectionIsDisabled()) return;
    handleIsDesignActivatedToggleChange(value == 2);
    const editData = {
      isFromDesignActivated: value == 2,
    };
    dispatch(
      updateEditToolsetData({
        projectId: lastPart,
        data: editData,
        port: plugins?.port,
        isPlugin: isPlugin,
      })
    );
    const data = { ...toolsetRedux, editToolsetSelection: value };

    setSelection(value);
    dispatch(
      updateToolsetData({
        projectId: lastPart,
        data: data,
      })
    );
    if (value === 2) {
      const data = {
        ...toolsetRedux,
        editPreserveId: 1,
        editPreserve: false,
        editToolsetSelection: value,
      };

      setImageFidelity(false);

      dispatch(
        updateToolsetData({
          projectId: lastPart,
          data: data,
        })
      );
    }
    const val = value !== 3 ? false : true;
    setTemplateDisable(val);
    const videoData = {
      isDesign: val,
    };
    dispatch(
      updateUpscaleToolsetData({
        projectId: lastPart,
        data: videoData,
        port: plugins?.port,
        isPlugin: isPlugin,
      })
    );
  };

  const checkIfSelectionIsDisabled = () => {
    if (
      (editToolsetRedux?.maskImageData &&
        (!editToolsetRedux?.maskImageBase64 ||
          !editToolsetRedux?.feedImageBase64)) ||
      (editToolsetRedux?.cnImage &&
        (!editToolsetRedux?.cnImageBase64 ||
          !editToolsetRedux?.feedImageBase64)) ||
      isVisualizedMaskUpdating
    )
      return true;
    else return false;
  };

  const handleEnhanceSelection = (e) => {
    const value = e;
    if (value === upscaleToolsetRedux?.toolset) return;
    setEnhanceOption(value);
    const videoData = {
      toolset: value,
      isDesign: value !== 2 ? false : true,
    };
    setTemplateDisable(value !== 2 ? false : true);
    dispatch(
      updateUpscaleToolsetData({
        projectId: lastPart,
        data: videoData,
        port: plugins?.port,
        isPlugin: isPlugin,
      })
    );
    if (value === 3) {
      const check = {
        isTemplate: true,
        isAtmosphere: false,
        isPrompt: true,
        isPrivate: false,
      };
      localStorage.setItem(`isTemplate_${lastPart}`, JSON.stringify(check));
    }
  };

  const handleResolutionType = (id) => {
    const data = { ...toolsetRedux, imageResolutionId: id };

    setResolutionType(id);

    dispatch(
      updateToolsetData({
        projectId: lastPart,
        data: data,
      })
    );

    if (inspireToolsetRedux?.imageCount !== 1 && id !== 1) {
      const data1 = { ...inspireToolsetRedux, imageCount: 1 };
      setImageCount(1);

      dispatch(
        updateInspireToolsetData({
          projectId: lastPart,
          data: data1,
        })
      );
    }
  };

  const handleUpscaleType = (value) => {
    const videoData = {
      isUpscaleId: value,
    };
    dispatch(
      updateUpscaleToolsetData({
        projectId: lastPart,
        data: videoData,
        port: plugins?.port,
        isPlugin: isPlugin,
      })
    );
    setUpscaleType(value);
  };

  const handleDesignPreservematerial = () => {
    const value = !toolsetRedux?.renderPreserve || !preserve;
    const data = {
      ...toolsetRedux,
      renderPreserve: value,
      renderPreserveId: 1,
    };

    setPreserve(value);

    dispatch(
      updateToolsetData({
        projectId: lastPart,
        data: data,
      })
    );
  };

  const handleImageResolution = () => {
    const value = !toolsetRedux?.imageResolution || !imageResolution;
    const data = {
      ...toolsetRedux,
      imageResolution: value,
      imageResolutionId: 1,
    };

    setImageResolution(value);

    dispatch(
      updateToolsetData({
        projectId: lastPart,
        data: data,
      })
    );
  };

  const handleImageFidelity = () => {
    const value = !toolsetRedux?.editPreserve || !imageFidelity;
    const data = { ...toolsetRedux, editPreserve: value, editPreserveId: 1 };
    setActiveImageFidelityLevel(1);
    setImageFidelity(value);

    dispatch(
      updateToolsetData({
        projectId: lastPart,
        data: data,
      })
    );
  };

  const handleCameraMovement = () => {
    const val = videoToolsetRedux?.bool === true;
    setVideoCameraId({
      id: val ? 1 : videoToolsetRedux?.id,
      bool: !videoToolsetRedux?.bool,
    });
    const videoData = {
      id: val ? 1 : videoToolsetRedux?.id,
      bool: !videoToolsetRedux?.bool,
    };
    dispatch(
      updateVideoToolsetData({
        projectId: lastPart,
        data: videoData,
        port: plugins?.port,
        isPlugin: isPlugin,
      })
    );
  };

  const popuptimer = useRef(null);

  const handleMouseEnter = (id, event, toppos = -40) => {
    event.preventDefault();
    event.stopPropagation();
    const newData1 = {
      id: activeLearnBool?.id,
      boolean: false,
      position: activeLearnBool?.position,
    };
    setActiveLearnBool(newData1);

    const headingDiv = event.currentTarget.parentNode;
    const rect = headingDiv.getBoundingClientRect();
    const position = {
      left: rect.left + window.scrollX,
      top: rect.bottom + window.scrollY + toppos,
    };
    const newData = {
      id: id,
      boolean: true,
      position: position,
    };
    clearTimeout(popuptimer.current);
    setActiveLearnBool1(newData);
  };

  const handleMouseLeave = (id, event) => {
    event.preventDefault();
    event.stopPropagation();
    const headingDiv = event.currentTarget.parentNode;
    const rect = headingDiv.getBoundingClientRect();
    const position = {
      left: rect.left + window.scrollX,
      top: rect.bottom + window.scrollY,
    };
    const newData = {
      id: id,
      boolean: false,
      position: position,
    };
    popuptimer.current = setTimeout(() => {
      setActiveLearnBool1(newData);
    }, 500);
  };

  const currentValue =
    activeExtendFidelityLevel ?? enhanceLocal?.extendFidelityLevel;

  const trackColor = currentValue < 0 ? "red" : "green";

  const extendPaddingStyle =
    percentage > 0
      ? `0 ${Math.abs(percentage) / 4}%`
      : `${Math.abs(percentage) / 4}% 0`;

  const tooltipFormatter = (value) => {
    return labels[value];
  };

  const handleFocus = (id) => {
    const data = {
      id: id,
      bool: true,
    };
    setControlFocus(data);
  };

  const handleVideoCamera = (id) => {
    if (videoToolsetRedux?.id === id) {
      setVideoCameraId({ id: id, bool: true });
      const videoData = {
        id: id,
        bool: true,
      };
      dispatch(
        updateVideoToolsetData({
          projectId: lastPart,
          data: videoData,
          port: plugins?.port,
          isPlugin: isPlugin,
        })
      );
    } else {
      setVideoCameraId({ id: id, bool: true });
      const videoData = {
        id: id,
        bool: true,
      };
      dispatch(
        updateVideoToolsetData({
          projectId: lastPart,
          data: videoData,
          port: plugins?.port,
          isPlugin: isPlugin,
        })
      );
    }
  };

  const [imageUrl1, setImageUrl1] = useState("");
  const [response, setResponse] = useState("");
  const [pluginId, setPluginId] = useState(null);

  const handlePlugin = (id) => {
    openUserSocket();
    const projectPlugin = JSON.parse(
      localStorage?.getItem(`projectPlugin_${lastPart}`)
    );
    if (!projectPlugin) {
      setPluginId(id);
      setPluginPopup(true);
      localStorage.setItem(
        `projectPlugin_${lastPart}`,
        JSON.stringify(plugins)
      );
      return;
    }
    setPluginPopup(false);
    if (plugins?.plugin === "/rhino") {
      connectToPluginSocket(id);
    } else if (plugins?.plugin === "/sketchup") {
      connectToSkechup(id);
    } else if (plugins?.plugin === "/revit") {
      connectToPluginSocket(id);
    }
  };

  const closePluginPopup = () => {
    setPluginPopup(false);
    handlePlugin(pluginId);
  };

  const connectToPluginSocket = (id) => {
    const port = plugins?.port;
    const ws = new WebSocket(`ws://localhost:${port}/`);

    ws.onopen = () => {
      console.log("WebSocket connection established.");
      ws.send("capture");
    };

    ws.onmessage = (event) => {
      const message = event.data;

      if (message.startsWith("screenshot:")) {
        const httpUrl = message.replace("screenshot:", "");
        setImageUrl1(httpUrl);
      } else if (message.startsWith("data:image/png;base64,")) {
        uploadScreenshot(message, id);
      } else {
        setResponse(message);
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
      setResponse("WebSocket error occurred. Check console for details.");
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed.");
    };
  };

  const connectToSkechup = async (id) => {
    const port = plugins?.port;
    try {
      const response = await fetch(`http://127.0.0.1:${port}/capture`);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const message = await response.text();

      if (message.startsWith("screenshot:")) {
        const httpUrl = message.replace("screenshot:", "");
        setImageUrl1(httpUrl);
      } else if (message.startsWith("data:image/png;base64,")) {
        uploadScreenshot(message, id);
      } else {
        setResponse(message);
      }
    } catch (error) {
      console.error("Fetch error:", error);
      setResponse("Error fetching capture data. Check console for details.");
    }
  };

  const uploadScreenshot = (img, id) => {
    if (img?.startsWith("data:image/png;base64,")) {
      let imageType = "base-image";
      if (id === 10) {
        imageType = "cn-image";
      }
      const bool = false;
      const toolset = id;
      localStorage.setItem(`imageType_${lastPart}`, JSON.stringify(imageType));
      const base64 = img;
      const mimeType = "image/jpeg";
      const blob = base64ToBlob(base64, mimeType);
      const imageUrl = URL.createObjectURL(blob);
      if (imageUrl) {
        updatePreUploadState(toolset, imageUrl);
        setTimeout(() => {
          handleBaseImageData(toolset, imageUrl, bool);
        }, 0);
      }
    }
  };

  const deleteUploaded = (toolset) => {
    switch (toolset) {
      case 0:
      case 1:
        const renderData = {
          feedDesignBase64: null,
          uploadedBaseImageUrl: null,
        };
        dispatch(
          updateRenderToolsetData({
            projectId: lastPart,
            data: renderData,
            port: plugins?.port,
            isPlugin: isPlugin,
          })
        );
        break;
      case 2:
        dispatch(
          resetMask({
            projectId: lastPart,
            mode: 0,
            port: plugins?.port,
            isPlugin: isPlugin,
          })
        );
        dispatch(
          clearEditToolsetData({
            projectId: lastPart,
            port: plugins?.port,
            isPlugin: isPlugin,
          })
        );
        break;
      case 10:
        const editData = {
          cnImage: null,
          cnImageBase64: null,
          originalCnImage: null,
          originalCnImageBase64: null,
        };
        dispatch(
          updateEditToolsetData({
            projectId: lastPart,
            data: editData,
            port: plugins?.port,
            isPlugin: isPlugin,
          })
        );
        break;
      default:
        break;
    }
  };

  const handleOpen = (isOpen) => {
    setOpen(isOpen);
  };

  const handleOpen1 = (isOpen) => {
    setOpen1(isOpen);
  };

  const handleOpen2 = (isOpen) => {
    setOpen2(isOpen);
  };

  const handleOpen3 = (isOpen) => {
    setOpen3(isOpen);
  };

  const handleOpen4 = (isOpen) => {
    setOpen4(isOpen);
  };

  const handleOpen5 = (isOpen) => {
    setOpen5(isOpen);
  };

  const handleOpen6 = (isOpen) => {
    setOpen6(isOpen);
  };

  const cnContentLines = t("cn_uploading")?.split("/n");

  return (
    <div className="toolset" onClick={(e) => e.stopPropagation()}>
      <div className="toolset__container">
        <div className="toolset__sections" ref={sectionsRef}>
          <div className="toolset__section">
            <div className="toolset__section__tools">
              <div className="toolset__section__tools__heading">
                <h4>{t("toolset")}</h4>
              </div>
              <Tabs
                selectedIndex={toolsetRedux?.toolset || 0}
                onSelect={(index) => handleToolsetClick(index)}
              >
                <TabList>
                  <div className="toolset__section__tools__tabs">
                    <Tab>
                      <div
                        className="toolset__section__tools__tab"
                        onMouseEnter={(e) => showTooltip(1, e)}
                        onMouseLeave={hideTooltip}
                      >
                        <img src={texti} alt="text-icon" />
                        <img className="imgh" src={texth} alt="text-icon" />
                        <img className="imgd" src={textd} alt="text-icon" />
                        <span>{t("text")}</span>
                        {!responsiveData && (
                          <Portal
                            style={{
                              display: `${
                                tooltipContent === 1 ? "block" : "none"
                              }`,
                            }}
                          >
                            <HoverTip
                              gifUrl={`${AIRI_AWS_URL1}/React-Default-Images/text.gif`}
                              videoResetKey={videoResetKey}
                              heading={t("text")}
                              content={textTooltip}
                              position={tooltipPosition}
                              defaultPosition={{ left: 264, top: 0 }}
                            />
                          </Portal>
                        )}
                      </div>
                    </Tab>
                    <Tab>
                      <div
                        className="toolset__section__tools__tab"
                        onMouseEnter={(e) => showTooltip(2, e)}
                        onMouseLeave={hideTooltip}
                      >
                        <img src={designi} alt="design-icon" />
                        <img className="imgh" src={designh} alt="design-icon" />
                        <img className="imgd" src={designd} alt="design-icon" />
                        <span>{t("design")}</span>
                        {!responsiveData && (
                          <Portal
                            style={{
                              display: `${
                                tooltipContent === 2 ? "block" : "none"
                              }`,
                            }}
                          >
                            <HoverTip
                              gifUrl={`${AIRI_AWS_URL1}/React-Default-Images/design.gif`}
                              videoResetKey={videoResetKey}
                              heading={t("design")}
                              content={designTooltip}
                              position={tooltipPosition}
                              defaultPosition={{ left: 264, top: 0 }}
                            />
                          </Portal>
                        )}
                      </div>
                    </Tab>
                    <Tab>
                      <div
                        className="toolset__section__tools__tab"
                        onMouseEnter={(e) => showTooltip(3, e)}
                        onMouseLeave={hideTooltip}
                      >
                        <img src={imagei} alt="image-icon" />
                        <img className="imgh" src={imageh} alt="image-icon" />
                        <img className="imgd" src={imaged} alt="image-icon" />
                        <span>{t("image")}</span>
                        {!responsiveData && (
                          <Portal
                            style={{
                              display: `${
                                tooltipContent === 3 ? "block" : "none"
                              }`,
                            }}
                          >
                            <HoverTip
                              gifUrl={`${AIRI_AWS_URL1}/React-Default-Images/inpainting.gif`}
                              videoResetKey={videoResetKey}
                              heading={t("image")}
                              content={imageTooltip}
                              position={tooltipPosition}
                              defaultPosition={{ left: 264, top: 0 }}
                            />
                          </Portal>
                        )}
                      </div>
                    </Tab>
                    <Tab>
                      <div
                        className="toolset__section__tools__tab"
                        onMouseEnter={(e) => showTooltip(4, e)}
                        onMouseLeave={hideTooltip}
                      >
                        <img src={upscalei} alt="upscale-icon" />
                        <img className="imgh" src={upscaleh} alt="image-icon" />
                        <img className="imgd" src={upscaled} alt="image-icon" />
                        {/* <h6 className="updated">{t("beta")}</h6> */}
                        <span>{t("enhance")}</span>
                        {!responsiveData && (
                          <Portal
                            style={{
                              display: `${
                                tooltipContent === 4 ? "block" : "none"
                              }`,
                            }}
                          >
                            <HoverTip
                              gifUrl={`${AIRI_AWS_URL1}/React-Default-Images/enhance.gif`}
                              videoResetKey={videoResetKey}
                              heading={t("enhance")}
                              content={enhanceTooltip}
                              position={tooltipPosition}
                              defaultPosition={{ left: 264, top: 0 }}
                            />
                          </Portal>
                        )}
                      </div>
                    </Tab>
                    <Tab>
                      <div
                        className="toolset__section__tools__tab"
                        onMouseEnter={(e) => showTooltip(5, e)}
                        onMouseLeave={hideTooltip}
                      >
                        <img src={videoa} alt="upscale-icon" />
                        <img className="imgh" src={videoh} alt="image-icon" />
                        <img className="imgd" src={videod} alt="image-icon" />
                        <h6 className="updated">{t("new")}</h6>
                        <span>{t("toolset_video")}</span>
                        {!responsiveData && (
                          <Portal
                            style={{
                              display: `${
                                tooltipContent === 5 ? "block" : "none"
                              }`,
                            }}
                          >
                            <HoverTip
                              gifUrl="https://airi-production.s3.cn-north-1.amazonaws.com.cn/frontend-assets/%E6%BC%94%E7%A4%BA%E6%96%87%E7%A8%BF1(1)+(1).gif"
                              videoResetKey={videoResetKey}
                              heading={t("toolset_video")}
                              content={videoTooltip}
                              position={tooltipPosition}
                              defaultPosition={{ left: 264, top: 0 }}
                            />
                          </Portal>
                        )}
                      </div>
                    </Tab>
                  </div>
                </TabList>
                <div
                  className={`toolset__section__tools__main ${
                    editToolsetRedux?.isFromDesignActivated === true
                      ? "scrolling"
                      : ""
                  }`}
                  key={promptLocalData?.isTemplate}
                  data-scrollable="true"
                >
                  <TabPanel>
                    <div className="toolset__section__tools__main__text">
                      <div className="toolset__section__tools__main__text__modal">
                        <div className="toolset__section__tools__main__text__modal__heading">
                          <h4>{t("modal")}</h4>
                          <h5>New</h5>
                        </div>
                        <div className="toolset__section__tools__main__text__modal__sec">
                          <div
                            className={`toolset__section__tools__main__text__modal__sec__sub ${
                              inspireToolsetRedux?.model === 1 ? "active" : ""
                            }`}
                            onClick={() => handleModalSelection(1)}
                          >
                            <div className="toolset__section__tools__main__text__modal__sec__sub">
                              <span>{t("airi_lab")}</span>
                            </div>
                          </div>
                          <div
                            className={`toolset__section__tools__main__text__modal__sec__sub ${
                              inspireToolsetRedux?.model === 2 ? "active" : ""
                            }`}
                            onClick={() => handleModalSelection(2)}
                          >
                            <div className="toolset__section__tools__main__text__modal__sec__sub">
                              <span>{t("mid_journey")}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="toolset__section__tools__main__text__orientation">
                        <div className="toolset__section__tools__main__text__orientation__heading">
                          <h4>{t("orientation")}</h4>
                        </div>
                        <div className="toolset__section__tools__main__text__orientation__sec">
                          <div
                            className={`toolset__section__tools__main__text__orientation__sec__sub ${
                              inspireToolsetRedux?.orientation === 0
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleOrientation(0)}
                          >
                            <span>{t("horizontal")}</span>
                          </div>
                          <div
                            className={`toolset__section__tools__main__text__orientation__sec__sub ${
                              inspireToolsetRedux?.orientation === 1
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleOrientation(1)}
                          >
                            <span>{t("vertical")}</span>
                          </div>
                        </div>
                      </div>
                      <div className="toolset__section__tools__main__text__ratio">
                        <div className="toolset__section__tools__main__text__ratio__heading">
                          <h4>{t("image_ratio")}</h4>
                        </div>
                        <div className="toolset__section__tools__main__text__ratio__sec">
                          <div
                            className={`toolset__section__tools__main__text__ratio__sec__sub ${
                              inspireToolsetRedux?.imageRatio === 0
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleClick(0)}
                          >
                            <div className="toolset__section__tools__main__text__ratio__sec__sub__section">
                              <h6>4:3</h6>
                            </div>
                          </div>
                          <div
                            className={`toolset__section__tools__main__text__ratio__sec__sub ${
                              inspireToolsetRedux?.imageRatio === 3
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleClick(3)}
                          >
                            <div className="toolset__section__tools__main__text__ratio__sec__sub__section">
                              <h6>16:9</h6>
                            </div>
                          </div>
                          <div
                            className={`toolset__section__tools__main__text__ratio__sec__sub ${
                              inspireToolsetRedux?.imageRatio === 1
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleClick(1)}
                          >
                            <div className="toolset__section__tools__main__text__ratio__sec__sub__section">
                              <h6>3:2</h6>
                            </div>
                          </div>
                          <div
                            className={`toolset__section__tools__main__text__ratio__sec__sub ${
                              inspireToolsetRedux?.imageRatio === 2
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleClick(2)}
                          >
                            <div className="toolset__section__tools__main__text__ratio__sec__sub__section">
                              <h6>1:1</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      {inspireToolsetRedux?.model === 1 ? (
                        <div className="toolset__section__tools__main__text__image-count">
                          <div className="toolset__section__tools__main__text__image-count__heading">
                            <h4>{t("image_count")}</h4>
                          </div>
                          <div className="toolset__section__tools__main__text__image-count__sec">
                            <div
                              className={`toolset__section__tools__main__text__image-count__sec__sub ${
                                inspireToolsetRedux?.imageCount === 1
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => handleImageCount(1)}
                            >
                              <h6>1</h6>
                            </div>
                            <div
                              className={`toolset__section__tools__main__text__image-count__sec__sub ${
                                inspireToolsetRedux?.imageCount === 2
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => handleImageCount(2)}
                            >
                              <h6>2</h6>
                            </div>
                            <div
                              className={`toolset__section__tools__main__text__image-count__sec__sub ${
                                inspireToolsetRedux?.imageCount === 3
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => handleImageCount(3)}
                            >
                              <h6>3</h6>
                            </div>
                            <div
                              className={`toolset__section__tools__main__text__image-count__sec__sub ${
                                inspireToolsetRedux?.imageCount === 4
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => handleImageCount(4)}
                            >
                              <h6>4</h6>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="toolset__section__tools__main__text__image-count">
                          <div className="toolset__section__tools__main__text__image-count__heading">
                            <h4>{t("image_count")}</h4>
                          </div>
                          <div className="toolset__section__tools__main__text__image-count__sec1">
                            <div
                              className={`toolset__section__tools__main__text__image-count__sec__sub ${
                                inspireToolsetRedux?.imageCount === 4
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => handleImageCount(4)}
                            >
                              <h6>4</h6>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="toolset__section__tools__main__design">
                      <div className="toolset__section__tools__main__design__base-img">
                        <div
                          className="toolset__section__tools__main__design__base-img__heading"
                          style={{ position: "relative" }}
                        >
                          <h4>{t("base_image")}</h4>
                          {!responsiveData && (
                            <div
                              className="tooltip-top"
                              onClick={(event) => handleActiveLearn(3, event)}
                            >
                              <img src={intro} alt="intro" />
                              <Portal
                                style={{
                                  display: `${
                                    activeLearnBool?.boolean === true &&
                                    activeLearnBool?.id === 3
                                      ? "block"
                                      : "none"
                                  }`,
                                }}
                              >
                                <LearnPopups
                                  heading={t("base_image")}
                                  content={t("base_image_tooltip")}
                                  styles="260px"
                                  image={renderimage}
                                  position={activeLearnBool?.position}
                                />
                              </Portal>
                            </div>
                          )}
                        </div>
                        <div
                          className={`card ${
                            controlFocus?.id === 1 && controlFocus?.bool
                              ? "focus"
                              : ""
                          }`}
                          onDragOver={handleDragOver}
                          onDrop={(e) => handleDrop(e, 1)}
                        >
                          <input
                            key={inputKey}
                            type="file"
                            accept=".jpeg, .jpg, .png, .webp"
                            id="fileInput"
                            style={{ display: "none" }}
                            onChange={handleFileChangeWithToolsetInput(1)}
                          />
                          <label
                            style={{
                              cursor:
                                renderToolsetRedux?.uploadedBaseImageUrl &&
                                !renderToolsetRedux?.feedDesignBase64
                                  ? "progress"
                                  : "pointer",
                            }}
                            {...(!isPlugin ? { htmlFor: "fileInput" } : {})}
                          >
                            {renderToolsetRedux?.feedDesignBase64 &&
                              isPlugin && (
                                <Popover
                                  overlayClassName="plugin-screenshot-popover"
                                  content={t("update_model")}
                                  arrow={true}
                                  trigger="hover"
                                  placement="top"
                                  open={open}
                                  onOpenChange={handleOpen}
                                >
                                  <div
                                    className="refresh-icon"
                                    onClick={() => handlePlugin(1)}
                                  >
                                    <img
                                      className="refresh-active"
                                      src={refreshicona}
                                      alt="refresh-icon"
                                    />
                                    <img
                                      className="refresh-hover"
                                      src={refreshiconh}
                                      alt="refresh-icon"
                                    />
                                    <img
                                      className="refresh-default"
                                      src={refreshicon}
                                      alt="refresh-icon"
                                    />
                                  </div>
                                </Popover>
                              )}
                            {renderToolsetRedux?.feedDesignBase64 &&
                              isPlugin && (
                                <Popover
                                  overlayClassName="plugin-screenshot-popover"
                                  content={t("delete")}
                                  arrow={true}
                                  trigger="hover"
                                  placement="top"
                                  open={open1}
                                  onOpenChange={handleOpen1}
                                >
                                  <div
                                    className="delete-icon"
                                    onClick={() => deleteUploaded(1)}
                                  >
                                    <img
                                      className="delete-active"
                                      src={deleteicona}
                                      alt="delete-icon"
                                    />
                                    <img
                                      className="delete-hover"
                                      src={deleteiconh}
                                      alt="delete-icon"
                                    />
                                    <img
                                      className="delete-default"
                                      src={deleteicon}
                                      alt="delete-icon"
                                    />
                                  </div>
                                </Popover>
                              )}
                            <div
                              className="card-content"
                              onDragOver={handleDragOver}
                              onDrop={(e) => handleDrop(e, 1)}
                              style={{
                                justifyContent: `${
                                  renderToolsetRedux?.uploadedBaseImageUrl
                                    ? "Center"
                                    : "FlexEnd"
                                }`,
                                padding: `${
                                  renderToolsetRedux?.uploadedBaseImageUrl 
                                    ? "0"
                                    : "0 0 22px 0"
                                }`,
                              }}
                            >
                              {renderToolsetRedux?.uploadedBaseImageUrl ? (
                                <>
                                  <img
                                    className="uploaded"
                                    src={
                                      renderToolsetRedux?.uploadedBaseImageUrl
                                    }
                                    draggable="false"
                                  />
                                  {renderToolsetRedux?.feedDesignBase64 ===
                                    null ||
                                  renderToolsetRedux?.feedDesignBase64 ===
                                    undefined ? (
                                    <>
                                      <div className="gradient-mask"></div>
                                      <div className="text-display">
                                        {t("uploading...")}
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                <>
                                  <label
                                    className={`card-content__sec ${
                                      isPlugin ? "plugin" : ""
                                    }`}
                                    htmlFor="fileInput"
                                  >
                                    <img
                                      className={`icon ${
                                        controlFocus?.id === 1 &&
                                        controlFocus?.bool
                                          ? "focus"
                                          : ""
                                      }`}
                                      src={
                                        controlFocus?.id === 1 &&
                                        controlFocus?.bool
                                          ? controlanim
                                          : baseicon
                                      }
                                      alt=""
                                    />
                                    <h5>{t("click_upload")}</h5>
                                  </label>
                                  {isPlugin && (
                                    <div
                                      className={`card-content__sec ${
                                        isPlugin ? "plugin" : ""
                                      }`}
                                      onClick={() => handlePlugin(1)}
                                    >
                                      <img
                                        className={`icon`}
                                        src={screenshot}
                                        alt=""
                                      />
                                      <h5>{t("model_screenshot")}</h5>
                                    </div>
                                  )}
                                  {!responsiveData && (
                                    <p>{t("design_base_image_text1")}</p>
                                  )}
                                  {/* <p>{t("design_base_image_text2")}</p> */}
                                </>
                              )}
                            </div>
                          </label>
                        </div>
                        {/* )} */}
                      </div>
                      <div className="toolset__section__tools__main__design__camera camera-main">
                        <div className="toolset__section__tools__main__design__imagetype">
                          <div className="toolset__section__tools__main__design__imagetype__main">
                            <div className="toolset__section__tools__main__design__fidelity__heading">
                              <div
                                className="toolset__section__tools__main__design__fidelity__heading__left"
                                style={{ position: "relative" }}
                              >
                                <h4>{t("control_level")}</h4>
                                {!responsiveData && (
                                  <div
                                    className="tooltip-top"
                                    onClick={(event) =>
                                      handleActiveLearn(33, event)
                                    }
                                  >
                                    <img src={intro} alt="intro" />
                                    <Portal
                                      style={{
                                        display: `${
                                          activeLearnBool?.boolean === true &&
                                          activeLearnBool?.id === 33
                                            ? "block"
                                            : "none"
                                        }`,
                                      }}
                                    >
                                      <LearnPopups
                                        heading={t("control_level")}
                                        content={t(
                                          "hover_control_content_render"
                                        )}
                                        styles="80px"
                                        image={
                                          languageActive === "ch"
                                            ? controlLevelrenderCh
                                            : controlLevelrenderEn
                                        }
                                        position={activeLearnBool?.position}
                                      />
                                    </Portal>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="toolset__section__tools__main__design__imagetype__slider">
                              <Slider
                                value={
                                  toolsetRedux?.renderControlId ||
                                  activeFidelityLevel
                                }
                                step={1}
                                min={1}
                                max={9}
                                onChange={handleSliderChange}
                              />
                              <span>
                                {toolsetRedux?.renderControlId ||
                                  activeFidelityLevel}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="toolset__section__tools__main__design__fidelity">
                        <div
                          className="toolset__section__tools__main__design__fidelity__heading"
                          style={{ cursor: "pointer" }}
                          onClick={handleDesignPreservematerial}
                        >
                          <div
                            className="toolset__section__tools__main__design__fidelity__heading__left"
                            style={{ position: "relative" }}
                          >
                            <h4>{t("preserve_material_new")}</h4>
                            {!responsiveData && (
                              <div
                                className="tooltip-top"
                                onClick={(event) =>
                                  handleActiveLearn(31, event)
                                }
                              >
                                <img src={intro} alt="intro" />
                                <Portal
                                  style={{
                                    display: `${
                                      activeLearnBool?.boolean === true &&
                                      activeLearnBool?.id === 31
                                        ? "block"
                                        : "none"
                                    }`,
                                  }}
                                >
                                  <LearnPopups
                                    heading={t("preserve_material_new")}
                                    content={t("fidelity_tooltip1")}
                                    styles="80px"
                                    image={preserveimage}
                                    position={activeLearnBool?.position}
                                  />
                                </Portal>
                              </div>
                            )}
                          </div>
                          <div className="toolset__section__tools__main__design__fidelity__heading__right">
                            <Switch
                              onChange={handleDesignPreservematerial}
                              checked={
                                toolsetRedux?.renderPreserve || preserve
                                  ? true
                                  : false
                              }
                              height={responsiveData ? 14 : 16}
                              width={responsiveData ? 28 : 32}
                              handleDiameter={12}
                              offColor="#A8ADAF"
                              onColor="#000"
                              uncheckedIcon={false}
                              checkedIcon={false}
                              className={`${
                                responsiveData
                                  ? toolsetRedux?.renderPreserve || preserve
                                    ? "small-on"
                                    : "small-off"
                                  : ""
                              }react-switch-Undragable`}
                            />
                          </div>
                        </div>
                        <div className="toolset__section__tools__main__design__fidelity__sec">
                          {(toolsetRedux?.renderPreserve || preserve) && (
                            <div className="toolset__section__tools__main__design__fidelity__sec__sub">
                              <Slider
                                // key={activePreserveMaterial}
                                value={
                                  toolsetRedux?.renderPreserveId ||
                                  activePreserveMaterial
                                }
                                step={1}
                                min={1}
                                max={9}
                                onChange={handlePreserveMaterial}
                              />
                              <span>
                                {toolsetRedux?.renderPreserveId ||
                                  activePreserveMaterial}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="toolset__section__tools__main__design__resolution">
                        <div
                          className="toolset__section__tools__main__design__resolution__heading"
                          style={{ cursor: "pointer" }}
                          onClick={handleImageResolution}
                        >
                          <div
                            className="toolset__section__tools__main__design__resolution__heading__left"
                            style={{ position: "relative" }}
                          >
                            <h4>{t("image_resolution")}</h4>
                            {!responsiveData && (
                              <div
                                className="tooltip-top"
                                onClick={(event) =>
                                  handleActiveLearn(32, event)
                                }
                              >
                                <img src={intro} alt="intro" />
                                <Portal
                                  style={{
                                    display: `${
                                      activeLearnBool?.boolean === true &&
                                      activeLearnBool?.id === 32
                                        ? "block"
                                        : "none"
                                    }`,
                                  }}
                                >
                                  <LearnPopups
                                    heading={t("image_resolution")}
                                    content={t("image_resolution_tooltip")}
                                    styles={`${
                                      toolsetRedux?.renderPreserve ?? preserve
                                        ? languageActive === "ch"
                                          ? "80px"
                                          : "100px"
                                        : languageActive === "ch"
                                        ? "120px"
                                        : "140px"
                                    }`}
                                    image={imageresolutionimage}
                                    position={activeLearnBool?.position}
                                  />
                                </Portal>
                              </div>
                            )}
                          </div>
                          <div className="toolset__section__tools__main__design__fidelity__heading__right">
                            <Switch
                              onChange={handleImageResolution}
                              checked={
                                toolsetRedux?.imageResolution || imageResolution
                                  ? true
                                  : false
                              }
                              height={responsiveData ? 14 : 16}
                              width={responsiveData ? 28 : 32}
                              handleDiameter={12}
                              offColor="#A8ADAF"
                              onColor="#000"
                              uncheckedIcon={false}
                              checkedIcon={false}
                              className={`${
                                responsiveData
                                  ? toolsetRedux?.imageResolution ||
                                    imageResolution
                                    ? "small-on"
                                    : "small-off"
                                  : ""
                              }react-switch-Undragable`}
                            />
                          </div>
                        </div>
                        {(toolsetRedux?.imageResolution || imageResolution) && (
                          <div className="toolset__section__tools__main__design__resolution__section">
                            <div className="toolset__section__tools__main__design__resolution__section__heading">
                              <div
                                className={`toolset__section__tools__main__design__resolution__section__heading__sec
                                    ${languageActive === "ch" ? "ch" : ""} ${
                                  toolsetRedux?.imageResolutionId === 1
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => handleResolutionType(1)}
                              >
                                <h5>2.25 {t("megapixels")}</h5>
                              </div>
                              <div
                                className={`toolset__section__tools__main__design__resolution__section__heading__sec
                                    ${languageActive === "ch" ? "ch" : ""} ${
                                  toolsetRedux?.imageResolutionId === 2
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => handleResolutionType(2)}
                              >
                                <h5>4 {t("megapixels")}</h5>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="toolset__section__tools__main__design__image-count">
                        <div className="toolset__section__tools__main__design__image-count__heading">
                          <h4>{t("image_count")}</h4>
                        </div>
                        <div className="toolset__section__tools__main__design__image-count__sec">
                          <div
                            className={`toolset__section__tools__main__design__image-count__sec__sub ${
                              inspireToolsetRedux?.imageCount === 1
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleImageCount(1)}
                          >
                            <h6>1</h6>
                          </div>
                          <div
                            className={`toolset__section__tools__main__design__image-count__sec__sub ${
                              inspireToolsetRedux?.imageCount === 2
                                ? "active"
                                : ""
                            } ${
                              toolsetRedux?.imageResolutionId === 2
                                ? "disable"
                                : ""
                            }`}
                            onClick={
                              toolsetRedux?.imageResolutionId === 2
                                ? null
                                : () => handleImageCount(2)
                            }
                          >
                            <h6>2</h6>
                          </div>
                          <div
                            className={`toolset__section__tools__main__design__image-count__sec__sub ${
                              inspireToolsetRedux?.imageCount === 3
                                ? "active"
                                : ""
                            } ${
                              toolsetRedux?.imageResolutionId === 2
                                ? "disable"
                                : ""
                            }`}
                            onClick={
                              toolsetRedux?.imageResolutionId === 2
                                ? null
                                : () => handleImageCount(3)
                            }
                          >
                            <h6>3</h6>
                          </div>
                          <div
                            className={`toolset__section__tools__main__design__image-count__sec__sub ${
                              inspireToolsetRedux?.imageCount === 4
                                ? "active"
                                : ""
                            } ${
                              toolsetRedux?.imageResolutionId === 2
                                ? "disable"
                                : ""
                            }`}
                            onClick={
                              toolsetRedux?.imageResolutionId === 2
                                ? null
                                : () => handleImageCount(4)
                            }
                          >
                            <h6>4</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="toolset__section__tools__main__imagec">
                      {!editToolsetRedux?.uploadedBaseImage && (
                        <div className="toolset__section__tools__main__imagec__base-img">
                          <div
                            className="toolset__section__tools__main__imagec__base-img__heading"
                            style={{ position: "relative" }}
                          >
                            <h4>{t("base_image")}</h4>
                            {!responsiveData && (
                              <div
                                className="tooltip-top"
                                onClick={(event) => handleActiveLearn(7, event)}
                              >
                                <img src={intro} alt="intro" />
                                <Portal
                                  style={{
                                    display: `${
                                      activeLearnBool?.boolean === true &&
                                      activeLearnBool?.id === 7
                                        ? "block"
                                        : "none"
                                    }`,
                                  }}
                                >
                                  <LearnPopups
                                    heading={t("base_image")}
                                    content={t("base_image_tooltip_edit")}
                                    styles="260px"
                                    image={renderimage}
                                    position={activeLearnBool?.position}
                                  />
                                </Portal>
                              </div>
                            )}
                          </div>
                          <div
                            className={`card ${
                              controlFocus?.id === 2 && controlFocus?.bool
                                ? "focus"
                                : ""
                            }`}
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, 2)}
                          >
                            <input
                              key={inputKey}
                              type="file"
                              accept=".jpeg, .jpg, .png, .webp"
                              id="fileInput1"
                              style={{ display: "none" }}
                              onChange={handleFileChangeWithToolsetInput(2)}
                            />
                            <label
                              {...(!isPlugin ? { htmlFor: "fileInput1" } : {})}
                              style={{
                                cursor:
                                  editToolsetRedux?.uploadedBaseImage &&
                                  !editToolsetRedux?.feedImageBase64
                                    ? "progress"
                                    : "pointer",
                              }}
                            >
                              {editToolsetRedux?.feedImageBase64 &&
                                isPlugin && (
                                  <Popover
                                    overlayClassName="plugin-screenshot-popover"
                                    content={t("update_model")}
                                    arrow={true}
                                    trigger="hover"
                                    placement="top"
                                    open={open2}
                                    onOpenChange={handleOpen2}
                                  >
                                    <div
                                      className="refresh-icon"
                                      onClick={() => handlePlugin(2)}
                                    >
                                      <img
                                        className="refresh-active"
                                        src={refreshicona}
                                        alt="refresh-icon"
                                      />
                                      <img
                                        className="refresh-hover"
                                        src={refreshiconh}
                                        alt="refresh-icon"
                                      />
                                      <img
                                        className="refresh-default"
                                        src={refreshicon}
                                        alt="refresh-icon"
                                      />
                                    </div>
                                  </Popover>
                                )}
                              <div
                                className="card-content"
                                onDragOver={handleDragOver}
                                onDrop={(e) => handleDrop(e, 2)}
                                style={{
                                  justifyContent: `${
                                    editToolsetRedux?.uploadedBaseImage
                                      ? "Center"
                                      : "FlexEnd"
                                  }`,
                                }}
                              >
                                {editToolsetRedux?.uploadedBaseImage ? (
                                  <>
                                    <img
                                      className="uploaded"
                                      src={editToolsetRedux?.uploadedBaseImage}
                                      alt=""
                                      draggable="false"
                                    />
                                    {editToolsetRedux?.feedImageBase64 ===
                                      null ||
                                    editToolsetRedux?.feedImageBase64 ===
                                      undefined ? (
                                      <>
                                        <div className="gradient-mask"></div>
                                        <div className="text-display">
                                          {t("uploading...")}
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <label
                                      className={`card-content__sec ${
                                        isPlugin ? "plugin" : ""
                                      }`}
                                      htmlFor="fileInput1"
                                    >
                                      <img
                                        className={`icon ${
                                          controlFocus?.id === 2 &&
                                          controlFocus?.bool
                                            ? "focus"
                                            : ""
                                        }`}
                                        src={
                                          controlFocus?.id === 2 &&
                                          controlFocus?.bool
                                            ? controlanim
                                            : baseicon
                                        }
                                        alt=""
                                      />
                                      <h5>{t("click_upload")}</h5>
                                    </label>
                                    {isPlugin && (
                                      <div
                                        className={`card-content__sec ${
                                          isPlugin ? "plugin" : ""
                                        }`}
                                        onClick={() => handlePlugin(2)}
                                      >
                                        <img
                                          className={`icon`}
                                          src={screenshot}
                                          alt=""
                                        />
                                        <h5>{t("model_screenshot")}</h5>
                                      </div>
                                    )}
                                    <p>{t("image_base_image_text1")}</p>
                                  </>
                                )}
                              </div>
                            </label>
                          </div>
                          {/* )} */}
                        </div>
                      )}
                      {editToolsetRedux?.uploadedBaseImage && (
                        <div className="toolset__section__tools__main__imagec__mask">
                          <div
                            className="toolset__section__tools__main__imagec__mask__heading"
                            style={{ position: "relative" }}
                          >
                            <div className="toolset__section__tools__main__imagec__mask__heading__leftSide">
                              <h4>{t("base_image")}</h4>
                              {!responsiveData && (
                                <div
                                  className="tooltip-top"
                                  onClick={(event) =>
                                    handleActiveLearn(9, event)
                                  }
                                >
                                  <img src={intro} alt="intro" />
                                  <Portal
                                    style={{
                                      display: `${
                                        activeLearnBool?.boolean === true &&
                                        activeLearnBool?.id === 9
                                          ? "block"
                                          : "none"
                                      }`,
                                    }}
                                  >
                                    <LearnPopups
                                      heading={t("mask")}
                                      content={t("mask_tooltip")}
                                      styles="260px"
                                      image={maskimage}
                                      position={activeLearnBool?.position}
                                    />
                                  </Portal>
                                </div>
                              )}
                            </div>
                            {editToolsetRedux?.isFromDesignActivated &&
                            !editToolsetRedux?.cnImage ? (
                              <button
                                onClick={() => handleFocus(4)}
                                style={{
                                  cursor: "default",
                                  background: "#fff",
                                  color: "#263238",
                                  border: "1px solid #ededed",
                                }}
                              >
                                <h4
                                  style={{
                                    color: "#263238",
                                  }}
                                >
                                  {t("edit_mask")}
                                </h4>
                              </button>
                            ) : (
                              <button
                                onClick={handleOpenMaskPopup}
                                style={{
                                  cursor:
                                    !editToolsetRedux?.uploadedBaseImage ||
                                    (editToolsetRedux?.isFromDesignActivated &&
                                      !editToolsetRedux?.cnImage)
                                      ? "default"
                                      : !editToolsetRedux?.feedImageBase64
                                      ? "progress"
                                      : !editToolsetRedux?.maskImageBase64
                                      ? "progress"
                                      : "pointer",
                                }}
                                disabled={
                                  (editToolsetRedux?.maskImageData &&
                                    (!editToolsetRedux?.maskImageBase64 ||
                                      !editToolsetRedux?.feedImageBase64)) ||
                                  (editToolsetRedux?.cnImage &&
                                    (!editToolsetRedux?.cnImageBase64 ||
                                      !editToolsetRedux?.feedImageBase64)) ||
                                  (editToolsetRedux?.isFromDesignActivated &&
                                    !editToolsetRedux?.cnImage)
                                }
                              >
                                <h4>{t("edit_mask")}</h4>
                              </button>
                            )}
                          </div>
                          <div className="toolset__section__tools__main__imagec__mask__sec">
                            <div className="toolset__section__tools__main__imagec__mask__sec__image">
                              <div
                                className="card"
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                              >
                                <input
                                  key={inputKey}
                                  type="file"
                                  accept=".jpeg, .jpg, .png, .webp"
                                  id="fileInput2"
                                  style={{ display: "none" }}
                                  onChange={handleFileChangeWithToolsetInput(2)}
                                  disabled={checkIfSelectionIsDisabled()}
                                />
                                <label
                                  // onClick={handleOpenMaskPopup}
                                  {...(!isPlugin
                                    ? { htmlFor: "fileInput2" }
                                    : {})}
                                  style={{
                                    cursor: !editToolsetRedux?.uploadedBaseImage
                                      ? "not-allowed"
                                      : !editToolsetRedux?.feedImageBase64
                                      ? "progress"
                                      : !editToolsetRedux?.maskImageBase64
                                      ? "progress"
                                      : editToolsetRedux?.isFromDesignActivated &&
                                        !editToolsetRedux?.cnImageBase64
                                      ? "not-allowed"
                                      : "pointer",
                                  }}
                                >
                                  {editToolsetRedux?.maskImageBase64 &&
                                    isPlugin && (
                                      <Popover
                                        overlayClassName="plugin-screenshot-popover"
                                        content={t("update_model")}
                                        arrow={true}
                                        trigger="hover"
                                        placement="top"
                                        open={open3}
                                        onOpenChange={handleOpen3}
                                      >
                                        <div
                                          className="refresh-icon"
                                          onClick={() => handlePlugin(2)}
                                        >
                                          <img
                                            className="refresh-active"
                                            src={refreshicona}
                                            alt="refresh-icon"
                                          />
                                          <img
                                            className="refresh-hover"
                                            src={refreshiconh}
                                            alt="refresh-icon"
                                          />
                                          <img
                                            className="refresh-default"
                                            src={refreshicon}
                                            alt="refresh-icon"
                                          />
                                        </div>
                                      </Popover>
                                    )}
                                  {editToolsetRedux?.maskImageBase64 &&
                                    isPlugin && (
                                      <Popover
                                        overlayClassName="plugin-screenshot-popover"
                                        content={t("delete")}
                                        arrow={true}
                                        trigger="hover"
                                        placement="top"
                                        open={open4}
                                        onOpenChange={handleOpen4}
                                      >
                                        <div
                                          className="delete-icon"
                                          onClick={() => deleteUploaded(2)}
                                        >
                                          <img
                                            className="delete-active"
                                            src={deleteicona}
                                            alt="delete-icon"
                                          />
                                          <img
                                            className="delete-hover"
                                            src={deleteiconh}
                                            alt="delete-icon"
                                          />
                                          <img
                                            className="delete-default"
                                            src={deleteicon}
                                            alt="delete-icon"
                                          />
                                        </div>
                                      </Popover>
                                    )}
                                  <div
                                    className="card-content"
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                    style={{
                                      justifyContent: `${
                                        editToolsetRedux?.uploadedBaseImage
                                          ? "Center"
                                          : "FlexEnd"
                                      }`,
                                    }}
                                  >
                                    {editToolsetRedux?.maskImageData ? (
                                      <>
                                        <img
                                          className="uploaded"
                                          src={editToolsetRedux?.maskImageData}
                                          alt=""
                                          draggable="false"
                                        />
                                        {editToolsetRedux?.maskImageBase64 ===
                                          null || undefined ? (
                                          <>
                                            <div className="gradient-mask"></div>
                                            <div className="text-display">
                                              {t("uploading...")}
                                            </div>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : (
                                      <img
                                        className="icon"
                                        src={baseicon}
                                        alt=""
                                      />
                                    )}
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="toolset__section__tools__main__imagec__selection">
                        <div className="toolset__section__tools__main__imagec__selection__sub">
                          <button
                            className={`toolset__section__tools__main__imagec__selection__sub__sec ${
                              toolsetRedux?.editToolsetSelection === 1
                                ? "active"
                                : ""
                            }`}
                            onMouseEnter={(event) =>
                              handleMouseEnter(31, event, 100)
                            }
                            onMouseLeave={(event) =>
                              handleMouseLeave(31, event, 100)
                            }
                            onClick={() => handleActiveSelection(1)}
                            disabled={checkIfSelectionIsDisabled()}
                            style={{
                              cursor: checkIfSelectionIsDisabled()
                                ? "progress"
                                : "pointer",
                            }}
                          >
                            {!responsiveData && (
                              <Portal
                                style={{
                                  display: `${
                                    activeLearnBool1?.boolean === true &&
                                    activeLearnBool1?.id === 31
                                      ? "block"
                                      : "none"
                                  }`,
                                }}
                              >
                                <LearnPopups
                                  heading={t("hover_inpaint_heading")}
                                  content={t("hover_inpaint_content")}
                                  styles="0px"
                                  image={inpaintimage}
                                  position={activeLearnBool1?.position}
                                />
                              </Portal>
                            )}
                            <img
                              className="imga"
                              src={inpainti}
                              alt="image-icon"
                            />
                            <img
                              className="imgh"
                              src={inpainth}
                              alt="image-icon"
                            />
                            <img
                              className="imgd"
                              src={inpaintd}
                              alt="image-icon"
                            />
                            {toolsetRedux?.editToolsetSelection === 1 && (
                              <h5>{t("inpaint")}</h5>
                            )}
                          </button>
                          <button
                            className={`toolset__section__tools__main__imagec__selection__sub__sec  ${
                              toolsetRedux?.editToolsetSelection === 2
                                ? "active"
                                : ""
                            }`}
                            onMouseEnter={(event) =>
                              handleMouseEnter(32, event, 100)
                            }
                            onMouseLeave={(event) =>
                              handleMouseLeave(32, event, 100)
                            }
                            onClick={() => handleActiveSelection(2)}
                            disabled={checkIfSelectionIsDisabled()}
                            style={{
                              cursor: checkIfSelectionIsDisabled()
                                ? "progress"
                                : "pointer",
                            }}
                          >
                            {!responsiveData && (
                              <Portal
                                style={{
                                  display: `${
                                    activeLearnBool1?.boolean === true &&
                                    activeLearnBool1?.id === 32
                                      ? "block"
                                      : "none"
                                  }`,
                                }}
                              >
                                <LearnPopups
                                  heading={t("hover_controlinpaint_heading")}
                                  content={t("hover_controlinpaint_content")}
                                  styles="0px"
                                  image={controlinpaintimage}
                                  position={activeLearnBool1?.position}
                                  hideLinks={true}
                                />
                              </Portal>
                            )}
                            <img
                              className="imga"
                              src={shapei}
                              alt="image-icon"
                            />
                            <img
                              className="imgh"
                              src={shapeh}
                              alt="image-icon"
                            />
                            <img
                              className="imgd"
                              src={shaped}
                              alt="image-icon"
                            />
                            {toolsetRedux?.editToolsetSelection === 2 && (
                              <h5>{t("shape")}</h5>
                            )}
                          </button>
                          <button
                            className={`toolset__section__tools__main__imagec__selection__sub__sec ${
                              toolsetRedux?.editToolsetSelection === 3
                                ? "active"
                                : ""
                            }`}
                            onMouseEnter={(event) =>
                              handleMouseEnter(33, event, 100)
                            }
                            onMouseLeave={(event) =>
                              handleMouseLeave(33, event, 100)
                            }
                            onClick={() => handleActiveSelection(3)}
                            disabled={checkIfSelectionIsDisabled()}
                            style={{
                              cursor: checkIfSelectionIsDisabled()
                                ? "progress"
                                : "pointer",
                            }}
                          >
                            {!responsiveData && (
                              <Portal
                                style={{
                                  display: `${
                                    activeLearnBool1?.boolean === true &&
                                    activeLearnBool1?.id === 33
                                      ? "block"
                                      : "none"
                                  }`,
                                }}
                              >
                                <LearnPopups
                                  heading={t("hover_eraser_heading")}
                                  content={t("hover_eraser_content")}
                                  styles="0px"
                                  image={eraserimage}
                                  position={activeLearnBool1?.position}
                                />
                              </Portal>
                            )}
                            <img
                              className="imga"
                              src={eraseri}
                              alt="image-icon"
                            />
                            <img
                              className="imgh"
                              src={eraserh}
                              alt="image-icon"
                            />
                            <img
                              className="imgd"
                              src={eraserd}
                              alt="image-icon"
                            />
                            {toolsetRedux?.editToolsetSelection === 3 && (
                              <h5>{t("eraser")}</h5>
                            )}
                          </button>
                        </div>
                      </div>
                      {toolsetRedux?.editToolsetSelection === 2 && (
                        <div className="toolset__section__tools__main__imagec__selection__main">
                          <div className="toolset__section__tools__main__imagec__CNSwitch">
                            <div
                              className="toolset__section__tools__main__imagec__CNSwitch__heading"
                              style={{ position: "relative" }}
                            >
                              <h4>{t("shape_control_image")}</h4>
                            </div>
                          </div>
                          <Popover
                            overlayClassName="cn-upload-popover"
                            content={
                              <ul>
                                {cnContentLines?.map((item, index) => (
                                  <li key={index}> {item?.trim()}</li>
                                ))}
                              </ul>
                            }
                            arrow={true}
                            trigger="hover"
                            placement="left"
                            open={open7}
                            // onOpenChange={handleHoverOpen1}
                          >
                            <div className="toolset__section__tools__main__CNimageSec">
                              <div className="toolset__section__tools__main__CNimageSec__base-img">
                                <div
                                  className="toolset__section__tools__main__CNimageSec__base-img__heading"
                                  style={{ position: "relative" }}
                                  key={controlFocus}
                                >
                                  <div
                                    className={`card ${
                                      controlFocus?.id === 4 &&
                                      controlFocus?.bool
                                        ? "focus"
                                        : ""
                                    }`}
                                    onDragOver={handleDragOver}
                                    onDrop={(e) => handleDrop(e, 10)}
                                  >
                                    <input
                                      key={inputKey}
                                      type="file"
                                      accept=".jpeg, .jpg, .png, .webp"
                                      id="fileInputCNImage"
                                      style={{ display: "none" }}
                                      onChange={handleFileChangeWithToolsetInput(
                                        10
                                      )}
                                      disabled={
                                        !editToolsetRedux?.feedImageBase64
                                      }
                                    />
                                    <label
                                      {...(!isPlugin
                                        ? { htmlFor: "fileInputCNImage" }
                                        : {})}
                                      style={{
                                        cursor:
                                          !editToolsetRedux?.uploadedBaseImage
                                            ? "not-allowed"
                                            : (editToolsetRedux?.cnImage &&
                                                !editToolsetRedux?.cnImageBase64) ||
                                              !editToolsetRedux?.feedImageBase64
                                            ? "progress"
                                            : "pointer",
                                      }}
                                    >
                                      {editToolsetRedux?.feedImageBase64 &&
                                        editToolsetRedux?.cnImageBase64 &&
                                        isPlugin && (
                                          <Popover
                                            overlayClassName="plugin-screenshot-popover"
                                            content={t("update_model")}
                                            arrow={true}
                                            trigger="hover"
                                            placement="top"
                                            open={open5}
                                            onOpenChange={handleOpen5}
                                          >
                                            <div
                                              className="refresh-icon"
                                              onClick={() => handlePlugin(10)}
                                            >
                                              <img
                                                className="refresh-active"
                                                src={refreshicona}
                                                alt="refresh-icon"
                                              />
                                              <img
                                                className="refresh-hover"
                                                src={refreshiconh}
                                                alt="refresh-icon"
                                              />
                                              <img
                                                className="refresh-default"
                                                src={refreshicon}
                                                alt="refresh-icon"
                                              />
                                            </div>
                                          </Popover>
                                        )}
                                      {editToolsetRedux?.feedImageBase64 &&
                                        editToolsetRedux?.cnImageBase64 &&
                                        isPlugin && (
                                          <Popover
                                            overlayClassName="plugin-screenshot-popover"
                                            content={t("delete")}
                                            arrow={true}
                                            trigger="hover"
                                            placement="top"
                                            open={open6}
                                            onOpenChange={handleOpen6}
                                          >
                                            <div
                                              className="delete-icon"
                                              onClick={() => deleteUploaded(10)}
                                            >
                                              <img
                                                className="delete-active"
                                                src={deleteicona}
                                                alt="delete-icon"
                                              />
                                              <img
                                                className="delete-hover"
                                                src={deleteiconh}
                                                alt="delete-icon"
                                              />
                                              <img
                                                className="delete-default"
                                                src={deleteicon}
                                                alt="delete-icon"
                                              />
                                            </div>
                                          </Popover>
                                        )}
                                      <div
                                        className="card-content"
                                        onDragOver={handleDragOver}
                                        onDrop={(e) => handleDrop(e, 10)}
                                        style={{
                                          justifyContent: `${
                                            editToolsetRedux?.cnImage
                                              ? "Center"
                                              : "FlexEnd"
                                          }`,
                                        }}
                                      >
                                        {editToolsetRedux?.cnImage ? (
                                          <>
                                            <img
                                              className="uploaded"
                                              src={editToolsetRedux?.cnImage}
                                              draggable="false"
                                            />
                                            {editToolsetRedux?.cnImageBase64 ===
                                              null ||
                                            editToolsetRedux?.cnImageBase64 ===
                                              undefined ||
                                            !editToolsetRedux?.feedImageBase64 ? (
                                              <>
                                                <div className="gradient-mask"></div>
                                                <div className="text-display">
                                                  {t("uploading...")}
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <label
                                              style={{
                                                cursor:
                                                  !editToolsetRedux?.uploadedBaseImage
                                                    ? "not-allowed"
                                                    : (editToolsetRedux?.cnImage &&
                                                        !editToolsetRedux?.cnImageBase64) ||
                                                      !editToolsetRedux?.feedImageBase64
                                                    ? "progress"
                                                    : "pointer",
                                              }}
                                              className={`card-content__sec ${
                                                isPlugin ? "plugin" : ""
                                              }`}
                                              htmlFor="fileInputCNImage"
                                            >
                                              <img
                                                className={`icon ${
                                                  controlFocus?.id === 4 &&
                                                  controlFocus?.bool
                                                    ? "focus"
                                                    : ""
                                                }`}
                                                src={
                                                  controlFocus?.id === 4 &&
                                                  controlFocus?.bool
                                                    ? controlanim
                                                    : baseicon
                                                }
                                                alt=""
                                              />
                                              <h5>{t("click_upload")}</h5>
                                            </label>
                                            {isPlugin && (
                                              <div
                                                className={`card-content__sec ${
                                                  isPlugin ? "plugin" : ""
                                                }`}
                                                onClick={() =>
                                                  editToolsetRedux?.uploadedBaseImage
                                                    ? handlePlugin(10)
                                                    : null
                                                }
                                              >
                                                <img
                                                  className={`icon`}
                                                  src={screenshot}
                                                  alt=""
                                                />
                                                <h5>{t("model_screenshot")}</h5>
                                              </div>
                                            )}
                                            <p>{t("image_cn_image_text1")}</p>
                                          </>
                                        )}
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Popover>
                          <div className="toolset__section__tools__main__imagec__base-img__camera">
                            <div className="toolset__section__tools__main__imagec__imagetype">
                              <div className="toolset__section__tools__main__imagec__imagetype__main">
                                <div className="toolset__section__tools__main__design__fidelity__heading">
                                  <div
                                    className="toolset__section__tools__main__design__fidelity__heading__left"
                                    style={{ position: "relative" }}
                                  >
                                    <h4>{t("control_level")}</h4>
                                    {!responsiveData && (
                                      <div
                                        className="tooltip-top"
                                        onClick={(event) =>
                                          handleActiveLearn(34, event)
                                        }
                                      >
                                        <img src={intro} alt="intro" />
                                        <Portal
                                          style={{
                                            display: `${
                                              activeLearnBool?.boolean ===
                                                true &&
                                              activeLearnBool?.id === 34
                                                ? "block"
                                                : "none"
                                            }`,
                                          }}
                                        >
                                          <LearnPopups
                                            heading={t("control_level")}
                                            content={t("hover_control_content")}
                                            styles="80px"
                                            image={
                                              languageActive === "ch"
                                                ? controlLevelCh
                                                : controlLevelEn
                                            }
                                            position={activeLearnBool?.position}
                                          />
                                        </Portal>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="toolset__section__tools__main__imagec__imagetype__slider">
                                  <Slider
                                    value={
                                      toolsetRedux?.editControlId ||
                                      activeMaskFidelityLevel
                                    }
                                    step={1}
                                    min={1}
                                    max={9}
                                    onChange={handleMaskSliderChange}
                                  />
                                  <span>
                                    {toolsetRedux?.editControlId ||
                                      activeMaskFidelityLevel}
                                  </span>
                                </div>
                              </div>
                              <div className="toolset__section__tools__main__imagec__fidelity">
                                <div
                                  className="toolset__section__tools__main__imagec__fidelity__heading"
                                  onClick={handleImageFidelity}
                                >
                                  <div
                                    className="toolset__section__tools__main__imagec__fidelity__heading__left"
                                    style={{ position: "relative" }}
                                  >
                                    <h4>{t("preserve_material")}</h4>
                                    {!responsiveData && (
                                      <div
                                        className="tooltip-top"
                                        onClick={(event) =>
                                          handleActiveLearn(26, event)
                                        }
                                      >
                                        <img src={intro} alt="intro" />
                                        <Portal
                                          style={{
                                            display: `${
                                              activeLearnBool?.boolean ===
                                                true &&
                                              activeLearnBool?.id === 26
                                                ? "block"
                                                : "none"
                                            }`,
                                          }}
                                        >
                                          <LearnPopups
                                            heading={t("preserve_material")}
                                            content={t("modification_tooltip1")}
                                            styles={
                                              languageActive === "ch"
                                                ? "60px"
                                                : "60px"
                                            }
                                            image={modificationLevelImage}
                                            position={activeLearnBool?.position}
                                          />
                                        </Portal>
                                      </div>
                                    )}
                                  </div>
                                  <div className="toolset__section__tools__main__imagec__fidelity__heading__right">
                                    <Switch
                                      onChange={handleImageFidelity}
                                      checked={
                                        toolsetRedux?.editPreserve ||
                                        imageFidelity
                                          ? true
                                          : false
                                      }
                                      height={responsiveData ? 14 : 16}
                                      width={responsiveData ? 28 : 32}
                                      handleDiameter={12}
                                      offColor="#A8ADAF"
                                      onColor="#000"
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      className={`${
                                        responsiveData
                                          ? toolsetRedux?.editPreserve ||
                                            imageFidelity
                                            ? "small-on"
                                            : "small-off"
                                          : ""
                                      } react-switch-Undragable`}
                                    />
                                  </div>
                                </div>
                                {(toolsetRedux?.editPreserve ||
                                  imageFidelity) && (
                                  <div className="toolset__section__tools__main__imagec__fidelity__sec">
                                    <div className="toolset__section__tools__main__imagec__fidelity__sec__sub">
                                      <Slider
                                        value={
                                          toolsetRedux?.editPreserveId ||
                                          activeImageFidelityLevel
                                        }
                                        step={1}
                                        min={1}
                                        max={9}
                                        onChange={handleImageSliderChange}
                                      />
                                      <span>
                                        {toolsetRedux?.editPreserveId ||
                                          activeImageFidelityLevel}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="toolset__section__tools__main__imagec__image-count">
                        <div className="toolset__section__tools__main__imagec__image-count__heading">
                          <h4>{t("image_count")}</h4>
                        </div>
                        <div className="toolset__section__tools__main__imagec__image-count__sec">
                          <div
                            className={`toolset__section__tools__main__imagec__image-count__sec__sub ${
                              inspireToolsetRedux?.imageCount === 1
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleImageCount(1)}
                          >
                            <h6>1</h6>
                          </div>
                          <div
                            className={`toolset__section__tools__main__imagec__image-count__sec__sub ${
                              inspireToolsetRedux?.imageCount === 2
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleImageCount(2)}
                          >
                            <h6>2</h6>
                          </div>
                          <div
                            className={`toolset__section__tools__main__imagec__image-count__sec__sub ${
                              inspireToolsetRedux?.imageCount === 3
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleImageCount(3)}
                          >
                            <h6>3</h6>
                          </div>
                          <div
                            className={`toolset__section__tools__main__imagec__image-count__sec__sub ${
                              inspireToolsetRedux?.imageCount === 4
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleImageCount(4)}
                          >
                            <h6>4</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="toolset__section__tools__main__upscale">
                      <div className="toolset__section__tools__main__upscale__base-img">
                        <div
                          className="toolset__section__tools__main__upscale__base-img__heading"
                          style={{ position: "relative" }}
                        >
                          <h4>{t("base_image")}</h4>
                          {/* {!responsiveData && (
                            <div
                              className="tooltip-top"
                              onClick={(event) => handleActiveLearn(11, event)}
                            >
                              <img src={intro} alt="intro" />
                              <Portal
                                style={{
                                  display: `${
                                    activeLearnBool?.boolean === true &&
                                    activeLearnBool?.id === 11
                                      ? "block"
                                      : "none"
                                  }`,
                                }}
                              >
                                <LearnPopups
                                  heading={t("low_definition_image")}
                                  content={t("upscale_tooltip")}
                                  styles="260px"
                                  image={upscaleimage}
                                  position={activeLearnBool?.position}
                                />
                              </Portal>
                            </div>
                          )} */}
                        </div>
                        <div
                          className={`card ${
                            controlFocus?.id === 3 && controlFocus?.bool
                              ? "focus"
                              : ""
                          }`}
                          onDragOver={handleDragOver}
                          onDrop={(e) => handleDrop(e, 3)}
                        >
                          <input
                            key={inputKey}
                            type="file"
                            accept=".jpeg, .jpg, .png, .webp"
                            id="fileInput3"
                            style={{ display: "none" }}
                            onChange={handleFileChangeWithToolsetInput(3)}
                          />
                          <label
                            htmlFor="fileInput3"
                            style={{
                              cursor:
                                upscaleToolsetRedux?.isUpScaleURLImgUrl &&
                                !upscaleToolsetRedux?.feedUpBase64
                                  ? "progress"
                                  : "pointer",
                            }}
                          >
                            <div
                              className="card-content"
                              onDragOver={handleDragOver}
                              onDrop={(e) => handleDrop(e, 3)}
                              style={{
                                justifyContent: `${
                                  upscaleToolsetRedux?.isUpScaleURLImgUrl
                                    ? "Center"
                                    : "FlexEnd"
                                }`,
                              }}
                            >
                              {upscaleToolsetRedux?.isUpScaleURLImgUrl ? (
                                <>
                                  {upscaleToolsetRedux?.toolset === 1 ? (
                                    <div
                                      className="image-back"
                                      style={{
                                        padding: extendPaddingStyle,
                                      }}
                                    >
                                      <img
                                        ref={imgRef}
                                        className="uploaded"
                                        src={
                                          upscaleToolsetRedux?.isUpScaleURLImgUrl
                                        }
                                        draggable="false"
                                      />
                                    </div>
                                  ) : (
                                    <img
                                      style={{ objectFit: "contain" }}
                                      ref={imgRef}
                                      className="uploaded"
                                      src={
                                        upscaleToolsetRedux?.isUpScaleURLImgUrl
                                      }
                                      draggable="false"
                                    />
                                  )}
                                  {upscaleToolsetRedux?.feedUpBase64 === null ||
                                  undefined ? (
                                    <>
                                      <div className="gradient-mask"></div>
                                      <div className="text-display">
                                        {t("uploading...")}
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                <>
                                  <img
                                    className={`icon ${
                                      controlFocus?.id === 3 &&
                                      controlFocus?.bool
                                        ? "focus"
                                        : ""
                                    }`}
                                    src={
                                      controlFocus?.id === 3 &&
                                      controlFocus?.bool
                                        ? controlanim
                                        : baseicon
                                    }
                                    alt=""
                                  />
                                  <p>{t("upscale_base_image")}</p>
                                </>
                              )}
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="toolset__section__tools__main__upscale__selection">
                        <div className="toolset__section__tools__main__upscale__selection__sub">
                          <button
                            className={`toolset__section__tools__main__upscale__selection__sub__sec ${
                              upscaleToolsetRedux?.toolset === 1 ? "active" : ""
                            }`}
                            onClick={() => handleEnhanceSelection(1)}
                            onMouseEnter={(event) => handleMouseEnter(2, event)}
                            onMouseLeave={(event) => handleMouseLeave(2, event)}
                          >
                            <img
                              className="imga"
                              src={extendi}
                              alt="image-icon"
                            />
                            <img
                              className="imgh"
                              src={extendh}
                              alt="image-icon"
                            />
                            <img
                              className="imgd"
                              src={extendd}
                              alt="image-icon"
                            />
                            {upscaleToolsetRedux?.toolset === 1 && (
                              <h5>{t("enhance_option1")}</h5>
                            )}
                            <Portal
                              style={{
                                display: `${
                                  activeLearnBool1?.boolean === true &&
                                  activeLearnBool1?.id === 2
                                    ? "block"
                                    : "none"
                                }`,
                              }}
                            >
                              <LearnPopups
                                heading={t("enhance_option1")}
                                content={t("enhance_option1_content")}
                                styles="0px"
                                image={extendinfo}
                                position={activeLearnBool1?.position}
                              />
                            </Portal>
                          </button>
                          <button
                            className={`toolset__section__tools__main__upscale__selection__sub__sec  ${
                              upscaleToolsetRedux?.toolset === 2 ? "active" : ""
                            }`}
                            onClick={() => handleEnhanceSelection(2)}
                            onMouseEnter={(event) => handleMouseEnter(3, event)}
                            onMouseLeave={(event) => handleMouseLeave(3, event)}
                          >
                            <img className="imga" src={HDi} alt="image-icon" />
                            <img className="imgh" src={HDh} alt="image-icon" />
                            <img className="imgd" src={HDd} alt="image-icon" />
                            {upscaleToolsetRedux?.toolset === 2 && (
                              <h5>{t("enhance_option2")}</h5>
                            )}
                            <Portal
                              style={{
                                display: `${
                                  activeLearnBool1?.boolean === true &&
                                  activeLearnBool1?.id === 3
                                    ? "block"
                                    : "none"
                                }`,
                              }}
                            >
                              <LearnPopups
                                heading={t("enhance_option2")}
                                content={t("enhance_option2_content")}
                                styles="0px"
                                image={
                                  languageActive === "ch"
                                    ? upscaleinfoCh
                                    : upscaleinfoEn
                                }
                                position={activeLearnBool1?.position}
                              />
                            </Portal>
                          </button>
                          <button
                            className={`toolset__section__tools__main__upscale__selection__sub__sec  ${
                              upscaleToolsetRedux?.toolset === 3 ? "active" : ""
                            }`}
                            onClick={() => handleEnhanceSelection(3)}
                            onMouseEnter={(event) => handleMouseEnter(4, event)}
                            onMouseLeave={(event) => handleMouseLeave(4, event)}
                          >
                            <img
                              className="imga"
                              src={atmospherei}
                              alt="image-icon"
                            />
                            <img
                              className="imgh"
                              src={atmosphereh}
                              alt="image-icon"
                            />
                            <img
                              className="imgd"
                              src={atmosphered}
                              alt="image-icon"
                            />
                            <h6 className="updated">{t("beta")}</h6>
                            {upscaleToolsetRedux?.toolset === 3 && (
                              <h5
                                dangerouslySetInnerHTML={{
                                  __html: t("enhance_option3_n"),
                                }}
                              ></h5>
                            )}
                            <Portal
                              style={{
                                display: `${
                                  activeLearnBool1?.boolean === true &&
                                  activeLearnBool1?.id === 4
                                    ? "block"
                                    : "none"
                                }`,
                              }}
                            >
                              <LearnPopups
                                heading={t("enhance_option3")}
                                content={t("enhance_option3_content")}
                                styles="0px"
                                image={atmosphereinfo[languageActive]}
                                position={activeLearnBool1?.position}
                              />
                            </Portal>
                          </button>
                        </div>
                      </div>
                      {upscaleToolsetRedux?.toolset === 1 && (
                        <div className="toolset__section__tools__main__upscale__extend__slider">
                          <Slider
                            value={currentValue}
                            step={1}
                            min={-4}
                            max={4}
                            onChange={handleExtendSliderChange}
                            marks={labels}
                            trackStyle={{
                              backgroundColor: trackColor,
                            }}
                            handleStyle={{
                              borderColor: trackColor,
                            }}
                            railStyle={{
                              backgroundColor: "#ccc",
                            }}
                            tooltip={{
                              formatter: tooltipFormatter,
                            }}
                            dotStyle={{
                              borderColor: "#677074",
                              borderWidth: "1px",
                            }}
                          />
                          <span>{extendValue}</span>
                        </div>
                      )}
                      {upscaleToolsetRedux?.toolset === 2 && (
                        <div className="toolset__section__tools__main__upscale__hd">
                          <div className="toolset__section__tools__main__upscale__hd__options">
                            <div
                              className={`toolset__section__tools__main__upscale__hd__options__sec ${
                                upscaleToolsetRedux?.isUpscaleId === 1
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => handleUpscaleType(1)}
                            >
                              <h5>{t("hd_option1")}</h5>
                            </div>
                            <div
                              className={`toolset__section__tools__main__upscale__hd__options__sec ${
                                upscaleToolsetRedux?.isUpscaleId === 2
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => handleUpscaleType(2)}
                            >
                              <h5>{t("hd_option2")}</h5>
                            </div>
                            <div
                              className={`toolset__section__tools__main__upscale__hd__options__sec ${
                                upscaleToolsetRedux?.isUpscaleId === 3
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => handleUpscaleType(3)}
                            >
                              <h5>{t("hd_option3")}</h5>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="toolset__section__tools__main__video-ai">
                      <div className="toolset__section__tools__main__video-ai__base-img">
                        <div
                          className="toolset__section__tools__main__video-ai__base-img__heading"
                          style={{ position: "relative" }}
                        >
                          <h4>{t("first_frame")}</h4>
                          {!responsiveData && (
                            <div
                              className="tooltip-top"
                              onClick={(event) => handleActiveLearn(41, event)}
                            >
                              <img src={intro} alt="intro" />
                              <Portal
                                style={{
                                  display: `${
                                    activeLearnBool?.boolean === true &&
                                    activeLearnBool?.id === 41
                                      ? "block"
                                      : "none"
                                  }`,
                                }}
                              >
                                <LearnPopups
                                  heading={t("first_frame")}
                                  content={t("first_frame_tip1")}
                                  styles={
                                    languageActive === "ch" ? "220px" : "220px"
                                  }
                                  image={video}
                                  position={activeLearnBool?.position}
                                />
                              </Portal>
                            </div>
                          )}
                        </div>
                        <div
                          className={`card ${
                            controlFocus?.id === 5 && controlFocus?.bool
                              ? "focus"
                              : ""
                          }`}
                          onDragOver={handleDragOver}
                          onDrop={(e) => handleDrop(e, 4)}
                        >
                          <input
                            key={inputKey}
                            type="file"
                            accept=".jpeg, .jpg, .png, .webp"
                            id="fileInput4"
                            style={{ display: "none" }}
                            onChange={handleFileChangeWithToolsetInput(4)}
                          />
                          <label
                            htmlFor="fileInput4"
                            style={{
                              cursor:
                                (videoToolsetRedux?.uploadedBaseImageUrl &&
                                  !videoToolsetRedux?.feedDesignBase64) ||
                                videoToolsetRedux?.uploading
                                  ? "progress"
                                  : "pointer",
                            }}
                          >
                            <div
                              className="card-content"
                              onDragOver={handleDragOver}
                              onDrop={(e) => handleDrop(e, 4)}
                            >
                              {videoToolsetRedux?.uploadedBaseImageUrl ||
                              videoToolsetRedux?.uploading ? (
                                <>
                                  <img
                                    className="uploaded"
                                    src={
                                      videoToolsetRedux?.uploadedBaseImageUrl
                                    }
                                    draggable="false"
                                  />
                                  {videoToolsetRedux?.feedDesignBase64 ===
                                    null ||
                                  videoToolsetRedux?.feedDesignBase64 ===
                                    undefined ||
                                  videoToolsetRedux?.uploading ? (
                                    <>
                                      <div className="gradient-mask"></div>
                                      <div className="text-display">
                                        {t("uploading...")}
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                <>
                                  <img
                                    className={`icon ${
                                      controlFocus?.id === 5 &&
                                      controlFocus?.bool
                                        ? "focus"
                                        : ""
                                    }`}
                                    src={
                                      controlFocus?.id === 5 &&
                                      controlFocus?.bool
                                        ? controlanim
                                        : baseicon
                                    }
                                    alt=""
                                  />
                                  <p className="first">
                                    {t("first_frame_placeholder")}
                                    {/* <br />
                                    {t("first_frame_placeholder1")}
                                    <br />
                                    {t("first_frame_placeholder2")}
                                    <br /> */}
                                  </p>
                                </>
                              )}
                            </div>
                          </label>
                        </div>
                        {errorMessage && (
                          <p>
                            {t("note")} : {errorMessage}
                          </p>
                        )}
                      </div>
                      {/* {!videoToolsetRedux?.feedDesignBase64 && (
                        <div className="toolset__section__tools__main__video-ai__tip">
                          <img src={videoTip} alt="tip-icon" />
                          <h5>{t("video_tip")}</h5>
                        </div>
                      )} */}
                      {videoToolsetRedux?.feedDesignBase64 && (
                        <div className="toolset__section__tools__main__video-ai__camera">
                          <div
                            className="toolset__section__tools__main__video-ai__camera__heading"
                            onClick={handleCameraMovement}
                          >
                            <div className="toolset__section__tools__main__video-ai__camera__heading__left">
                              <h4>{t("camera_movement")}</h4>
                            </div>
                            <div className="toolset__section__tools__main__video-ai__camera__heading__right">
                              <Switch
                                onChange={handleCameraMovement}
                                checked={videoToolsetRedux?.bool ? true : false}
                                height={responsiveData ? 14 : 16}
                                width={responsiveData ? 28 : 32}
                                handleDiameter={12}
                                offColor="#A8ADAF"
                                onColor="#000"
                                uncheckedIcon={false}
                                checkedIcon={false}
                                className={`${
                                  responsiveData
                                    ? videoToolsetRedux?.bool
                                      ? "small-on "
                                      : "small-off "
                                    : ""
                                }react-switch-Undragable`}
                              />
                            </div>
                          </div>
                          {videoToolsetRedux?.bool && (
                            <>
                              <div className="toolset__section__tools__main__video-ai__camera__options">
                                <div className="toolset__section__tools__main__video-ai__camera__options__heading">
                                  <h4>{t("camera_pan")}</h4>
                                </div>
                                <div className="toolset__section__tools__main__video-ai__camera__options__main">
                                  <div
                                    className={`toolset__section__tools__main__video-ai__camera__options__sec ${
                                      videoToolsetRedux?.id === 1
                                        ? " active"
                                        : ""
                                    }`}
                                    onClick={() => handleVideoCamera(1)}
                                  >
                                    <h5>{t("left")}</h5>
                                  </div>
                                  <div
                                    className={`toolset__section__tools__main__video-ai__camera__options__sec ${
                                      videoToolsetRedux?.id === 2
                                        ? " active"
                                        : ""
                                    }`}
                                    onClick={() => handleVideoCamera(2)}
                                  >
                                    <h5>{t("right")}</h5>
                                  </div>
                                </div>
                              </div>
                              <div className="toolset__section__tools__main__video-ai__camera__options">
                                <div className="toolset__section__tools__main__video-ai__camera__options__heading">
                                  <h4>{t("camera_tilt")}</h4>
                                </div>
                                <div className="toolset__section__tools__main__video-ai__camera__options__main">
                                  <div
                                    className={`toolset__section__tools__main__video-ai__camera__options__sec ${
                                      videoToolsetRedux?.id === 3
                                        ? " active"
                                        : ""
                                    }`}
                                    onClick={() => handleVideoCamera(3)}
                                  >
                                    <h5>{t("up")}</h5>
                                  </div>
                                  <div
                                    className={`toolset__section__tools__main__video-ai__camera__options__sec ${
                                      videoToolsetRedux?.id === 4
                                        ? " active"
                                        : ""
                                    }`}
                                    onClick={() => handleVideoCamera(4)}
                                  >
                                    <h5>{t("down")}</h5>
                                  </div>
                                </div>
                              </div>
                              <div className="toolset__section__tools__main__video-ai__camera__options">
                                <div className="toolset__section__tools__main__video-ai__camera__options__heading">
                                  <h4>{t("camera_zoom")}</h4>
                                </div>
                                <div className="toolset__section__tools__main__video-ai__camera__options__main">
                                  <div
                                    className={`toolset__section__tools__main__video-ai__camera__options__sec ${
                                      videoToolsetRedux?.id === 7
                                        ? " active"
                                        : ""
                                    }`}
                                    onClick={() => handleVideoCamera(7)}
                                  >
                                    <h5>{t("zoom_in")}</h5>
                                  </div>
                                  <div
                                    className={`toolset__section__tools__main__video-ai__camera__options__sec ${
                                      videoToolsetRedux?.id === 8
                                        ? " active"
                                        : ""
                                    }`}
                                    onClick={() => handleVideoCamera(8)}
                                  >
                                    <h5>{t("zoom_out")}</h5>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </TabPanel>
                </div>
              </Tabs>
            </div>
          </div>
        </div>
        {pluginPopup && (
          <Confirm
            isCancelRequired={false}
            confirmTitle={t("plugin_confirm_heading")}
            content={t("plugin_confirm_content")}
            onConfirm={() => closePluginPopup()}
            buttonText={t("ok")}
          />
        )}
      </div>
    </div>
  );
}

export default ToolSetComponent;
