/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "./TutorialVideo.scss";
import loader from "../../assets/images/workspace/upscale-gif.gif";
import arrow from "../../assets/images/icons/faq-arrow1.png";
import playd from "../../assets/images/icons/tutorial-play-d.png";
import playh from "../../assets/images/icons/tutorial-play-h.png";
import playa from "../../assets/images/icons/tutorial-play-a.png";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";
import { useParams } from "react-router-dom";
import { GetTutorialDetailsById } from "../../utils/apiCalls";

const TutorialVideo = () => {
  const { id } = useParams();
  const tutorialsApiRef = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeVideo, setAvtiveVideo] = useState(null);
  const [tutorials, setTutorials] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [expand, setExpand] = useState();

  const handleActiveVideo = (vid) => {
    setAvtiveVideo(null);
    setTimeout(() => {
      setAvtiveVideo(vid);
    }, 100);
  };

  useEffect(() => {
    if (!tutorialsApiRef.current) {
      tutorialsApiRef.current = true;
      getTutorialDetailsById();
    }
    return () => {
      tutorialsApiRef.current = false;
    };
  }, []);

  const transformChapters = (chapters) => {
    return chapters?.map((chapter) => ({
      id: chapter?.id,
      name: chapter?.name,
      completed: false,
    }));
  };

  const getTutorialDetailsById = () => {
    const lang = localStorage.getItem("language");
    const chinese = lang === "ch" ? "chs" : lang;
    const headingId = "6678f60be29910413e1fbad3";
    setIsLoader(true);
    GetTutorialDetailsById(
      (res) => {
        if (res?.status === 200) {
          setIsLoader(false);
          setTutorials(res?.data);
          setAvtiveVideo(res?.data[0]?.card?.chapters[0]?.subChapters[0]);
          const chapters = res?.data[0]?.card?.chapters;
          const transformedChapters = transformChapters(chapters);
          setExpand(transformedChapters);
        } else {
          setIsLoader(false);
          setTutorials([]);
        }
      },
      chinese,
      headingId,
      id
    );
  };

  const handleExpand = (name) => {
    setExpand((prevExpand) =>
      prevExpand?.map((chapter) =>
        chapter?.name === name
          ? { ...chapter, completed: !chapter?.completed }
          : chapter
      )
    );
  };

  const findCompletedStatus = (id) => {
    const chapter = expand?.find((chap) => chap?.id === id);
    return chapter ? chapter?.completed : false;
  };

  // const getVideoDuration = (url) => {
  //   return new Promise((resolve) => {
  //     const video = document.createElement("video");
  //     video.preload = "metadata";

  //     video.onloadedmetadata = () => {
  //       window.URL.revokeObjectURL(video.src);
  //       resolve(video.duration);
  //     };

  //     video.src = url;
  //   });
  // };

  // const [videoDurations, setVideoDurations] = useState({});

  // useEffect(() => {
  //   const fetchDurations = async () => {
  //     const durations = {};
  //     for (const chap of tutorials[0]?.card?.chapters || []) {
  //       for (const vid of chap?.subChapters || []) {
  //         const duration = await getVideoDuration(vid.link);
  //         const minutes = Math.floor(duration / 60);
  //         const seconds = Math.floor(duration % 60);
  //         const formattedDuration = `${minutes}:${seconds
  //           .toString()
  //           .padStart(2, "0")}`;
  //         durations[vid.name] = formattedDuration;
  //       }
  //     }
  //     setVideoDurations(durations);
  //   };
  //   fetchDurations();
  // }, [tutorials]);

  return (
    <UserPortalLayout>
      <div className="tutorial-video">
        <div className="tutorial-video__container">
          {!isLoader ? (
            <div className="tutorial-video__container__sections">
              <div className="tutorial-video__container__video">
                {!isLoaded && !activeVideo && (
                  <div className="tutorial-video__container__video__loader">
                    <img
                      className="loader-gif"
                      src={loader}
                      alt="Video Thumbnail"
                    />
                  </div>
                )}
                <div className="tutorial-video__container__video__main">
                  {/* <video
                    key={activeVideo}
                    style={{ display: isLoaded ? "block" : "none" }}
                    controls
                    autoPlay
                    // loop
                    // muted
                    playsInline
                    className="instruct__element"
                    onLoadedData={() => setIsLoaded(true)}
                  >
                    <source src={activeVideo?.link} type="video/mp4" />
                  </video> */}
                  <iframe
                    key={activeVideo}
                    src={activeVideo?.link}
                    // allow="autoplay; encrypted-media"
                    allowFullScreen
                    className="instruct__element"
                    title={`tutorial-video}`}
                    onLoadedData={() => setIsLoaded(true)}
                  ></iframe>
                  {/* {isLoaded ? ( */}
                  <div className="tutorial-video__container__video__content">
                    <h2>{activeVideo?.name}</h2>
                    <h4>{activeVideo?.description}</h4>
                  </div>
                  {/* ) : (
                    <div className="tutorial-video__container__video__loader">
                      <img
                        className="loader-gif"
                        src={loader}
                        alt="Video Thumbnail"
                      />
                    </div>
                  )} */}
                </div>
              </div>
              <div className="tutorial-video__container__data">
                <div
                  className="tutorial-video__container__data__videos"
                  data-draggable="true"
                >
                  {tutorials[0]?.card?.chapters?.map((chap, index) => {
                    const completed = findCompletedStatus(chap?.id);
                    return (
                      <div
                        className="tutorial-video__container__data__videos__section"
                        key={index}
                      >
                        <div
                          className={`tutorial-video__container__data__videos__section__heading ${
                            completed ? "active" : ""
                          }`}
                          onClick={() => handleExpand(chap?.name)}
                        >
                          <div className="tutorial-video__container__data__videos__section__heading__content">
                            <h2>{chap?.name}</h2>
                          </div>
                          <div
                            className={`tutorial-video__container__data__videos__section__heading__icon ${
                              completed ? "active" : ""
                            }`}
                          >
                            <img src={arrow} alt="arrow-icon" />
                          </div>
                        </div>
                        {!completed && (
                          <div className="tutorial-video__container__data__videos__section__list">
                            {chap?.subChapters?.map((vid, index) => {
                              const active = activeVideo === vid;
                              // const duration =
                              //   videoDurations[vid.name] || "...loading";
                              return (
                                <div
                                  className={`tutorial-video__container__data__videos__section__list__sec ${
                                    active ? "active" : ""
                                  }`}
                                  key={index}
                                  onClick={() => handleActiveVideo(vid)}
                                >
                                  <div className="tutorial-video__container__data__videos__section__list__sec__left left">
                                    <h2>{vid?.name}</h2>
                                  </div>
                                  <div className="tutorial-video__container__data__videos__section__list__sec__right right">
                                    {/* <h4>{duration}</h4> */}
                                    <img
                                      className="play-d"
                                      src={playd}
                                      alt="play-icon"
                                    />
                                    <img
                                      className="play-h"
                                      src={playh}
                                      alt="play-icon"
                                    />
                                    <img
                                      className="play-a"
                                      src={playa}
                                      alt="play-icon"
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div className="tutorial-video__container__video__loader">
              <img className="loader-gif" src={loader} alt="Video Thumbnail" />
            </div>
          )}
        </div>
      </div>
    </UserPortalLayout>
  );
};

export default TutorialVideo;
