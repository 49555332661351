import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "./App/i18n";
import { AuthDataProvider } from "./App/store/UserAuthData";
import { UserProvider } from "./App/store/UserDetailsStore";
import { ToolsetDataProvider } from "./App/store/WorkSpaceToolsetData";
import { DesignLibraryProvider } from "./App/store/DesignLibraryData";
import { CurrentProjectProvider } from "./App/store/CurrentProjectData";
import { ResponsiveDataProvider } from "./App/store/ResponsiveData";
import { ClientDataProvider } from "./App/store/ClientData";
import { UserSocketProvider } from "./App/store/UserSocketData";
import { TeamDesignProvider } from "./App/store/TeamDesignData";
import { AtmosphereRefProvider } from "./App/store/AtmosphereRefData";
import { AdminProvider } from "./App/store/AdminDetailsStore";
import { store, persistor } from "./App/store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <AdminProvider>
            <UserProvider>
              <UserSocketProvider>
                <ClientDataProvider>
                  <TeamDesignProvider>
                    <ResponsiveDataProvider>
                      <AuthDataProvider>
                        <AtmosphereRefProvider>
                          <DesignLibraryProvider>
                            <ToolsetDataProvider>
                              <CurrentProjectProvider>
                                <App />
                              </CurrentProjectProvider>
                            </ToolsetDataProvider>
                          </DesignLibraryProvider>
                        </AtmosphereRefProvider>
                      </AuthDataProvider>
                    </ResponsiveDataProvider>
                  </TeamDesignProvider>
                </ClientDataProvider>
              </UserSocketProvider>
            </UserProvider>
          </AdminProvider>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
