import { createSlice } from "@reduxjs/toolkit";
 
 const designToolsetSlice = createSlice({
   name: "renderToolset",
   initialState: {
     renderToolsetData: {},
   },
   reducers: {
     updateRenderToolsetData: (state, action) => {
       const { projectId, data, port, isPlugin } = action.payload;
       if (!state[projectId]) {
         state[projectId] = {};
       }
       if (!state[projectId][port]) {
         state[projectId][port] = { renderToolsetData: {} };
       }
       if (isPlugin) {
         state[projectId][port].renderToolsetData = {
           ...state[projectId][port].renderToolsetData,
           ...JSON.parse(JSON.stringify(data)),
         };
       } else {
         state[projectId].renderToolsetData = {
           ...state[projectId].renderToolsetData,
           ...JSON.parse(JSON.stringify(data)),
         };
       }
     },
     clearRenderToolsetData: (state, action) => {
       const { projectId, port, isPlugin } = action.payload;
       if (isPlugin) {
         if (state[projectId][port]) {
           state[projectId][port].renderToolsetData = {};
         }
       } else {
         if (state[projectId]) {
           state[projectId].renderToolsetData = {};
         }
       }
     },
   },
 });
 
 export const { updateRenderToolsetData, clearRenderToolsetData } =
   designToolsetSlice.actions;
 export default designToolsetSlice.reducer;