import React from "react";
import "./MembershipUpgrade.scss";
import successicon from "../../../assets/images/icons/upgrade-notification.png";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { UserSocketContext } from "../../../store/UserSocketData";

const MembershipUpgrade = () => {
  const { t } = useTranslation();
  const {
    subscriptionUpgradeContent,
    setIsSubscriptionUpgrade,
    setSubscriptionUpgradeContent,
    handleShowSubscription,
  } = useContext(UserSocketContext);

  const langnuage = localStorage.getItem("language");

  const mainHeading =
    langnuage !== "ch"
      ? subscriptionUpgradeContent[1]
      : subscriptionUpgradeContent[4];
  const heading =
    langnuage !== "ch"
      ? subscriptionUpgradeContent[2]
      : subscriptionUpgradeContent[5];
  const content =
    langnuage !== "ch"
      ? subscriptionUpgradeContent[3]
      : subscriptionUpgradeContent[6];


  const updatedContent = content.replace(
    /<h6>/g,
    '<div style="display: flex; align-items: flex-start;"><span style="width: 24px; height: 24px; background: #d9d9d9; display: inline-block; margin-right: 8px;"></span><h6>'
  );
  const updatedContent1 = updatedContent.replace(/<\/h6>/g, "</h6></div>");

  const handleViewPlans = () => {
    setIsSubscriptionUpgrade(false);
    setSubscriptionUpgradeContent(null);
    handleShowSubscription();
  };

  return (
    <div className="plan-upgrade">
      <div className="plan-upgrade__container">
        <div className="plan-upgrade__sections">
          <div className="plan-upgrade__heading">
            <div className="plan-upgrade__heading__top">
              <img src={successicon} alt="upgrade-icon" />
              <h2>{mainHeading}</h2>
            </div>
            <div className="plan-upgrade__heading__bottom">
              {/* <span></span> */}
              <h5>{heading}</h5>
            </div>
          </div>
          <div
            className="plan-upgrade__content"
            dangerouslySetInnerHTML={{
              __html: updatedContent1,
            }}
          />
          <div className="plan-upgrade__buttons">
            <button className="success" onClick={handleViewPlans}>
              {t("view_plans")}
            </button>
            <button onClick={() => setIsSubscriptionUpgrade(false)}>
              {t("ok")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipUpgrade;
