import React, { useContext, useState } from "react";
import "./Galleries.scss";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";
import { useTranslation } from "react-i18next";
import Gallery from "../Gallery/Gallery";
import TeamGallery from "../TeamGallery/TeamGallery";
import CommunityGallery from "../CommunityGallery/CommunityGallery";
import { TeamDesignContext } from "../../store/TeamDesignData";
import { UserContext } from "../../store/UserDetailsStore";

const Galleries = () => {
  const { t } = useTranslation();
  const { teamsList } = useContext(TeamDesignContext);
  const { isUserMembershipDetails } = useContext(UserContext);
  const isActive = JSON.parse(localStorage.getItem("isGallery"));
  const [activeTab, setActiveTab] = useState(isActive?.isGallery ?? 1);
  const [teamGalleryDisable, setTeamGalleryDisable] = useState(false);
  const [teamGalleryDisable1, setTeamGalleryDisable1] = useState(false);

  const handleTabChange = (id) => {
    setActiveTab(id);
    const isGalley = {
      isGallery: id,
    };
    localStorage.setItem("isGallery", JSON.stringify(isGalley));
  };
  const disable = teamsList?.length === 1 ? true : false;
  const membership =
    isUserMembershipDetails?.membership?.accounts !== 1 ? true : false;

  const handleGalleryDisable = () => {
    setTeamGalleryDisable(true);
  };

  const handleGalleryDisable1 = () => {
    setTeamGalleryDisable1(true);
  };

  return (
    <UserPortalLayout>
      <div className="all-galleries">
        <div className="all-galleries__container">
          <div className="all-galleries__sections">
            <div className="all-galleries__header">
              <div className="all-galleries__header__tabs">
                <div
                  className={`all-galleries__header__tab ${
                    activeTab === 1 ? "active" : ""
                  }`}
                  onClick={() => handleTabChange(1)}
                >
                  <h2>{t("my_gallery")}</h2>
                </div>
                <div
                  className={`all-galleries__header__tab ${
                    activeTab === 2 ? "active" : ""
                  } ${disable ? "disable" : ""}`}
                  onClick={
                    disable
                      ? membership
                        ? handleGalleryDisable1
                        : handleGalleryDisable
                      : () => handleTabChange(2)
                  }
                >
                  <h2>{t("team_gallery")}</h2>
                </div>
                <div
                  className={`all-galleries__header__tab ${
                    activeTab === 3 ? "active" : ""
                  }`}
                  onClick={() => handleTabChange(3)}
                >
                  <h2>{t("community_gallery")}</h2>
                </div>
              </div>
            </div>
            {activeTab === 1 && (
              <Gallery
                isActiveTab={activeTab}
                setTeamGalleryDisable={setTeamGalleryDisable}
                teamGalleryDisable={teamGalleryDisable}
                setTeamGalleryDisable1={setTeamGalleryDisable1}
                teamGalleryDisable1={teamGalleryDisable1}
              />
            )}
            {activeTab === 2 && <TeamGallery isActiveTab={activeTab} />}
            {activeTab === 3 && (
              <CommunityGallery
                isActiveTab={activeTab}
                setTeamGalleryDisable={setTeamGalleryDisable}
                teamGalleryDisable={teamGalleryDisable}
                setTeamGalleryDisable1={setTeamGalleryDisable1}
                teamGalleryDisable1={teamGalleryDisable1}
              />
            )}
          </div>
        </div>
      </div>
    </UserPortalLayout>
  );
};

export default Galleries;
