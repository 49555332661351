import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "redux";
import editToolsetReducer from "./slices/editToolsetSlice";
import renderToolsetReducer from "./slices/renderToolsetSlice";
import upscaleToolsetReducer from "./slices/upscaleToolsetSlice";
import videoToolsetReducer from "./slices/videoToolsetSlice";
import inspireToolsetReducer from "./slices/inspireToolsetSlice";
import toolsetReducer from "./slices/toolsetSlice";
import createIDBStorage from "redux-persist-indexeddb-storage";

const storage = createIDBStorage({
  name: "appStorage",
  storeName: "persistedData",
});

const persistConfig = {
  key: "root",
  storage,
};

const appReducer = combineReducers({
  renderToolset: renderToolsetReducer,
  editToolset: editToolsetReducer,
  upscaleToolset: upscaleToolsetReducer,
  videoToolset: videoToolsetReducer,
  inspireToolset: inspireToolsetReducer,
  toolset: toolsetReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_ALL') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
        ignoredPaths: ["register", "rehydrate"],
      },
    }),
});

export const resetAll = () => ({ type: 'RESET_ALL' });

export const persistor = persistStore(store);
