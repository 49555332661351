import React, { createContext, useState } from "react";

const AtmosphereRefContext = createContext();

function AtmosphereRefProvider({ children }) {
  const [atmosphereRefData, setAtmosphereRefData] = useState(null);
  const [atmosphereRefData1, setAtmosphereRefData1] = useState(null);
  const [smartPromptData, setSmartPromptData] = useState(null);

  const updateAtmosphereRefData = (newData) => {
    setAtmosphereRefData((prevData) => ({ ...prevData, ...newData }));
  };

  const clearAtmosphereRefData = () => {
    setAtmosphereRefData(null);
  };

  const updateAtmosphereRefData1 = (newData) => {
    setAtmosphereRefData1((prevData) => ({ ...prevData, ...newData }));
  };

  const clearAtmosphereRefData1 = () => {
    setAtmosphereRefData1(null);
  };

  const updateSmartPromptData = (newData) => {
    setSmartPromptData((prevData) => ({ ...prevData, ...newData }));
  };

  const clearSmartPromptData = () => {
    setSmartPromptData(null);
  };

  return (
    <AtmosphereRefContext.Provider
      value={{
        atmosphereRefData,
        updateAtmosphereRefData,
        clearAtmosphereRefData,
        atmosphereRefData1,
        updateAtmosphereRefData1,
        clearAtmosphereRefData1,
        smartPromptData,
        updateSmartPromptData,
        clearSmartPromptData
      }}
    >
      {children}
    </AtmosphereRefContext.Provider>
  );
}

export { AtmosphereRefContext, AtmosphereRefProvider };
